/*
Using formula solid
$primary: #FFC107;
$home: #00003c;
$away: #ff0000;
$base: #16202C;
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin,latin-ext");
.sr-bb {
  font-family: "Roboto", "Noto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  background: #16202C;
}

.sr-bb.sr-rtl {
  text-align: right;
}

.sr-bb .sr-bb {
  background: none;
}
srct-br-outcome--selected{
  background-color: var(--orange)!important;
}
srct-br-outcome{
  background-color: var(--odds-button)!important;
}
.sr-bb .srt-base-1 {
  background-color: transparent;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-win {
  background-color: transparent;
  color: #00003c;
  border-color: #00003c;
}

.sr-bb .srt-base-1-draw {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-base-1-lose {
  background-color: transparent;
  color: #ff0000;
  border-color: #ff0000;
}

.sr-bb .srt-base-1-is-active {
  background-color: rgba(255, 193, 7, 0.12);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
  background-color: #2d3641;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
  background-color: rgba(255, 193, 7, 0.12);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-primary {
  background-color: transparent;
  color: #FFC107;
  border-color: #FFC107;
}

.sr-bb .srt-base-1-home-1 {
  background-color: transparent;
  color: #00003c;
  border-color: #00003c;
}

.sr-bb .srt-base-1-away-1 {
  background-color: transparent;
  color: #ff0000;
  border-color: #ff0000;
}

.sr-bb .srt-base-1-home-2 {
  background-color: transparent;
  color: #00003c;
  border-color: #ffdb6f;
}

.sr-bb .srt-base-1-away-2 {
  background-color: transparent;
  color: #ff0000;
  border-color: #ffdb6f;
}

.sr-bb .srt-base-1-home-3 {
  background-color: transparent;
  color: #00003c;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-away-3 {
  background-color: transparent;
  color: #ff0000;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-home-4 {
  background-color: transparent;
  color: #070a37;
  border-color: #070a37;
}

.sr-bb .srt-base-1-away-4 {
  background-color: transparent;
  color: #b90a0d;
  border-color: #b90a0d;
}

.sr-bb .srt-base-1-home-5 {
  background-color: transparent;
  color: #595980;
  border-color: #595980;
}

.sr-bb .srt-base-1-away-5 {
  background-color: transparent;
  color: #ff5959;
  border-color: #ff5959;
}

.sr-bb .srt-base-1-background {
  background-color: #16202c;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2 {
  background-color: #16202c;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-active {
  background-color: #323328;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
  background-color: #323328;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3 {
  background-color: #323328;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-active {
  background-color: #3e3b26;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
  background-color: #3e3b26;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-background {
  background-color: #16202c;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-4 {
  background-color: #22282a;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-active {
  background-color: #534a22;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
  background-color: #534a22;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-6 {
  background-color: transparent;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.3);
}

.sr-bb .srt-primary-1 {
  background-color: var(--odds-button);
  color: var(--light);
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-active {
  background-color: #e6ae06;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-hoverable:hover {
  background-color: #e6ae06;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
  background-color: #cc9a06;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-2 {
  background-color: #ffd451;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-3 {
  background-color: #e6ae06;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-4 {
  background-color: #a28116;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5 {
  background-color: #a28116;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover {
  background-color: #FFC107;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-6 {
  background-color: #ffdb6f;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-7 {
  background-color: #FFC107;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8 {
  background-color: #FFC107;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-1 {
  background-color: #e6ae06;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
  background-color: #e6ae06;
  color: #000000;
  border-color: #16202C;
}

.sr-bb .srt-primary-9 {
  background-color: #5c5021;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-primary-10 {
  background-color: #d0a10e;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-11 {
  background-color: #e6ae06;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-12 {
  background-color: #FFC107;
  color: #000000;
  border-color: #000000;
}

.sr-bb .srt-primary-13 {
  background-color: #22282a;
  color: #000000;
  border-color: rgba(255, 193, 7, 0.3);
}

.sr-bb .srt-base-1-primary-1 {
  background-color: transparent;
  color: #FFC107;
  border-color: #FFC107;
}

.sr-bb .srt-base-1-primary-2 {
  background-color: transparent;
  color: #ffd451;
  border-color: #ffd451;
}

.sr-bb .srt-base-1-primary-3 {
  background-color: transparent;
  color: #e6ae06;
  border-color: #e6ae06;
}

.sr-bb .srt-base-1-primary-4 {
  background-color: transparent;
  color: #a28116;
  border-color: #a28116;
}

.sr-bb .srt-base-1-primary-5 {
  background-color: transparent;
  color: #a28116;
  border-color: #a28116;
}

.sr-bb .srt-base-1-primary-6 {
  background-color: transparent;
  color: #ffdb6f;
  border-color: #ffdb6f;
}

.sr-bb .srt-base-1-primary-7 {
  background-color: transparent;
  color: #FFC107;
  border-color: #FFC107;
}

.sr-bb .srt-base-1-primary-8 {
  background-color: transparent;
  color: #FFC107;
  border-color: #FFC107;
}

.sr-bb .srt-base-1-primary-9 {
  background-color: transparent;
  color: #5c5021;
  border-color: #5c5021;
}

.sr-bb .srt-base-1-primary-10 {
  background-color: transparent;
  color: #d0a10e;
  border-color: #d0a10e;
}

.sr-bb .srt-base-1-primary-11 {
  background-color: transparent;
  color: #e6ae06;
  border-color: #e6ae06;
}

.sr-bb .srt-base-1-primary-13 {
  background-color: #16202C;
  color: #22282a;
  border-color: rgba(255, 193, 7, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
  background-color: transparent;
  color: #343d47;
  border-color: #343d47;
}

.sr-bb .srt-base-1-neutral-2 {
  background-color: transparent;
  color: #e8e9ea;
  border-color: #e8e9ea;
}

.sr-bb .srt-base-1-neutral-3 {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
  background-color: transparent;
  color: #adb1b5;
  border-color: #adb1b5;
}

.sr-bb .srt-base-1-neutral-5 {
  background-color: transparent;
  color: #c5c7ca;
  border-color: #c5c7ca;
}

.sr-bb .srt-base-1-neutral-6 {
  background-color: transparent;
  color: #4e565f;
  border-color: #4e565f;
}

.sr-bb .srt-base-1-neutral-7 {
  background-color: transparent;
  color: #adb1b5;
  border-color: #adb1b5;
}

.sr-bb .srt-base-1-neutral-8 {
  background-color: transparent;
  color: #343d47;
  border-color: #343d47;
}

.sr-bb .srt-base-1-neutral-9 {
  background-color: transparent;
  color: #242d39;
  border-color: #242d39;
}

.sr-bb .srt-base-1-neutral-10 {
  background-color: transparent;
  color: #323b45;
  border-color: #323b45;
}

.sr-bb .srt-base-1-neutral-11 {
  background-color: transparent;
  color: #737980;
  border-color: #737980;
}

.sr-bb .srt-base-1-neutral-12 {
  background-color: transparent;
  color: #b9bcc0;
  border-color: #b9bcc0;
}

.sr-bb .srt-base-1-neutral-13 {
  background-color: transparent;
  color: #2d3641;
  border-color: #2d3641;
}

.sr-bb .srt-base-1-is-active-primary {
  background-color: rgba(255, 193, 7, 0.12);
  color: #FFC107;
  border-color: #FFC107;
}

.sr-bb .srt-base-1-is-active-home-1 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #00003c;
  border-color: #00003c;
}

.sr-bb .srt-base-1-is-active-away-1 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #ff0000;
  border-color: #ff0000;
}

.sr-bb .srt-base-1-is-active-home-2 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #00003c;
  border-color: #ffdb6f;
}

.sr-bb .srt-base-1-is-active-away-2 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #ff0000;
  border-color: #ffdb6f;
}

.sr-bb .srt-base-1-is-active-home-3 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #00003c;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #ff0000;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #070a37;
  border-color: #070a37;
}

.sr-bb .srt-base-1-is-active-away-4 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #b90a0d;
  border-color: #b90a0d;
}

.sr-bb .srt-base-1-is-active-home-5 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #595980;
  border-color: #595980;
}

.sr-bb .srt-base-1-is-active-away-5 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #ff5959;
  border-color: #ff5959;
}

.sr-bb .srt-base-1-is-active-primary-1 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #FFC107;
  border-color: #FFC107;
}

.sr-bb .srt-base-1-is-active-primary-2 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #ffd451;
  border-color: #ffd451;
}

.sr-bb .srt-base-1-is-active-primary-3 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #e6ae06;
  border-color: #e6ae06;
}

.sr-bb .srt-base-1-is-active-primary-4 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #a28116;
  border-color: #a28116;
}

.sr-bb .srt-base-1-is-active-primary-5 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #a28116;
  border-color: #a28116;
}

.sr-bb .srt-base-1-is-active-primary-6 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #ffdb6f;
  border-color: #ffdb6f;
}

.sr-bb .srt-base-1-is-active-primary-7 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #FFC107;
  border-color: #FFC107;
}

.sr-bb .srt-base-1-is-active-primary-8 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #FFC107;
  border-color: #FFC107;
}

.sr-bb .srt-base-1-is-active-primary-9 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #5c5021;
  border-color: #5c5021;
}

.sr-bb .srt-base-1-is-active-primary-10 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #d0a10e;
  border-color: #d0a10e;
}

.sr-bb .srt-base-1-is-active-primary-11 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #e6ae06;
  border-color: #e6ae06;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #343d47;
  border-color: #343d47;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #e8e9ea;
  border-color: #e8e9ea;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
  background-color: rgba(255, 193, 7, 0.12);
  color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #adb1b5;
  border-color: #adb1b5;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #c5c7ca;
  border-color: #c5c7ca;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #4e565f;
  border-color: #4e565f;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #adb1b5;
  border-color: #adb1b5;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #343d47;
  border-color: #343d47;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #242d39;
  border-color: #242d39;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #323b45;
  border-color: #323b45;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #737980;
  border-color: #737980;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #b9bcc0;
  border-color: #b9bcc0;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
  background-color: rgba(255, 193, 7, 0.12);
  color: #2d3641;
  border-color: #2d3641;
}

.sr-bb .srt-home-1 {
  background-color: #00003c;
  color: #ffffff;
  border-color: #00003c;
}

.sr-bb .srt-away-1 {
  background-color: #ff0000;
  color: #ffffff;
  border-color: #ff0000;
}

.sr-bb .srt-home-2 {
  background-color: #00003c;
  color: #ffffff;
  border-color: #ffdb6f;
}

.sr-bb .srt-away-2 {
  background-color: #ff0000;
  color: #ffffff;
  border-color: #ffdb6f;
}

.sr-bb .srt-home-3 {
  background-color: #00003c;
  color: #ffffff;
  border-color: #16202C;
}

.sr-bb .srt-away-3 {
  background-color: #ff0000;
  color: #ffffff;
  border-color: #16202C;
}

.sr-bb .srt-home-4 {
  background-color: #070a37;
  color: #ffffff;
  border-color: #070a37;
}

.sr-bb .srt-away-4 {
  background-color: #b90a0d;
  color: #ffffff;
  border-color: #b90a0d;
}

.sr-bb .srt-home-5 {
  background-color: #595980;
  color: #ffffff;
  border-color: #595980;
}

.sr-bb .srt-away-5 {
  background-color: #ff5959;
  color: #ffffff;
  border-color: #ff5959;
}

.sr-bb .srt-home-6 {
  background-color: rgba(0, 0, 60, 0.2);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-away-6 {
  background-color: rgba(255, 0, 0, 0.2);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-home-6-is-hoverable:hover {
  background-color: rgba(0, 0, 60, 0.2);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-away-6-is-hoverable:hover {
  background-color: rgba(255, 0, 0, 0.2);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-1 {
  background-color: #343d47;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-2 {
  background-color: #e8e9ea;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-3 {
  background-color: rgba(255, 255, 255, 0.12);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-4 {
  background-color: #adb1b5;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-5 {
  background-color: #c5c7ca;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-6 {
  background-color: #4e565f;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-7 {
  background-color: #adb1b5;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-8 {
  background-color: #343d47;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-9 {
  background-color: #242d39;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-10 {
  background-color: #323b45;
  color: rgba(255, 255, 255, 0.82);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-11 {
  background-color: #737980;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-12 {
  background-color: #b9bcc0;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-13 {
  /* background-color: #2d3641; */
  background: var(--viax-betslip-bg);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-win {
  background-color: #00003c;
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-draw {
  background-color: rgba(255, 255, 255, 0.4);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-lose {
  background-color: #ff0000;
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-text-secondary {
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-text-disabled {
  opacity: 0.6;
}

.sr-bb .srt-text-tertiary {
  opacity: 0.7;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
  opacity: 0.7;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
  opacity: 0.35;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
  opacity: 0.54;
}

.sr-bb .srt-icon {
  opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
  opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23), 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.26), 0px 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
  box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
  box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
  box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
  box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
  fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
  stroke: #2579ad;
}

.sr-bb .srt-fill-green-card {
  fill: #2cbd00;
}

.sr-bb .srt-stroke-green-card {
  stroke: #2cbd00;
}

.sr-bb .srt-fill-soccer-yellow-card {
  fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
  stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
  stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
  stroke: #4fbe30;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
  fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
  fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
  fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
  stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
  fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
  stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
  fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
  stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
  fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
  stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
  fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
  stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
  fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
  stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
  fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
  stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
  fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
  stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
  fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
  stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
  fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
  stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
  background-color: #f5a623;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
  background-color: rgba(245, 166, 35, 0.3);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-penalty {
  background-color: #f4a621;
  color: #000000;
  border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
  background-color: #ac182e;
  color: #000000;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
  background-color: #ac182e;
  color: #000000;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
  background-color: #4b90de;
  color: #000000;
  border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
  background-color: #f5a623;
  color: #000000;
  border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
  fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
  stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
  fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
  stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
  fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
  stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
  background-color: #009900;
  color: #ffffff;
  border-color: #009900;
}

.sr-bb .srt-mlb-run-2 {
  background-color: #ffffff;
  color: #009900;
  border-color: #009900;
}

.sr-bb .srt-mlb-hit-1 {
  background-color: #f7ab11;
  color: #ffffff;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
  background-color: #ffffff;
  color: #f7ab11;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
  background-color: #00a4ff;
  color: #ffffff;
  border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
  background-color: #d0021b;
  color: #ffffff;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
  background-color: #ffffff;
  color: #d0021b;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
  background-color: #212121;
  color: #ffffff;
  border-color: #ffffff;
}

.sr-bb .srt-fill-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
  fill: #00003c;
}

.sr-bb .srt-stroke-change-increase {
  stroke: #00003c;
}

.sr-bb .srt-fill-change-decrease {
  fill: #ff0000;
}

.sr-bb .srt-stroke-change-decrease {
  stroke: #ff0000;
}

.sr-bb .srt-fill-text {
  fill: #ffffff;
}

.sr-bb .srt-fill-text-invert {
  fill: #000000;
}

.sr-bb .srt-fill-text-secondary {
  fill-opacity: 0.8;
}

.sr-bb .srt-fill-text-disabled {
  fill-opacity: 0.6;
}

.sr-bb .srt-fill-base-1 {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
  fill: rgba(255, 193, 7, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
  stroke: rgba(255, 193, 7, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
  fill: #2d3641;
}

.sr-bb .srt-stroke-base-1-active-2 {
  stroke: #2d3641;
}

.sr-bb .srt-fill-base-1-primary {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
  stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
  fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
  stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
  fill: #323328;
}

.sr-bb .srt-stroke-base-2-active {
  stroke: #323328;
}

.sr-bb .srt-fill-base-2-hover {
  fill: #323328;
}

.sr-bb .srt-stroke-base-2-hover {
  stroke: #323328;
}

.sr-bb .srt-fill-base-3 {
  fill: #323328;
}

.sr-bb .srt-stroke-base-3 {
  stroke: #323328;
}

.sr-bb .srt-fill-base-3-active {
  fill: #3e3b26;
}

.sr-bb .srt-stroke-base-3-active {
  stroke: #3e3b26;
}

.sr-bb .srt-fill-base-3-hover {
  fill: #3e3b26;
}

.sr-bb .srt-stroke-base-3-hover {
  stroke: #3e3b26;
}

.sr-bb .srt-fill-primary-1 {
  fill: #FFC107;
}

.sr-bb .srt-stroke-primary-1 {
  stroke: #FFC107;
}

.sr-bb .srt-fill-primary-2 {
  fill: #ffd451;
}

.sr-bb .srt-stroke-primary-2 {
  stroke: #ffd451;
}

.sr-bb .srt-fill-primary-3 {
  fill: #e6ae06;
}

.sr-bb .srt-stroke-primary-3 {
  stroke: #e6ae06;
}

.sr-bb .srt-fill-primary-4 {
  fill: #a28116;
}

.sr-bb .srt-stroke-primary-4 {
  stroke: #a28116;
}

.sr-bb .srt-fill-primary-5 {
  fill: #a28116;
}

.sr-bb .srt-stroke-primary-5 {
  stroke: #a28116;
}

.sr-bb .srt-fill-primary-6 {
  fill: #ffdb6f;
}

.sr-bb .srt-stroke-primary-6 {
  stroke: #ffdb6f;
}

.sr-bb .srt-fill-primary-7 {
  fill: #FFC107;
}

.sr-bb .srt-stroke-primary-7 {
  stroke: #FFC107;
}

.sr-bb .srt-fill-primary-8 {
  fill: #FFC107;
}

.sr-bb .srt-stroke-primary-8 {
  stroke: #FFC107;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
  fill: #e6ae06;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
  stroke: #e6ae06;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
  fill: #e6ae06;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
  stroke: #e6ae06;
}

.sr-bb .srt-fill-primary-9 {
  fill: #5c5021;
}

.sr-bb .srt-stroke-primary-9 {
  stroke: #5c5021;
}

.sr-bb .srt-fill-primary-10 {
  fill: #d0a10e;
}

.sr-bb .srt-stroke-primary-10 {
  stroke: #d0a10e;
}

.sr-bb .srt-fill-primary-11 {
  fill: #e6ae06;
}

.sr-bb .srt-stroke-primary-11 {
  stroke: #e6ae06;
}

.sr-bb .srt-fill-primary-12 {
  fill: #FFC107;
}

.sr-bb .srt-stroke-primary-12 {
  stroke: #FFC107;
}

.sr-bb .srt-fill-home-1 {
  fill: #00003c;
}

.sr-bb .srt-stroke-home-1 {
  stroke: #00003c;
}

.sr-bb .srt-fill-home-2 {
  fill: #00003c;
}

.sr-bb .srt-stroke-home-2 {
  stroke: #00003c;
}

.sr-bb .srt-fill-home-3 {
  fill: #00003c;
}

.sr-bb .srt-stroke-home-3 {
  stroke: #00003c;
}

.sr-bb .srt-fill-home-4 {
  fill: #070a37;
}

.sr-bb .srt-stroke-home-4 {
  stroke: #070a37;
}

.sr-bb .srt-fill-home-5 {
  fill: #595980;
}

.sr-bb .srt-stroke-home-5 {
  stroke: #595980;
}

.sr-bb .srt-fill-away-1 {
  fill: #ff0000;
}

.sr-bb .srt-stroke-away-1 {
  stroke: #ff0000;
}

.sr-bb .srt-fill-away-2 {
  fill: #ff0000;
}

.sr-bb .srt-stroke-away-2 {
  stroke: #ff0000;
}

.sr-bb .srt-fill-away-3 {
  fill: #ff0000;
}

.sr-bb .srt-stroke-away-3 {
  stroke: #ff0000;
}

.sr-bb .srt-fill-away-4 {
  fill: #b90a0d;
}

.sr-bb .srt-stroke-away-4 {
  stroke: #b90a0d;
}

.sr-bb .srt-fill-away-5 {
  fill: #ff5959;
}

.sr-bb .srt-stroke-away-5 {
  stroke: #ff5959;
}

.sr-bb .srt-fill-neutral-1 {
  fill: #343d47;
}

.sr-bb .srt-stroke-neutral-1 {
  stroke: #343d47;
}

.sr-bb .srt-fill-neutral-2 {
  fill: #e8e9ea;
}

.sr-bb .srt-stroke-neutral-2 {
  stroke: #e8e9ea;
}

.sr-bb .srt-fill-neutral-3 {
  fill: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
  stroke: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
  fill: #adb1b5;
}

.sr-bb .srt-stroke-neutral-4 {
  stroke: #adb1b5;
}

.sr-bb .srt-fill-neutral-5 {
  fill: #c5c7ca;
}

.sr-bb .srt-stroke-neutral-5 {
  stroke: #c5c7ca;
}

.sr-bb .srt-fill-neutral-6 {
  fill: #4e565f;
}

.sr-bb .srt-stroke-neutral-6 {
  stroke: #4e565f;
}

.sr-bb .srt-fill-neutral-7 {
  fill: #adb1b5;
}

.sr-bb .srt-stroke-neutral-7 {
  stroke: #adb1b5;
}

.sr-bb .srt-fill-neutral-8 {
  fill: #343d47;
}

.sr-bb .srt-stroke-neutral-8 {
  stroke: #343d47;
}

.sr-bb .srt-fill-neutral-9 {
  fill: #242d39;
}

.sr-bb .srt-stroke-neutral-9 {
  stroke: #242d39;
}

.sr-bb .srt-fill-neutral-10 {
  fill: #323b45;
}

.sr-bb .srt-stroke-neutral-10 {
  stroke: #323b45;
}

.sr-bb .srt-fill-neutral-11 {
  fill: #737980;
}

.sr-bb .srt-stroke-neutral-11 {
  stroke: #737980;
}

.sr-bb .srt-fill-neutral-12 {
  fill: #b9bcc0;
}

.sr-bb .srt-stroke-neutral-12 {
  stroke: #b9bcc0;
}

.sr-bb .srt-fill-neutral-13 {
  fill: #2d3641;
}

.sr-bb .srt-stroke-neutral-13 {
  stroke: #2d3641;
}

.sr-bb .srt-fill-win {
  fill: #00003c;
}

.sr-bb .srt-stroke-win {
  stroke: #00003c;
}

.sr-bb .srt-fill-draw {
  fill: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-stroke-draw {
  stroke: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-fill-lose {
  fill: #ff0000;
}

.sr-bb .srt-stroke-lose {
  stroke: #ff0000;
}

.sr-bb .srt-stop-base-1 {
  stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
  stop-color: #FFC107;
}

.sr-bb .srt-stop-primary-2 {
  stop-color: #ffd451;
}

.sr-bb .srt-stop-primary-3 {
  stop-color: #e6ae06;
}

.sr-bb .srt-stop-primary-4 {
  stop-color: #a28116;
}

.sr-bb .srt-stop-primary-5 {
  stop-color: #a28116;
}

.sr-bb .srt-stop-primary-6 {
  stop-color: #ffdb6f;
}

.sr-bb .srt-stop-primary-7 {
  stop-color: #FFC107;
}

.sr-bb .srt-stop-primary-8 {
  stop-color: #FFC107;
}

.sr-bb .srt-stop-primary-9 {
  stop-color: #5c5021;
}

.sr-bb .srt-stop-primary-10 {
  stop-color: #d0a10e;
}

.sr-bb .srt-stop-primary-11 {
  stop-color: #e6ae06;
}

.sr-bb .srt-stop-primary-12 {
  stop-color: #FFC107;
}

.sr-bb .srt-stop-home-1 {
  stop-color: #00003c;
}

.sr-bb .srt-stop-away-1 {
  stop-color: #ff0000;
}

.sr-bb .srt-fill-neutral-14 {
  fill: #16202C;
}

.sr-bb .srt-stroke-neutral-14 {
  stroke: #16202C;
}

.sr-bb .srt-logo-powered-by-light {
  display: none;
}

.sr-bb .srt-logo-powered-by-dark {
  display: inline-block;
}
