body {
  margin: 0;
  font-family: ProximaNovaRegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {

}
