*{
    /* list-style:none; */
    box-sizing: border-box;
    text-decoration: none;

}
/* html, body {
    height: 100%;
    margin: 0;
    padding: 0;
} */

input, select, textarea {
    font-size: 16px;
}

.size-1{
    width:15%
}
.size-2{
    width:85%
}
@supports(padding:max(0px)) {
    body, footer {
        padding-left: min(0vmin, env(safe-area-inset-left));
        padding-right: min(0vmin, env(safe-area-inset-right));
        padding-top: min(0px, env(safe-area-inset-top));
        padding-bottom: min(0px, env(safe-area-inset-bottom));
    }
}

    .testimonials-style{
        overflow: -moz-hidden-unscrollable; /* Firefox */
        overflow: -webkit-hidden; /* Safari and Chrome */
        overflow: hidden;
    }
    .moz-clip{
        overflow-x:clip;
        overflow-x: -moz-clip;
        width:100%;
    }
    .testimonial-style{
        white-space: nowrap;
        animation: slide1 50s linear infinite;
    }
    @keyframes slide1 {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-1700px);
        }
    }
    .sticky-testimony{
        position: sticky;
        top: 126px;
        z-index:150;
        opacity:1;
        background: #253743;
    }

@media (orientation: landscape) and (max-height:375px){

    .slip-body>tr{
        overflow: hidden;
        height: 1px;
        line-height: 6.5px;
        font-size: 11px;
        white-space: nowrap
    }
    .betslip{
        padding-bottom:0px;
    }
    .place-bet-btn{
        font-size:9px;
    }
    .slip-body {
        width: 100%;
        line-height: 6px !important;
    }
}
@media (orientation: landscape) and (max-width: 767px) and (max-height: 375px){
    .slip-body>tr{
        line-height: 5.5px!important;
        font-size:9px;
    }
    .slip-size{
        max-height:33.5vh!important;
    }

}



.size-slip-new{
    max-height:28vh;
    height:auto;

}


@media (orientation: landscape) and (max-width: 967px) {
    .landscape-orientation{
        position:absolute;
        width:50%;
        bottom:0px;
    }
    .betslip{
        padding-top:0px;
    }
    .slip-body>tr{
        overflow: hidden;
        height: 1px;
        white-space: nowrap
    }
    .betslip-submit-form{
        display:contents;
    }

}

.mobile-menu {
    background-color:var(--CrashKali-header-bg);
    position: fixed;
    bottom: 0;
    visibility:visible;
    height: 100px;
    width: 100%;
    z-index:2000!important;
    font-size: 13px !important;
    display: flex;
    justify-content: space-around;
}



.mobile-menu tbody{
    width:100%;
}
.prematch-slip.progress {
    height: 14px;
    position:relative;
}
.progress{
    position:relative;
}
.prematch.progress-bar {
    background: var(--lite-top-color) !important;
}

  .bloc-icon p{
    color: white;
  }
  .bloc-icon:nth-of-type(3){
        background: none;
    }

  .bloc-icon img {
    width: 25px;
    fill: white;
  }
  @media screen and (min-width: 600px) {
    .mobile-nav {
    display: none;
    }
  }
.width-all{
    width:100%;
}


.size-date {
    width: 20%;
}

.casino-category-container {
    align-items: flex-start;
    box-shadow: 0 5px 15px rgba(0,0,0,.35)!important;
    display: flex;
    flex-direction: row!important;
    justify-content: space-around;
    position: -webkit-sticky!important;
    position: sticky!important;
    width:100%;
    overflow: auto;
}

.shark-1 {
    position: sticky;
    top: 0;
}

.kanyonde-image {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    flex-direction: row;
}

.casino-category {
    width: 100%;
    border-radius: 5px;
    background-color: #0e131b;
    padding: 2px;
    color: white;
    position: sticky !important;
    top: 4rem;
    margin-left: 2px;
    outline: none !important;
}

.virtual-game-container {
    box-shadow: 0px 1px 5px 3px #253743 !important;
    margin-bottom: 15px !important;
    border-radius: 5px;
    border: thin solid #253743;
    background-color: #0e131b;
    padding: 2px;
    color: white;
    position: sticky !important;
    top: 4rem;
    margin-left: 2px;
    outline: none !important;
}

.casino-category:focus {
    background-color: #f98f06;
}

.bg-active {
    background: #f98f06 !important;
}

/*@font-face {*/
/*    font-family: 'GothamBold';*/
/*    src: url('../fonts/GothamBold.eot');*/
/*    src: url('../fonts/GothamBold.eot?#iefix&v=4.6.1') format('embedded-opentype'), url('../fonts/GothamBold.eot?v=4.6.1') format('woff2'), url('../fonts/GothamBold.woff?v=4.6.1') format('woff'), url('../fonts/GothamBold.ttf?v=4.6.1') format('truetype'), url('../fonts/GothamBold.svg?v=4.6.1#') format('svg');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*}*/


html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}
.f-list {
    padding-left: 0;
}

.f-list li {
    list-style-type: none;
    padding-left: 0;
}

.mybets a {
    color: #1E3948 !important;
}

.gothb {

}

.header-menu li{
    position:relative;
    text-align: center;
}
.header-menu li.active {
    border-bottom: none!important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-menu{
    background: var(--CrashKali-body-bg);
}

.header-menu li.active:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 5px;
    left: 50%;
    transform: translate(-50%);
    background: var(--CrashKali-button-login);
    bottom: 0;
    border-bottom: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.header-menu li.active a strong{
    color:var(--CrashKali-button-login)!important;
    font-weight:700;
}

.betslip::-webkit-scrollbar {
    display: none;
}

.flow {
    display: flex;
    flex-direction: column-reverse;
}

.flow::-webkit-scrollbar {
    display: none;
}

.betslip input[type="number"] {
    color: #333;
    border: 1px solid #9f9f9f;
    font-size: 13px;
}

.accordion-button:not(.collapsed) {
    color: var(--light);
    border: none;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bet-history) !important;
}

.accordion-button {
    outline: none;
    border: none;
    color: whitesmoke;
    padding: 20px;
    font-size: 16px;
    color: whitesmoke;
    border-bottom: thin solid var(--bet-history);
}

.accordion-button.jackpot {
background: var(--CrashKali-body-bg)!important;
}
.accordion-button {
background: var(--CrashKali-body-bg)!important;
}

.no-border-radius {
    -webkit-border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
}

::selection {
    color: #fff;
    background: #664AFF;
}

.wrapper {
    max-width: 450px;
    margin: 150px auto;
}

.wrapper .search-input {
    background: #fff;
    width: 100%;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
}

.search-input input {
    height: 55px;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0 60px 0 20px;
    font-size: 18px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.search-input.active input {
    border-radius: 5px 5px 0 0;
}

.search-input .autocomplete-box {
    padding: 0;
    opacity: 0;
    pointer-events: none;
    max-height: 280px;
    overflow-y: auto;
}
.autocomplete-box{
    max-height: 42vh;
}
.search-input.active .autocomplete-box {
    padding: 10px 8px;
    opacity: 1;
    pointer-events: auto;
}

.autocomplete-box li {
    list-style: none;
    padding: 5px 9px;
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    color:var(--light);
    line-height:1.5;
}

.search-input.active .autocomplete-box li {
    display: block;
}



.search-input .icon {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 55px;
    width: 55px;
    text-align: center;
    line-height: 55px;
    font-size: 20px;
    color: #644bff;
    cursor: pointer;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.accordion-button:focus {
    outline: none;
}

.accordion-container {
    background: var(--CrashKali-primary) !important;

}

.accordion-item-panel {
    background: var(--CrashKali-primary);
    padding: 10px 35px;
    line-height: 30px;
    font-size: var(--font-size-large);
}

.accordion-item-panel li {
    margin-bottom: 10px;
    opacity:0.99;
}

#quick-login p {
    font-size: 14px;
    font-weight: normal;
}

#quick-login form {
    margin-top: 0;
}

#quick-login {
    padding: 4px 8px 10px;

}

#quick-login .form-control {
    margin-top: -6px;
}

.bet-option:nth-child(2n) {
    background: var(--betslip-list);
}

#quick-login label {
    font-weight: normal;
}

.top-login-input-field::placeholder {
    color: gray;
}


.pdf-app {
    display: table;
    margin: 0 auto;
}


.home-tabs {
    background: var(--dark);
    border-right: 3px solid transparent;
    border-top: 3px solid transparent;
    padding: 6px 5px;
    width: 100% !important;
    float: left;
    color: #b9c7db !important;
}


.cancel {
    color: #fff;
    background: var(--cancel);
    display: block;
    width: 100px;
    margin: 10px auto 10px;
    text-align: center;
    padding: 10px;
}

.home-tabs .main-header, .home-tabs-active .main-header {
    padding: 0px 5px !important;
}

.homepage img {
    width: 100%;
    max-height: 12rem;
}

.homepage .header-holder {
    margin-top: 10px;
}

.f-list li a {
    color: #545454;
    width: 100%;
}

.new {
    background: #ffd604;
    color: #030928;
    font-size: 11px;
    text-transform: capitalize;
    padding: 1px 2px;
}

.mobi .row {
    margin-right: -8px !important;
    margin-left: -8px !important;
    overflow: auto;
}

.fb-iframe {
    height: 160px;
    display: block;
}

.yellow-bg {
    background: #24367e !important;
    color: #fff !important;
}

.compt {
    color: #adb2c5;
}

.odds-up {
    color: #38b41c !important;
}

.odds-down {
    color: #fe0100 !important;
}

.picked .compt {
    color: #fff;
}


.mobi h2 {
    font-size: 20px;
}

.header-icon {
    text-align: center;
    font-size: 20px;
}

.cancelb {
    color: #fff;
    background: #030928;
    display: block;
    width: 100px;
    float: right;
    text-align: center;
    padding: 10px;
    margin-top: -50px;
}

.bon {
    margin-top: 5px;
}

.header-icon:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 214, 4, 0);
    border-width: 8px;
    margin-top: -8px;
}

.main-icon {
    background: #e8ae20;
    text-align: center;
    color: #421826;
    font-size: 20px;
    padding: 5px;
    padding: 10px 0 8px 1px !important;
    max-width: 55px;
}

.jackpot {
    margin-bottom: 0 !important;
    border-radius: 0 !important;
}

.jackpot .header-holder {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    background: #c91d56;
}

.row .jackpot .header-holder span {
    color: #fff;
    font-weight: bold;
}
.paginator a {
    color: #ffffff;
    display: block;
    display: inline-block;
    background: #ada8a8;
    text-align: center;
    width: 45px;
    margin: 2px;
    float: left;
    padding: 3px;
    font-size: 12px;
    font-weight: bold;
}

.current-page {
    color: #fff !important;
    background: #c91d56 !important;
    font-weight: bold;
    border: 1px solid rgba(255, 255, 255, .2);
}

.betslipJ {
    background: #f5f1f2;
    padding: 5px;
    font-size: 14px;
}

#search-event input[type="text"] {
    width: 100%;
}

#search-event div {
    padding: 8px 0 !important;
}

.mobi #search-event div {
    padding: 10px 0 !important;
    width: 80%;
    float: left;
}

.mobi #search-event .col-md-2 {
    width: 20% !important;
}

#search-event .fp {
    background: transparent;
    padding: 2px 0 0px 5px;
    border: 1px solid #d4cece !important;
}

.jp-stake-highlight {
    font-size: 18px;
    font-weight: 900;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
}

.light-primary-bg {
    background: #d96189;
    color: #000;
}

.betslipJ .bet-table td {
    padding: 4px;
}

.jackpot #place_bet_button {
    background: #FFC428;
    color: #333;
    white-space:nowrap;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 14px;
    float: right;
    border-radius: 4px !important;
}

.panel-body {
    padding: 15px;
    background: #fff0;
    color: #212020;
    font-size: 16px;
}

.panel-body .intext-title {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 4px;
}

.mobi .jackpot #place_bet_button {
    padding: 6px 8px;
    font-size: 16px;
    float: right;
    right: 8px;
}

.jackpot .header-icon {
    color: #fefefe !important;
    padding: 6px 0;
    font-weight: bold;
}

.main-icon::after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 214, 4, 0);
    border-left-color: #e8ae20;
    border-width: 8px;
    margin-top: -8px;
}

.full-width {
    width: 100%;
}

.matches {
    display: inline-block;
}


.matches .top-matches .match-detail button {
    background: var(--odds-button);
    border: 1px solid #3c5a6c!important;
    border-radius: 0!important;
    border-top: none!important;
    color: #fff!important;
    cursor: pointer;
    float: left;
    font-weight: 20!important;
    margin-right: 0;
    min-width: 100%;
    text-align: left;
}

.yellow {
    background-color: #ffc428;
    color: #000000;
}

.mobi-list {
    padding: 0;
}

.mobi-list li:nth-child(2n) {
    background: #ffffff;
}

.mobi .nav.men {
    border-bottom: 1px solid #f25f95;
}

.text-center {
    text-align: center;
    display: block;
    margin: auto;
    max-width: 100%;
    overflow: auto;
}

.text-center img {
    max-width: 80px;
}

.bet-fix {
    display: flex;
    /*width: 15%;*/
    gap: 2px;
    flex-basis: content;
}

.odd-fix {
    display: flex;
    justify-content: center;
}

.more-markets-container {
    min-width: 50px !important;
}

.picked .theodds,
.picked .mobi-odd {
    color: var(--dark);
    padding: 0px;
}
.button-text-color-on-yellow{
    color:var(--dark)
}

.matches .top-matches.match {
    padding: 0px 0px 0px 0px;
    opacity: 1;
    color: #292828 !important;
    font-size: 15px;
    border: none;
    border-radius: 3px;
    margin-bottom: 10px;
}

.matches .top-matches.header {
    background: #101B25;
    opacity: 1;
    display: flex;
    justify-content: flex-start;
    color: #fff !important;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bolder;
}

.match-header {
    background: #63b9e4;
    color: #3b4367;
    padding: 6px 10px 3px;
    overflow: auto;
    font-size: 14px;
    border-bottom: none !important;
}

.match button {
    max-width: 33% !important;
    border: 1px solid #c1c2c6 !important;
    color: #555 !important;
}

.match .blueish {
    color: #fff !important;
}

.match .odd-value {
    color: #ffffff !important;
}
.picked .blueish{
    color: var(--dark) !important;
}
.picked .odd-value {
    color: var(--dark) !important;
}

.events-header {
    background: #101B25;
    opacity: 0.9;
    padding: 0;
    align-items: center;
}

.events-header div {
    padding: 3px 10px 3px !important;
    color: #fafafa;
    min-height: 30px;
    height: auto;
    text-align: center;
}

.events-match {
    background: #060f38;
}

.matches .top-matches .events-odd {
    background: transparent;
}

.top-matches button {
    overflow: visible;
    display: flex;
    width: 100%;
    border: none;
    text-align: center !important;
    outline-style: none;
    outline: none;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 100;
    padding: 2px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }
#live_matches .match-div-col {
    padding: 10px 2px 0 0px !important;
}

.match-div-col {
    padding: 0 2px 0 0px !important;
    display: flex;
    flex-grow: 1;
}

.top-matches .pad {
    padding: 0px 0 2px;
    text-align: center;
}

.matches .top-matches .pad {
    padding: 0px 0 0px;
    text-align: center;
    border-right: 0px !important;
}

.font-btn {
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    letter-spacing: 1px;
    align-items: center;

}

.space-icons {
    padding-right: 10px
}

.jp-matches button {
    overflow: visible;
    display: block;
    border: 1px solid #0c153b;
    background: transparent;
    padding: 10px 0 10px 5px;
    outline-style: none;
    outline: none;
    color: #c8cbdc;
    float: left;
    text-align: center;
}

.two-way .top-matches .col-sm-2 {
    width: 16.666667%;
}

.separator {
    border-right: 3px solid #0c153b !important;
}

.events-header .events-odd {
    background: transparent;
    color: #f4f4f4;
}

.main-header {
    padding: 15px 25px 5px !important;
}

.top-matches .col-sm-2 {
    width: 12.666667%;
}

.top-matches .col-sm-3 {
    width: 29%;
}

.top-matches button .col-sm-3 {
    width: 25%;
}

.theteam {
    padding: 0;
    text-align: left;
    max-width: 75%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.correct .jp-matches button {
    overflow: visible;
    display: block;
    border: 1px solid #d4d4d4;
    background: transparent;
    padding: 10px 0 10px 5px;
    outline-style: none;
    outline: none;
    color: #111112;
    float: left;
    text-align: center;
}

.correct .jp-matches .theteam {
    padding: 0;
    text-align: center;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: bold;
    color: #514f4f;
    letter-spacing: 2px;
}

.betslipB {
    background: #fcfcfc;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
}

.black-bg {
    background: #404040;
}

.correct .jp-matches .teams {
    background: #eaeaea;
    text-transform: uppercase;
    color: #1d1c1c;
    padding: 10px;
    border-bottom: 1px solid #fff;
}

.correct .jp-matches .events-odd {
    background: #eaeaea;
    text-transform: uppercase;
    color: #1d1c1c;
    padding: 10px;
}

.theodds {
    padding: 0;
    font-weight: 400;
}

.slip-counter {
    text-align: center;
    color: var(--dark);
    font-size: 20px;
    padding: 5px;
}

.slip-counter::after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 214, 4, 0);
    border-width: 8px;
    margin-top: -8px;
}

.header-icon img {
    width: 22px;
}

.header-holder span {
    padding: 4px 0;
}

.header-holder > span:first-child {
    padding: 8px 0 8px 15px;
}

.f-league {
    font-weight: 600;
}

.join-now {
    display: inline-block !important;
    padding: 4px 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    top: 52%;
    position: absolute;
    left: 17%;
    color: #000000 !important;
    border-radius: 2px;
    border-bottom: 5px solid #AB9000;
    background: #FFD800;
    border-radius: 2px;
}

.join-now:hover {
    border-bottom: 5px solid #FFD800;
}

.f-list li a::after {
    color: #a8a6a6;
    content: "\f054";
    text-align: right;
    float: right;
    font-weight: normal !important;
}

.bet {
    font-size: 14px;
}

.betslip-mobi {
    float: right;
    margin: 8px;
    color: #fff !important;
    border-radius: 25px;
    padding: 0 0 0 10px !important;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #fff;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #fff;
    opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
    opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}

.thedate {
    color: white;
    font-size: 15px;
    font-weight: normal;
}

.thedate span {
    float: left;
    padding-right: 6px;
    font-size: 17px !important;
}

.shrink-header {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}


.shrink-header .e img {
    max-width: 200px !important;
}

.shrink-header .by form {
    margin-top: 10px;
}

.shrink-header .sign-up.btn {
    padding: 5px 20px;
    font-size: 17px;
}

.shrink-header .sticky-hidden, .shrink-header .hide-on-affix {
    opacity: 0;
    display: none !important;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.shrink-header .e {
    padding: 0px 15px !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.shrink-header .mobi .nav > li > a {
    display: block;
    padding: 1px 10px;
    font-size: 12px;
}

.forgot {
    display: inline-block;
    float: right;
}

.shrink-header .second-nav {
    top: 68px !important;
}


#shrink-header.shrink-header nav.second-nav.ck.pc.os.app-navbar div#navbar-collapse-main.by.f.collapse.container ul.nav.navbar-nav.og.ale.ss li a {
    font-size: 13px !important;
    font-weight: normal;
    padding-top: 7px !important;
    padding-bottom: 10px !important;
}


.shrink-header .top-nav {
    top: -4px;
}
.shrink-header .top-nav-login {
    top: -4px;
}

.treeview-menu.menu-open {
    background: #3f6878 !important;
}

.shrink-header .navbar-nav > li > a {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.shrink-header .second-nav {
    padding: 5px 0;
}

.shrink-header .competitive {
    padding-top: 4px !important;
    font-size: 12px !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.shrink-header .form-control {
    height: 32px;
    padding: 6px 10px;
    font-size: 13px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.shrink-header .cg {
    font-size: 12px;
    border-radius: 2px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.shrink-header i {
    display: none !important;
}

.shrink-header .icon-holder {
    line-height: 5px;
    font-size: 13px;
}

.icon-holder img {
    padding-bottom: 3px;
}


.panel-header a {
    display: inline-block;
    color: #fff;
    padding-right: 10px;
}

.loader {
    text-align: center;
    margin: auto;
    display: none;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

.hidden-overflow {
    overflow: hidden;
}

.no-pad-right {
    padding-right: 0;
}

.no-pad-left {
    padding-left: 0;
}

.inner-content h1,
.inner-content h2,
.inner-content h3,
.inner-content h4,
.inner-content h5 {
    padding: 10px 0;
    margin: 0;
}

.inner-content .form-control {
    height: auto;
    background-color: #ffffff;
    /* color: #421826; */
    border: 1px solid #c5c3c4;
    padding: 5px;
}

.form-control:focus {
    background-color: #fff;
    box-shadow: none;
}

a {
    background-color: transparent;
}

.panel {
    border: 0;
}

.inner-content p {
    color: #0e0e0e !important;
    font-size: 13px;
}



.navbar-form input, .form-inline input {
    width: auto;
}

.pick {
    cursor: copy;
    cursor: pointer;
    border: 1px solid #26408A;
    margin: 0;
    min-width: 90px;
}

.blueish {
    color: #002 !important;
}

header {
    height: auto;
}

.balnc {
    background: transparent !important;
    font-size: 10px;
    line-height: 6px;
    border-bottom: none !important;
    text-align: right;
    padding-right: 12px !important;
}

.main-nav {
    padding: 5px 0 0px;
}

.main-nav li {
    list-style-type: none;
    display: inline-block;
    padding-right: 3px;
    font-size: 12px;
}

.main-nav .icon {
    text-align: center;
    display: inline-block;
    line-height: 17px;
    border: 1px solid #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    font-size: 10px;
}

.main-nav .icon-football {
    text-align: center;
    display: inline-block;
    line-height: 17px;
    width: 20px;
    height: 20px;
}

.icon-holder {
    text-align: center;
    width: 100%;
    display: inline-block;
    line-height: 10px;
}

.home-tabs .web-element, .home-tabs-active .web-element {
    display: inline !important;
}

.match-time-real-counter {
    position: absolute;
    background-color: #000;
    padding: 5px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 14px !important;
    line-height: 17px;
}


@media (max-width: 640px) {
    .match button {
        width: 100% !important;
        max-width: 100% !important;
    }
}

@media (max-width: 768px) {


    .mobi {
        display: block !important;
    }

    .container {
        padding: 0 !important;
    }
    .top-matches div {
        min-height: 24px;
        justify-content: flex-end;
        /*background: #16202C;*/
    }

    .home-tabs .main-header, .home-tabs-active .main-header {
        font-size: 12px;
    }



    #slip-holder {
        position: fixed;
        z-index: 9999999;
        top: 15%;
        right: 10px;
        width: 98%;
        display: none;
        margin: auto;
    }

    .match button {
        max-width: 50%;
        width: 49%;
        display: inline-block;
    }

    .matches {
        display: block;
        overflow: hidden;
    }

    .odds .label {
    }

    .app-navbar .e img {
        max-width: 100px !important;
    }


    .second-nav {
        /*top: 80px !important;*/
        margin-top: 140px;
        background: #16202c !important;
    }

    .e {
        padding: 14px 5px !important;
    }

    .amt {
        /*padding-top: 50px !important;*/
    }


    .shrink-header .icon-holder img {
        display: none;
    }

    .pick {
        cursor: copy;
        cursor: pointer;
        border: 1px solid #26408A;
        margin: 0;
        min-width: 90px;
    }

    .panel-header h2, h2 {
        font-size: 16px;
        padding-top: 0px !important;
        margin-bottom: 0px !important;
        padding: 0;
        font-weight: 400;
    }

    .panel-header {
        padding: 5px !important;
    }

    .shrink-header .e {
        padding: 5px 5px !important;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .gd, .ge, .gf, .gg, .gh, .gi, .gj, .gk, .gl, .gm, .gn, .go, .gp, .gq, .gr, .gs, .gt, .gu, .gv, .gw, .gx, .gy, .gz, .ha, .hb, .hc, .hd, .he, .hf, .hg, .hh, .hi, .hj, .hk, .hl, .hm, .hn, .ho, .hp, .hq, .hr, .hs, .ht, .hu, .hv, .hw, .hx, .hy {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .match-time-real-counter {
        font-size: 10px !important;
        padding: 2px !important;
        line-height: 13px;
        z-index: 100;
    }

    .live-placeholder-game {
        font-size: 15px !important;
    }

    .live-header b {
        font-size: 10px !important;
    }

    .shrink-header .second-nav {
        top: 72px !important;
    }

    li a.live-game.active, .navbar-nav li a.live-game.active, li a.live-game {
        color: #fff;
        background-color: red;
        padding: 5px;
        margin: 0;
        padding-bottom: 4px !important;
    }

}


.winners-holder {
    box-sizing: border-box;
    background-color: #161616;
    font-size: 10px;
    border: 1px solid #fc0;
    color: #fff;
    height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.winners-title {
    background-color: black;
    color: white;
    padding: 5px;
}

.winners-content {
    width: 100%;
    overflow: hidden;
}

.marquee {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    animation: marquee 100s linear infinite;
}

.marquee p {
    display: inline;
    margin-left: 20px;
}

@keyframes marquee {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-50%, 0, 0);
    }
}


.testimonials-style {
    /*white-space: nowrap;*/
    overflow-x: hidden;
    overflow: -moz-hidden-unscrollable; /* Firefox */
    overflow: -webkit-hidden; /* Safari and Chrome */
    /*animation: slide 40s linear infinite;*/
}

.match-overflow {
    overflow-x: clip;
    overflow-x: -moz-clip;
    width: 100% !important;
}

.testimonial-style {

    /*overflow-x:auto*/
    width: 62vw;
    animation: slide1 50s linear infinite;
}

@keyframes slide1 {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-1700px);
    }
}




.betslip-container-mozilla {
    margin-left: 2px
}

@media (min-width: 1900px) {

}

@media (min-width: 979px) {
    #slip-holder.affix-top {
        position: static;
        margin-top: 0px;
    }

    /*for review  */
    #slip-holder.affix {
        position: fixed;
        top: 110px;
        z-index: 999;
        width: 230px;
        -webkit-transition: opacity 0.5s ease;
        -moz-transition: opacity 0.5s ease;
        -o-transition: opacity 0.5s ease;
        transition: opacity 0.5s ease;
    }


}

.affix, .affix-top {
    position: static;
}

.label {
    display: inline;
    padding: 2px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    color: #003;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}
.profile-wrap{
    white-space: nowrap;
}
.profile {
    margin-right: 0 !important;
    text-align: right;
    color: #f9f9f9;
    font-weight: 600;
}

.profile a {
    color: #fff;
}

.mobi {
    display: none;
}

.web-element {
    display: block;
}

.competitive {
    display: inline-block;
    vertical-align: middle;
    padding-top: 8px;
    padding-right: 40px;
    font-weight: 600;
    color: #fff;
}

@media (min-width: 1200px) {
    .container {
        width: 100%;
    }
}

header {
    background: #0e131b;
    color: #fff;
    opacity: 0.7;
    font-weight: 100;
    text-transform: capitalize;
    padding: 0;
    font-size: 14px;
    margin-bottom: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.67);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.67);
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.67);
}

.jackpot header {
    background: #c91d56;

}

events-header {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    background: #444444 !important;
    padding-top: 5px;
}

.pink-bg {
    background: #c91d56 !important;
}

.dropdown .fa {
    float: right;
    padding-right: 10px;
}

.header-holder {
    clear: both;
}

.matches .label, .top-matches .label {
    color: inherit;
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
    line-height: 1.7;
    font-weight: 800;
}


.neutral .mobi-odd {
    /*color:;*/
}

.helpline {
    padding: 10px 0px;
    display: inline-block;
    width: 100%;
}

.paybill {
    color: #262424cc;
    text-align: center;
    text-transform: uppercase;
    font-weight: 100 !important;
    /* padding: 30px 0; */
    margin: 5px 0;
    font-size: 15px;
    line-height: 35px;
}

.paybill .content {
    padding-top: 20px;
    padding-bottom: 30px;
}

.paybill .main {
    font-size: 55px;
    clear: both;
    display: block;
}

.support {
    background-size: cover;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0;
    margin: 5px 0 0;
    text-transform: lowercase;
    font-weight: bold;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid #fff0;
    border-radius: 5px;
    padding: 0;
    border: none;
    /*    background: #f0f0f0;
*/
}

.support {
    color: #262424cc;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    text-transform: lowercase;
    font-weight: bold;
    background: #c91d56;
    color: #ffffff;
    /* font-size: 40px !important; */
}

.support .helpline {
    font-size: 20px;
}

.support .mail {
    padding: 5px 0;
    /*background: #efefef;*/
    background: #16202C;
    font-size: 13px;
    color: #212121;
}

.support .fa {
    font-size: 16px;
}

.white {
    color: #fff;
}

.helpline h6 {
    margin-bottom: 0;
    margin-top: 0;
}

.mpesa {
    background: #fff;
}

.white-color {
    color: #fff;
    background: #26408A;
    margin-bottom: 5px !important;
}

.the-paybill {
    background: #fff;
    color: #0D0D19;
    padding: 0 5px;
    border-radius: 2px;
    font-size: 16px;
}

.home {
    overflow-x: clip;
    /*overflow-y: auto;*/
    height: 100% !important;
}

.helpline h4 {
    font-size: 15px;
    border-bottom: 1px dotted;
    padding-bottom: 5px;
}

.gz h3 {
    padding: 0 5px;
}

.table-h {
    background: #212121;
    color: #fff;
}

.clickable-row {
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer !important;
}

.paybill .odds {
    text-align: left;
    padding-left: 5px;
}


.home {
    padding: 0 !important;
}

.events-header .form-control {
    display: block;
    width: 100%;
    height: 25px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    /* color: #555; */
    background-color: #f9f3f5;
    background-image: none;
    border: 1px solid #d4d1d2 !important;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.game-id {
    background: #003193 !important;
    color: #fff !important;
}


.leftpad a {
    color: #443b24;
}


.top-nav-login {
    top: -2px;
    padding-top: var(--CrashKali-header-bg)!important;
    background-size: cover;
    background-position: center center;
    padding-bottom: 17px;
    background-size: cover;
    z-index: 9999 !important;
}

.opacity-reduce-txt {
    opacity: 0.8;
}

.opacity-reduce-txt.vs-styling {

    padding-left: 0px;
    padding-right: 5px;
    color: var(--red);
}

.std-side-pads {
    padding-right: 15px;
    padding-left: 15px;
}

.no-transform {
    text-transform: none;
}

.payment-selector {
    text-align: left;
}

.inner-content {
    padding: 10px;
}

.inner-content .fm {
    color: #ffffff;
    background-color: #c91d56;
    border: 1px solid #FFFFFF;
}

.odds {
    text-align: left;
    padding: 2px;
}

.odd-value {
    float: right;
}

.second-nav .f {
    max-width: 100%;
    padding: 0 15px !important;
}

.top-matches {
    color: #333335;
    padding: 0;
    overflow: hidden;
}

.matches .top-matches {
    padding-top: 5px;
    padding-bottom: 5px;
}

.compt-detail {
    font-size: 12px;
    opacity: 0.75;
}

.compt-teams {
    font-size: 14px;
    /*text-transform: uppercase !important;*/
}

/*todo changed styling of buttons*/
.matches .top-matches button {
    height: 30px;
    border-radius: 15px !important;
    margin-right: 4px;
    outline: none;
    box-sizing: border-box;
}

.home-team {
    background: var(--odds-button);
    color: var(--text-secondary);
    font-weight: 150 !important;
}

.away-team {
    background: #c91d56 !important;
    color: #fff !important;
    font-weight: bold !important;
}

.draw {
    background: #1e2d36 !important;
    color: #ffffff;
    font-weight: bold !important;
}



.mobi #search-event {
    padding-top: 0;
    padding-bottom: 0;
}

.top-matches button.picked {
    background: var(--picked) !important;
    color: var(--dark)!important;
}
.text-weight-md{
    font-weight:600;
}


.mobi .draw {
    color: #fff;
    font-weight: normal !important;
}

.mobi .home-team {
    background: #a11045;
    color: var(--text-btn-color);
    font-weight: normal !important;
}

a:active,
a:hover {
    outline: 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

dfn {
    font-style: italic;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

mark {
    background: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}
.date-size{
    font-size: 100%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    .ck {
        display: none;
    }

    .cg > .cl,
    .cm > .cg > .cl {
        border-top-color: #000 !important;
    }

    .cb {
        border: 1px solid #000;
    }

    .cn {
        border-collapse: collapse !important;
    }

    .cn td,
    .cn th {
        background-color: #fff !important;
    }

    .co th,
    .co td {
        border: 1px solid #ddd !important;
    }
}

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}



input,
button,
select,
textarea {
    font-size: inherit;
    line-height: inherit;
    border: 2px solid #333;
}

a {
    color: #3f9ad1;
    text-decoration: none;
}

.top-matches a {
    color: #ffffff;
}

.one_game_wrapper.top-matches a {
    color: #6C6A6A;
}

.one_game_wrapper.top-matches a.side {
    color: #fff;
    margin-top: 10px;
    text-decoration: none;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: inherit;
}

a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

figure {
    margin: 0;
}

img {
    vertical-align: middle;
    max-width: 100%;
}

.panel-header {
    background: #454545;
    color: #ffffff;
    padding: 10px;
    margin-top: 0px;
    line-height: 1;
    border: 1px solid #101B25;
    margin-bottom: 5px;
    border-radius: 2px !important;
    font-size: 18px;
}

.panel-header .header-text, .panel-header .start-time {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    opacity: 0.7;
}

.match-detail .picked {
    background: #fdd835 !important;
    color: #000 !important;
    border: none;
    font-weight: normal !important;
}

.panel-header h2 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
}

.flex {
    display: flex !important;
}

footer {
    background: #dddbdb;
    color: var(--footer-color);
    padding: 0;
    font-size: 16px;
    margin-top: 2px;
}

footer a {
    padding: 5px;
    color: #121212;
}

.jp-matches .theteam {
    text-align: center;
    width: 100%;
}


.teams p {
    margin: 0;
    text-transform: capitalize;
    font-weight: 100;
    font-size: 12px;
}

.mobi .events-header .form-control {
    display: block;
    width: 100%;
    height: 30px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    /* color: #555; */
    background-color: #fbfbfb;
    background-image: none;
    border: 1px solid #d4d1d2 !important;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    box-shadow: none;
    opacity: 1;
}

.mobi #search-event button {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 0;
    background: #c91d56 !important;
    font-size: 13px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.social {
    padding-left: 0;
    display: inline-block;
    float: right;
    text-transform: uppercase;
}

.social li {
    list-style-type: none;
    display: inline-block;
}

.social li a {
    list-style-type: none;
    padding: 5px 18px 0px;
    font-size: 38px;
    display: inline-block;
    margin: -2px;
    color: #1a1f3b;
}

.social .tw {
    background: #9092a0;
}

.social .fb {
    background: #5d6175;
    padding: 5px 25px 0px;
}

.footer-lower {
    background: #963b59 !important;
    color: #ffffff;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    /* color: #fff; */
    font-size: 14px;
    text-align: center;
    padding: 15px 0;
}

.spacing-end {
    margin-left: auto;
}

.footer-lower a {
    color: #fff;
    text-transform: capitalize;
}

.social .tw:hover {
    background: #55acee;
    color: #fff;
}

.social .fb:hover {
    background: #3b5998;
    color: #fff;
}


.bkmrk, .slp {
    padding: 8px 0 6px 15px;
}

.bkmrk {
    font-size: 18px;
    padding: 8px 0 6px 0;
    text-align: center;
}



.footer-custom ul {
    list-style: none;
}

.footer-custom ul li {
    color: var(--footer-links);
    text-decoration: none;
    opacity: 0.75;
}

.footer-custom ul li a {
    color: var(--footer-links);
}

.footer-upper {
    color: #1e1c1c;
    border-bottom: 0px solid #fff;
    padding: 30px 0;
    font-size: 15px;
    background: #A9A9A9;
}

.footer-info {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #454960;
    padding: 35px 0px;
}

.footer-bottom {
    color: #fff;
}

footer .col-sm-12, footer .col-sm-6 {
    padding: 10px 10px 10px 0;
}

.panel-header h2 {
    margin-top: 0;
}

.cp,
.cq > img,
.cq a > img,
.cr > .item > img,
.cr > .item > a > img {
    display: block;
    max-width: 100%;
    height: auto;
}

.cs {
    border-radius: 6px;
}

.ct {
    padding: 4px;
    line-height: 1.6;
    background-color: #f5f8fa;
    border: 1px solid #dddddd;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.cu {
    border-radius: 50%;
}

hr {
    margin-top: 22px;
    margin-bottom: 22px;
    border: 0;
    border-top: 1px solid #d4dbe0;
}

.mobi {
    padding: 5px;
    /* background: #030928; */
}

.mobi .side {
    /* background: #3f9ad1; */
    /* padding: 2px; */
    /* border-radius: 0; */
    /* font-size: 16px; */
    height: 24px;
    /* display: block; */
    color: #fff;
    font-weight: normal;
}

.mobi .odds .label {

    display: contents;
}

.mobi .teams {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.4;
}

.purple-bg {
    background: #523580;
    color: #fff;
}

ul.sport {

}

.mobi .meta {
    font-weight: 400;
}

.odds button {
    border: none;
    padding: 0px;
    background: #26408a;
    color: #fff;
}

.row {
    clear: both;
}

.odds .label {
    color: #fff;
    max-width: 75%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.neutral .label {
    color: #fff;
}

.mobi .odds {
    float: left;
    background: transparent;
    margin: 0px;
    height: 35px;
    text-align: center;
    font-size: 12px;
    border: none;
    font-weight: bold !important;
}

.mobi .home {
    width: 38%;
    background: #162362;
}

.mobi .neutral {
    width: 20%;
    background: #1c2d7f;
    color: #333;
}

.mobi .away {
    width: 40%;
    background: #162362;
}

.two-way .mobi .home {
    width: 50%;
    background: #162362;
}

.two-way .mobi .away {
    width: 50%;
    background: #162362;
}

.mobi .nav > li {
    position: relative;
    display: inline-block;
    padding: 5px 0;
    margin-right: 10px;
    text-transform: uppercase;
}

.mobi .in > li {
    width: 24% !important;
}

.mobi .nav > li > a {
    position: relative;
    display: inline-block;
    padding: 0;
    font-size: 10px;
    font-weight: 500;
    color: #fff;
    background: transparent !important;
}

.mobi .main-nav a {
    color: #ffffff;
}

nav .mobi {
    background: #c91d56;
    color: #ffffff;
    border-bottom: 1px solid #b18897;
    padding: 0 5px;
}

.nav > li > a:hover, .nav > li > a:active, nav > li > a:focus, .aoi > li:nth-child(2n) a:hover {
    text-decoration: none;
    background-color: transparent;
    color: #fff !important;
}

.betmobile, .betmobile td {
    color: #333;
    /* background: #8db63b; */
}

.betmobile td {
    padding: 3px;
    /* background-color: #8db63b; */
}

.sml {
    font-size: 12px;
    padding-left: 15px;
}

.betmobile h3 {
    margin: 0;
    border-bottom: 1px solid;
    color: #26408A;
    padding-bottom: 10px;
    margin-bottom: 5px;
}

.betmobile .bet-value {
    width: 80%;
    font-weight: 500;
    text-transform: uppercase;
    font-weight: normal !important;
}

.bet-option.warn {
    /* background: #999999;
    opacity: 0.7; */
}

.bet-option .row .warn {
    color: var(--orange);
    font-size: 10px;;
}

.bet-option form {
    color: #333 !important;
}

.betmobile .bet-option {
    list-style-type: none;
    margin-bottom: 5px;
    padding: 4px 4px;
    /* background: #789c31; */
    /* padding-left: 4px; */
    /* color: #fff; */
    background: #f0f0f0;
    border-bottom: 1px solid #e6e4e4;
    border-top: 1px solid #e6e4e4;
}

.clear-slip {
    color: #FE0000 !important;
    font-weight: bold;
    text-decoration: underline;
    border: none;
    background: transparent;
}

.bet-option form {
    /* color: #fff !important; */
    margin-top: 5px !important;
}

.cv {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.cx:active,
.cx:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

[role="button"] {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
.cy,
.cz,
.da,
.db,
.dc,
.dd {
    font-weight: 600;
    line-height: 1.2;
    color: inherit;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.cy small,
.cz small,
.da small,
.db small,
.dc small,
.dd small,
h1 .de,
h2 .de,
h3 .de,
h4 .de,
h5 .de,
h6 .de,
.cy .de,
.cz .de,
.da .de,
.db .de,
.dc .de,
.dd .de {
    font-weight: normal;
    line-height: 1;
    color: inherit;
}

h1,
.cy,
h2,
.cz,
h3,
.da {
    margin-top: 10px;
    margin-bottom: 11px;
}

h3.events {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: bold;
}

table.bd {
    margin-bottom: 0px;
}

h1 small,
.cy small,
h2 small,
.cz small,
h3 small,
.da small,
h1 .de,
.cy .de,
h2 .de,
.cz .de,
h3 .de,
.da .de {
    font-size: 65%;
}

h4,
.db,
h5,
.dc,
h6,
.dd {
    margin-top: 11px;
    margin-bottom: 11px;
}

h4 small,
.db small,
h5 small,
.dc small,
h6 small,
.dd small,
h4 .de,
.db .de,
h5 .de,
.dc .de,
h6 .de,
.dd .de {
    font-size: 75%;
}

h1,
.cy {
    font-size: 36px;
}

h2,
.cz {
    font-size: 20px;
}

h3, .da {
    font-size: 20px;
}

h4,
.db {
    font-size: 18px;
}

h5,
.dc {
    font-size: 14px;
}

h6,
.dd {
    font-size: 12px;
}

p {
    margin: 0 0 11px;
}

.df {
    margin-bottom: 22px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
}

@media (min-width: 768px) {
    ::-webkit-scrollbar {
        width: 5px!important;
    
    }
       
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #495666;
    }
    .df {
        font-size: 21px;
    }
    .navbar-expand-md .offcanvas{
        display:none!important
    }
    .pro-sidebar {
        width: var(--width-side-lg) !important;
    }
}

.bigger-list li {
    margin-bottom: 8px;
}

.quick-login .fm {

}

div#quick-login form input.cg.fm {
    background: #FFC428;
    color: #000000;
}

@media (max-width: 768px) {
    #myCarousel {
        margin-bottom: 0px;
    }
}

small,
.de {
    font-size: 85%;
}

mark,
.dg {
    background-color: #fcf8e3;
    padding: .2em;
}

.dh {
    text-align: left;
}

.di {
    text-align: right;
}

.dj {
    text-align: center;
}

.dk {
    text-align: justify;
}

.dl {
    white-space: nowrap;
}

.dm {
    text-transform: lowercase;
}

.dn {
    text-transform: uppercase;
}

.do {
    text-transform: capitalize;
}

.dp {
    color: #9da9b0;
}

.dq {
    color: #3097d1;
}

a.dq:hover,
a.dq:focus {
    color: #2579a9;
}

.dr {
    color: #3c763d;
}

a.dr:hover,
a.dr:focus {
    color: #2b542c;
}

.ds {
    color: #31708f;
}

a.ds:hover,
a.ds:focus {
    color: #245269;
}

.dt {
    color: #8a6d3b;
}

a.dt:hover,
a.dt:focus {
    color: #66512c;
}

.du {
    color: #a94442;
}

a.du:hover,
a.du:focus {
    color: #843534;
}

.dv {
    color: #fff;
    background-color: #3097d1;
}

a.dv:hover,
a.dv:focus {
    background-color: #2579a9;
}

.dw {
    background-color: #dff0d8;
}

a.dw:hover,
a.dw:focus {
    background-color: #c1e2b3;
}

.dx {
    background-color: #d9edf7;
}

a.dx:hover,
a.dx:focus {
    background-color: #afd9ee;
}

.dy {
    background-color: #fcf8e3;
}

a.dy:hover,
a.dy:focus {
    background-color: #f7ecb5;
}

.dz {
    background-color: #f2dede;
}

a.dz:hover,
a.dz:focus {
    background-color: #e4b9b9;
}

.ea {
    padding-bottom: 10px;
    margin: 44px 0 22px;
    border-bottom: 1px solid #d4dbe0;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0px;
}

ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}

.eb {
    padding-left: 0;
    list-style: none;
}

.ec {
    padding-left: 0;
    list-style: none;
    margin-left: -5px;
}

.ec > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

dl {
    margin-top: 0;
    margin-bottom: 22px;
}

dt,
dd {
    line-height: 1.6;
}

dt {
    font-weight: bold;
}

dd {
    margin-left: 0;
}

@media (min-width: 768px) {
    .ed dt {
        float: left;
        width: 160px;
        clear: left;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ed dd {
        margin-left: 180px;
    }
}

abbr[title],
abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted #9da9b0;
}

.ee {
    font-size: 90%;
    text-transform: uppercase;
}

blockquote {
    padding: 11px 22px;
    margin: 0 0 22px;
    font-size: 17.5px;
    border-left: 5px solid #d4dbe0;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .de {
    display: block;
    font-size: 80%;
    line-height: 1.6;
    color: #9da9b0;
}

blockquote footer:before,
blockquote small:before,
blockquote .de:before {
    content: '\2014 \00A0';
}

.ef,
blockquote.eg {
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid #d4dbe0;
    border-left: 0;
    text-align: right;
}

.ef footer:before,
blockquote.eg footer:before,
.ef small:before,
blockquote.eg small:before,
.ef .de:before,
blockquote.eg .de:before {
    content: '';
}

.ef footer:after,
blockquote.eg footer:after,
.ef small:after,
blockquote.eg small:after,
.ef .de:after,
blockquote.eg .de:after {
    content: '\00A0 \2014';
}

address {
    margin-bottom: 22px;
    font-style: normal;
    line-height: 1.6;
}

code,
kbd,
pre,
samp {

}
code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
}

kbd {
    padding: 2px 4px;
    font-size: 90%;
    color: #ffffff;
    background-color: #333333;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold;
    box-shadow: none;
}

pre {
    display: block;
    padding: 10.5px;
    margin: 0 0 11px;
    font-size: 13px;
    line-height: 1.6;
    word-break: break-all;
    word-wrap: break-word;
    color: #1e3948;
    background-color: #f5f5f5;
    border: 1px solid #cccccc;
    border-radius: 4px;
}

pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
}

.eh {
    max-height: 340px;
    overflow-y: scroll;
}

table {
    background-color: var(--CrashKali-primary);
    width: 100%;
}

#pos_win {
    font-weight: bold;
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #9da9b0;
    text-align: left;
}

th {
    text-align: left;
}

.cn {
    width: 100%;
    max-width: 100%;
    margin-bottom: 22px;
}

.cn > thead > tr > th,
.cn > tbody > tr > th,
.cn > tfoot > tr > th,
.cn > thead > tr > td,
.cn > tbody > tr > td,
.cn > tfoot > tr > td {
    padding: 8px;
    line-height: 1.6;
    vertical-align: top;
    border-top: 1px solid #dddddd;
}

.cn > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #dddddd;
}

.cn > caption + thead > tr:first-child > th,
.cn > colgroup + thead > tr:first-child > th,
.cn > thead:first-child > tr:first-child > th,
.cn > caption + thead > tr:first-child > td,
.cn > colgroup + thead > tr:first-child > td,
.cn > thead:first-child > tr:first-child > td {
    border-top: 0;
}

.cn > tbody + tbody {
    border-top: 2px solid #dddddd;
}

.cn .cn {
    background-color: #f5f8fa;
}

.ei > thead > tr > th,
.ei > tbody > tr > th,
.ei > tfoot > tr > th,
.ei > thead > tr > td,
.ei > tbody > tr > td,
.ei > tfoot > tr > td {
    padding: 5px;
}

.co {
    border: 1px solid #dddddd;
}

.co > thead > tr > th,
.co > tbody > tr > th,
.co > tfoot > tr > th,
.co > thead > tr > td,
.co > tbody > tr > td,
.co > tfoot > tr > td {
    border: 1px solid #dddddd;
}

.co > thead > tr > th,
.co > thead > tr > td {
    border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.ej > tbody > tr:hover {
    background-color: #f5f5f5;
}

table col[class*="col-"] {
    position: static;
    float: none;
    display: table-column;
}

table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    float: none;
    display: table-cell;
}

.cn > thead > tr > td.active,
.cn > tbody > tr > td.active,
.cn > tfoot > tr > td.active,
.cn > thead > tr > th.active,
.cn > tbody > tr > th.active,
.cn > tfoot > tr > th.active,
.cn > thead > tr.active > td,
.cn > tbody > tr.active > td,
.cn > tfoot > tr.active > td,
.cn > thead > tr.active > th,
.cn > tbody > tr.active > th,
.cn > tfoot > tr.active > th {
    background-color: #f5f5f5;
}

.ej > tbody > tr > td.active:hover,
.ej > tbody > tr > th.active:hover,
.ej > tbody > tr.active:hover > td,
.ej > tbody > tr:hover > .active,
.ej > tbody > tr.active:hover > th {
    background-color: #e8e8e8;
}

.cn > thead > tr > td.ek,
.cn > tbody > tr > td.ek,
.cn > tfoot > tr > td.ek,
.cn > thead > tr > th.ek,
.cn > tbody > tr > th.ek,
.cn > tfoot > tr > th.ek,
.cn > thead > tr.ek > td,
.cn > tbody > tr.ek > td,
.cn > tfoot > tr.ek > td,
.cn > thead > tr.ek > th,
.cn > tbody > tr.ek > th,
.cn > tfoot > tr.ek > th {
    background-color: #dff0d8;
}

.ej > tbody > tr > td.ek:hover,
.ej > tbody > tr > th.ek:hover,
.ej > tbody > tr.ek:hover > td,
.ej > tbody > tr:hover > .ek,
.ej > tbody > tr.ek:hover > th {
    background-color: #d0e9c6;
}

.cn > thead > tr > td.el,
.cn > tbody > tr > td.el,
.cn > tfoot > tr > td.el,
.cn > thead > tr > th.el,
.cn > tbody > tr > th.el,
.cn > tfoot > tr > th.el,
.cn > thead > tr.el > td,
.cn > tbody > tr.el > td,
.cn > tfoot > tr.el > td,
.cn > thead > tr.el > th,
.cn > tbody > tr.el > th,
.cn > tfoot > tr.el > th {
    background-color: #d9edf7;
}

.ej > tbody > tr > td.el:hover,
.ej > tbody > tr > th.el:hover,
.ej > tbody > tr.el:hover > td,
.ej > tbody > tr:hover > .el,
.ej > tbody > tr.el:hover > th {
    background-color: #c4e3f3;
}

.cn > thead > tr > td.em,
.cn > tbody > tr > td.em,
.cn > tfoot > tr > td.em,
.cn > thead > tr > th.em,
.cn > tbody > tr > th.em,
.cn > tfoot > tr > th.em,
.cn > thead > tr.em > td,
.cn > tbody > tr.em > td,
.cn > tfoot > tr.em > td,
.cn > thead > tr.em > th,
.cn > tbody > tr.em > th,
.cn > tfoot > tr.em > th {
    background-color: #fcf8e3;
}

.ej > tbody > tr > td.em:hover,
.ej > tbody > tr > th.em:hover,
.ej > tbody > tr.em:hover > td,
.ej > tbody > tr:hover > .em,
.ej > tbody > tr.em:hover > th {
    background-color: #faf2cc;
}

.cn > thead > tr > td.en,
.cn > tbody > tr > td.en,
.cn > tfoot > tr > td.en,
.cn > thead > tr > th.en,
.cn > tbody > tr > th.en,
.cn > tfoot > tr > th.en,
.cn > thead > tr.en > td,
.cn > tbody > tr.en > td,
.cn > tfoot > tr.en > td,
.cn > thead > tr.en > th,
.cn > tbody > tr.en > th,
.cn > tfoot > tr.en > th {
    background-color: #f2dede;
}

.ej > tbody > tr > td.en:hover,
.ej > tbody > tr > th.en:hover,
.ej > tbody > tr.en:hover > td,
.ej > tbody > tr:hover > .en,
.ej > tbody > tr.en:hover > th {
    background-color: #ebcccc;
}

.eo {
    overflow-x: auto;
    min-height: 0.01%;
}

@media screen and (max-width: 767px) {
    .eo {
        width: 100%;
        margin-bottom: 16.5px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #dddddd;
    }

    .eo > .cn {
        margin-bottom: 0;
    }

    .eo > .cn > thead > tr > th,
    .eo > .cn > tbody > tr > th,
    .eo > .cn > tfoot > tr > th,
    .eo > .cn > thead > tr > td,
    .eo > .cn > tbody > tr > td,
    .eo > .cn > tfoot > tr > td {
        white-space: nowrap;
    }

    .eo > .co {
        border: 0;
    }

    .eo > .co > thead > tr > th:first-child,
    .eo > .co > tbody > tr > th:first-child,
    .eo > .co > tfoot > tr > th:first-child,
    .eo > .co > thead > tr > td:first-child,
    .eo > .co > tbody > tr > td:first-child,
    .eo > .co > tfoot > tr > td:first-child {
        border-left: 0;
    }

    .eo > .co > thead > tr > th:last-child,
    .eo > .co > tbody > tr > th:last-child,
    .eo > .co > tfoot > tr > th:last-child,
    .eo > .co > thead > tr > td:last-child,
    .eo > .co > tbody > tr > td:last-child,
    .eo > .co > tfoot > tr > td:last-child {
        border-right: 0;
    }

    .eo > .co > tbody > tr:last-child > th,
    .eo > .co > tfoot > tr:last-child > th,
    .eo > .co > tbody > tr:last-child > td,
    .eo > .co > tfoot > tr:last-child > td {
        border-bottom: 0;
    }
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 22px;
    font-size: 21px;
    line-height: inherit;
    color: #1e3948;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}


input[type="search"] {
    box-sizing: border-box;
}



input[type="file"] {
    display: block;
}

input[type="range"] {
    display: block;
    width: 100%;
}

select[multiple],
select[size] {
    height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.by form {
    font-size: 12px;
    margin-top: 15px;
    overflow: hidden;
    color: #fff;
    font-weight: normal;
}

#search-event form {
    margin-top: 5px;
    opacity: 0.9;
}

#slip-holder {

}

.payment-selector .panel-heading {
    font-size: 14px !important;
}

.payment-preview-img {
    max-width: 80px;
    max-height: 30px;
    float: right;
    display: inline-block;
}

.company-number {
    font-size: 50px;
    background: #cc5500;
    line-height: 50px;
    color: #fff;
    margin-top: -15px;
    padding-bottom: 15px;
    padding-top: 10px;
    text-shadow: 3px 1px 1px #000;
    /* font-weight: bolder !important; */
}

.top-matches {
    overflow: hidden;
}

.panel-default {
    counter-reset: item;
}

.panel-default {

}

.panel-default li {
    counter-increment: item;
    margin-bottom: 5px;
    background: transparent !important;
    font-size: 15px;
    font-weight: bold;
    text-shadow: none !important;
    padding: 0 !important;
}
.small{
    width:max-content;
}
.no-pad-left {
    padding-left: 0;
}

.panel-default li::before {
    margin-right: 10px;
    content: counter(item);
    background: #c91d56;
    border-radius: 100%;
    color: white;
    width: 20px;
    text-align: center;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    font-weight: bolder;
}

.pay-highlight {
    font-weight: bolder;
}

.panel-default ol {
    list-style: none;
}

.panel-default ul {
    list-style: none;
    padding: 0;
}

.payment-selector label {
    margin-bottom: 0;
    font-weight: normal;
}

output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.6;
    color: #536570;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0px 12px;
    font-size: 14px;
    line-height: 1.6;
    color: #504d4d;
    background-color: #ffffff;
    background-image: none;
    border: none;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 0.6;
    font-weight: 100 !important;
}

.form-control:-ms-input-placeholder {
    color: #999;
    opacity: 0.6;
    font-weight: 100 !important;
}

.form-control::-webkit-input-placeholder {
    color: #999;
    opacity: 0.6;
    font-weight: 100 !important;
}

.form-control::-ms-expand {
    border: 0;
    background-color: transparent;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #c5c5c5;
    opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
    cursor: not-allowed;
}

textarea.form-control {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"].form-control,
    input[type="time"].form-control,
    input[type="datetime-local"].form-control,
    input[type="month"].form-control {
        line-height: 36px;
    }

    input[type="date"].ep,
    input[type="time"].ep,
    input[type="datetime-local"].ep,
    input[type="month"].ep,
    .eq input[type="date"],
    .eq input[type="time"],
    .eq input[type="datetime-local"],
    .eq input[type="month"] {
        line-height: 30px;
    }

    input[type="date"].er,
    input[type="time"].er,
    input[type="datetime-local"].er,
    input[type="month"].er,
    .es input[type="date"],
    .es input[type="time"],
    .es input[type="datetime-local"],
    .es input[type="month"] {
        line-height: 46px;
    }
}

.et {
    margin-bottom: 15px;
}

.eu,
.ev {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.eu label,
.ev label {
    min-height: 22px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}

.eu input[type="radio"],
.ew input[type="radio"],
.ev input[type="checkbox"],
.ex input[type="checkbox"] {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px \9;
}

.eu + .eu,
.ev + .ev {
    margin-top: -5px;
}

.ew,
.ex {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
}

.ew + .ew,
.ex + .ex {
    margin-top: 0;
    margin-left: 10px;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
    cursor: not-allowed;
}

.ew.disabled,
.ex.disabled,
fieldset[disabled] .ew,
fieldset[disabled] .ex {
    cursor: not-allowed;
}

.eu.disabled label,
.ev.disabled label,
fieldset[disabled] .eu label,
fieldset[disabled] .ev label {
    cursor: not-allowed;
}

.ey {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
    min-height: 36px;
}

.ey.er,
.ey.ep {
    padding-left: 0;
    padding-right: 0;
}

.ep {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

select.ep {
    height: 30px;
    line-height: 30px;
}

textarea.ep,
select[multiple].ep {
    height: auto;
}

.ez .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.ez select.form-control {
    height: 30px;
    line-height: 30px;
}

.ez textarea.form-control,
.ez select[multiple].form-control {
    height: auto;
}

.ez .ey {
    height: 30px;
    min-height: 34px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.er {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

select.er {
    height: 46px;
    line-height: 46px;
}

textarea.er,
select[multiple].er {
    height: auto;
}

.fa .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

.fa select.form-control {
    height: 46px;
    line-height: 46px;
}

.fa textarea.form-control,
.fa select[multiple].form-control {
    height: auto;
}

.fa .ey {
    height: 46px;
    min-height: 40px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.fb {
    position: relative;
}

.fb .form-control {
    padding-right: 45px;
}

.fc {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    pointer-events: none;
}

.er + .fc,
.es + .fc,
.fa .form-control + .fc {
    width: 46px;
    height: 46px;
    line-height: 46px;
}

.ep + .fc,
.eq + .fc,
.ez .form-control + .fc {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.fd .fe,
.fd .ff,
.fd .eu,
.fd .ev,
.fd .ew,
.fd .ex,
.fd.eu label,
.fd.ev label,
.fd.ew label,
.fd.ex label {
    color: #3c763d;
}

.fd .form-control {
    border-color: #3c763d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.fd .form-control:focus {
    border-color: #2b542c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.fd .input-group-addon {
    color: #3c763d;
    border-color: #3c763d;
    background-color: #dff0d8;
}

.fd .fc {
    color: #3c763d;
}

.fg .fe,
.fg .ff,
.fg .eu,
.fg .ev,
.fg .ew,
.fg .ex,
.fg.eu label,
.fg.ev label,
.fg.ew label,
.fg.ex label {
    color: #8a6d3b;
}

.fg .form-control {
    border-color: #8a6d3b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.fg .form-control:focus {
    border-color: #66512c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.fg .input-group-addon {
    color: #8a6d3b;
    border-color: #8a6d3b;
    background-color: #fcf8e3;
}

.fg .fc {
    color: #8a6d3b;
}

.fh .fe,
.fh .ff,
.fh .eu,
.fh .ev,
.fh .ew,
.fh .ex,
.fh.eu label,
.fh.ev label,
.fh.ew label,
.fh.ex label {
    color: #a94442;
}

.fh .form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.fh .form-control:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.fh .input-group-addon {
    color: #a94442;
    border-color: #a94442;
    background-color: #f2dede;
}

.fh .fc {
    color: #a94442;
}

.fb label ~ .fc {
    top: 27px;
}

.fb label.cv ~ .fc {
    top: 0;
}

.fe {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #4480a2;
}

@media (min-width: 768px) {
    .span-change {
        width: 25% !important;
    }

    .fi .et {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .fi .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .fi .ey {
        display: inline-block;
    }

    .fi .input-group {
        display: inline-table;
        vertical-align: middle;
    }

    .fi .input-group .input-group-addon,
    .fi .input-group .fj,
    .fi .input-group .form-control {
        width: auto;
    }

    .fi .input-group > .form-control {
        width: 100%;
    }

    .fi .ff {
        margin-bottom: 0;
        vertical-align: middle;
    }

    .fi .eu,
    .fi .ev {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .fi .eu label,
    .fi .ev label {
        padding-left: 0;
    }

    .fi .eu input[type="radio"],
    .fi .ev input[type="checkbox"] {
        position: relative;
        margin-left: 0;
    }

    .fi .fb .fc {
        top: 0;
    }
}

.fk .eu,
.fk .ev,
.fk .ew,
.fk .ex {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 7px;
}

.fk .eu,
.fk .ev {
    min-height: 29px;
}

.fk .et {
    margin-left: -15px;
    margin-right: -15px;
}

@media (min-width: 768px) {
    .fk .ff {
        text-align: right;
        margin-bottom: 0;
        padding-top: 7px;
    }
}

.fk .fb .fc {
    right: 15px;
}

@media (min-width: 768px) {
    .fk .fa .ff {
        padding-top: 11px;
        font-size: 18px;
    }
}

@media (min-width: 768px) {
    .fk .ez .ff {
        padding-top: 6px;
        font-size: 12px;
    }
}

.cg {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: none;
    white-space: nowrap;
    padding: 6px 10px;
    font-size: 14px;
    line-height: 1.6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cg:focus,
.cg:active:focus,
.cg.active:focus,
.cg.fl,
.cg:active.fl,
.cg.active.fl {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.cg:hover,
.cg:focus,
.cg.fl {
    color: #536570;
    text-decoration: none;
}

.cg:active,
.cg.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.cg.disabled,
.cg[disabled],
fieldset[disabled] .cg {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none;
}

a.cg.disabled,
fieldset[disabled] a.cg {
    pointer-events: none;
}

.fm {
    color: #FFF;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
}

.et .fm {
    margin-left: -4px;
}

.leftpad {
    margin-left: 5px;
}

.et input {
    margin-bottom: 3px;
}

.fm:focus,
.fm.fl {
    color: #fff;
}

.fm:hover {
    color: #fff;
}

.fm:active,
.fm.active,
.open > .fn.fm {
    color: #536570;
    background-color: #e6e6e6;
    border-color: #b0bdc6;
}

.fm:active:hover,
.fm.active:hover,
.open > .fn.fm:hover,
.fm:active:focus,
.fm.active:focus,
.open > .fn.fm:focus,
.fm:active.fl,
.fm.active.fl,
.open > .fn.fm.fl {
    color: #536570;
    background-color: #d4d4d4;
    border-color: #8a9dab;
}

.fm:active,
.fm.active,
.open > .fn.fm {
    background-image: none;
}

.fm.disabled:hover,
.fm[disabled]:hover,
fieldset[disabled] .fm:hover,
.fm.disabled:focus,
.fm[disabled]:focus,
fieldset[disabled] .fm:focus,
.fm.disabled.fl,
.fm[disabled].fl,
fieldset[disabled] .fm.fl {
    background-color: #ffffff;
    border-color: #d4dbe0;
}

.fm .fo {
    color: #ffffff;
    background-color: #536570;
}

.fp {
    color: #ffffff;
    background-color: #527994;
    border-color: #24367e;
}

.join-desc {
    display: block;
    font-size: 10px;
    font-weight: normal;
}

.sign-up {
    line-height: 1;
}

.sign-up.btn {
    padding: 7px 25px;
    margin-right: 35px;
    font-size: 15px;
}

.ss.profile {
    padding-bottom: 7px;
    padding-top: 5px;
    width: auto;
    font-size: 12px;
    font-weight: normal;
}

.light-bg {
    background: #ff689a;
    color: #000;
}

.primary-bg {
    background: var(--CrashKali-primary);
    color: var(--light);
}

.fp:focus,
.fp.fl {
    color: #ffffff;
    background-color: #C91250;
    border-color: #133d55;
}

.fp:hover {
    color: #ffffff;
    background-color: #a90a2b;
    border-color: #86304d;
}

.fp:active,
.fp.active,
.open > .fn.fp {
    color: #ffffff;
    background-color: #C91250;
    border-color: #1f648b;
}

.fp:active:hover,
.fp.active:hover,
.open > .fn.fp:hover,
.fp:active:focus,
.fp.active:focus,
.open > .fn.fp:focus,
.fp:active.fl,
.fp.active.fl,
.open > .fn.fp.fl {
    color: #ffffff;
    background-color: #1f648b;
    border-color: #133d55;
}

.fp:active,
.fp.active,
.open > .fn.fp {
    background-image: none;
}

.fp.disabled:hover,
.fp[disabled]:hover,
fieldset[disabled] .fp:hover,
.fp.disabled:focus,
.fp[disabled]:focus,
fieldset[disabled] .fp:focus,
.fp.disabled.fl,
.fp[disabled].fl,
fieldset[disabled] .fp.fl {
    background-color: #3097d1;
    border-color: #2a88bd;
}

.fp .fo {
    color: #3097d1;
    background-color: #ffffff;
}

.fq {
    color: #ffffff;
    background-color: #4eb76e;
    border-color: #44a762;
}

.fq:focus,
.fq.fl {
    color: #ffffff;
    background-color: #3d9558;
    border-color: #1f4d2d;
}

.fq:hover {
    color: #ffffff;
    background-color: #3d9558;
    border-color: #327c49;
}

.fq:active,
.fq.active,
.open > .fn.fq {
    color: #ffffff;
    background-color: #3d9558;
    border-color: #327c49;
}

.fq:active:hover,
.fq.active:hover,
.open > .fn.fq:hover,
.fq:active:focus,
.fq.active:focus,
.open > .fn.fq:focus,
.fq:active.fl,
.fq.active.fl,
.open > .fn.fq.fl {
    color: #ffffff;
    background-color: #327c49;
    border-color: #1f4d2d;
}

.fq:active,
.fq.active,
.open > .fn.fq {
    background-image: none;
}

.fq.disabled:hover,
.fq[disabled]:hover,
fieldset[disabled] .fq:hover,
.fq.disabled:focus,
.fq[disabled]:focus,
fieldset[disabled] .fq:focus,
.fq.disabled.fl,
.fq[disabled].fl,
fieldset[disabled] .fq.fl {
    background-color: #4eb76e;
    border-color: #44a762;
}

.fq .fo {
    color: #4eb76e;
    background-color: #ffffff;
}

.fr {
    color: #ffffff;
    background-color: #8eb4cb;
    border-color: #7da8c3;
}

.fr:focus,
.fr.fl {
    color: #ffffff;
    background-color: #6b9dbb;
    border-color: #3d6983;
}

.fr:hover {
    color: #ffffff;
    background-color: #6b9dbb;
    border-color: #538db0;
}

.fr:active,
.fr.active,
.open > .fn.fr {
    color: #ffffff;
    background-color: #6b9dbb;
    border-color: #538db0;
}

.fr:active:hover,
.fr.active:hover,
.open > .fn.fr:hover,
.fr:active:focus,
.fr.active:focus,
.open > .fn.fr:focus,
.fr:active.fl,
.fr.active.fl,
.open > .fn.fr.fl {
    color: #ffffff;
    background-color: #538db0;
    border-color: #3d6983;
}

.fr:active,
.fr.active,
.open > .fn.fr {
    background-image: none;
}

.fr.disabled:hover,
.fr[disabled]:hover,
fieldset[disabled] .fr:hover,
.fr.disabled:focus,
.fr[disabled]:focus,
fieldset[disabled] .fr:focus,
.fr.disabled.fl,
.fr[disabled].fl,
fieldset[disabled] .fr.fl {
    background-color: #8eb4cb;
    border-color: #7da8c3;
}

.fr .fo {
    color: #8eb4cb;
    background-color: #ffffff;
}

.fs {
    color: #ffffff;
    background-color: #cbb956;
    border-color: #c5b142;
}

.fs:focus,
.fs.fl {
    color: #ffffff;
    background-color: #b6a338;
    border-color: #685d20;
}

.fs:hover {
    color: #ffffff;
    background-color: #b6a338;
    border-color: #9b8a30;
}

.fs:active,
.fs.active,
.open > .fn.fs {
    color: #ffffff;
    background-color: #b6a338;
    border-color: #9b8a30;
}

.fs:active:hover,
.fs.active:hover,
.open > .fn.fs:hover,
.fs:active:focus,
.fs.active:focus,
.open > .fn.fs:focus,
.fs:active.fl,
.fs.active.fl,
.open > .fn.fs.fl {
    color: #ffffff;
    background-color: #9b8a30;
    border-color: #685d20;
}

.fs:active,
.fs.active,
.open > .fn.fs {
    background-image: none;
}

.fs.disabled:hover,
.fs[disabled]:hover,
fieldset[disabled] .fs:hover,
.fs.disabled:focus,
.fs[disabled]:focus,
fieldset[disabled] .fs:focus,
.fs.disabled.fl,
.fs[disabled].fl,
fieldset[disabled] .fs.fl {
    background-color: #cbb956;
    border-color: #c5b142;
}

.fs .fo {
    color: #cbb956;
    background-color: #ffffff;
}

.ft {
    color: #ffffff;
    background-color: #bf5329;
    border-color: #aa4a24;
}

.ft:focus,
.ft.fl {
    color: #ffffff;
    background-color: #954120;
    border-color: #411c0e;
}

.ft:hover {
    color: #ffffff;
    background-color: #954120;
    border-color: #78341a;
}

.ft:active,
.ft.active,
.open > .fn.ft {
    color: #ffffff;
    background-color: #954120;
    border-color: #78341a;
}

.ft:active:hover,
.ft.active:hover,
.open > .fn.ft:hover,
.ft:active:focus,
.ft.active:focus,
.open > .fn.ft:focus,
.ft:active.fl,
.ft.active.fl,
.open > .fn.ft.fl {
    color: #ffffff;
    background-color: #78341a;
    border-color: #411c0e;
}

.ft:active,
.ft.active,
.open > .fn.ft {
    background-image: none;
}

.ft.disabled:hover,
.ft[disabled]:hover,
fieldset[disabled] .ft:hover,
.ft.disabled:focus,
.ft[disabled]:focus,
fieldset[disabled] .ft:focus,
.ft.disabled.fl,
.ft[disabled].fl,
fieldset[disabled] .ft.fl {
    background-color: #bf5329;
    border-color: #aa4a24;
}

.ft .fo {
    color: #bf5329;
    background-color: #ffffff;
}

.fu {
    color: #3097d1;
    font-weight: normal;
    border-radius: 0;
}

.fu,
.fu:active,
.fu.active,
.fu[disabled],
fieldset[disabled] .fu {
    background-color: transparent;
    box-shadow: none;
}

.fu,
.fu:hover,
.fu:focus,
.fu:active {
    border-color: transparent;
}

.fu:hover,
.fu:focus {
    color: #216a94;
    text-decoration: underline;
    background-color: transparent;
}

.fu[disabled]:hover,
fieldset[disabled] .fu:hover,
.fu[disabled]:focus,
fieldset[disabled] .fu:focus {
    color: #9da9b0;
    text-decoration: none;
}

.fv,
.fw > .cg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

.fx,
.fy > .cg {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.fz,
.ga > .cg {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block + .btn-block {
    margin-top: 5px;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.by {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {
    .by {
        width: 100%;
    }

    .container {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .mobile-menu.mobile-menu-jackpot {
        background-color: #16202C !important;
        position: -webkit-sticky;
        position: sticky!important;
        /* position: fixed; */
        bottom: 0;
        visibility: visible;
        height: 100px;
        width: 100%;
        z-index: 2000 !important;
        font-size: 13px !important;
        display: flex;
        justify-content: space-around;
    }
    .opacity-reduce-txt.vs-styling {
        font-size: var(--font-size-large);}
    .container-size{
        width:100%!important
    }
    .by {
        width: 100%;
    }
    .off-canvas {
        display: block;
        width: 40%!important;
        /* margin-top: 4rem!important; */
    }
    .desktop-none{
        display:none!important;
    }
}



.gb {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}


.gd, .ge, .gf, .gg, .gh, .gi, .gj, .gk, .gl, .gm, .gn, .go, .gp, .gq, .gr, .gs, .gt, .gu, .gv, .gw, .gx, .gy, .gz, .ha, .hb, .hc, .hd, .he, .hf, .hg, .hh, .hi, .hj, .hk, .hl, .hm, .hn, .ho, .hp, .hq, .hr, .hs, .ht, .hu, .hv, .hw, .hx, .hy {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.gd, .gh, .gl, .gp, .gt, .gx, .hb, .hf, .hj, .hn, .hr, .hv {
    float: left;
}

.hv {
    width: 100%;
}

.hr {
    width: 91.66666667%;
}

.hn {
    width: 83.33333333%;
}

.hj {
    width: 75%;
}

.hf {
    width: 66.66666667%;
}

.hb {
    width: 58.33333333%;
}

.gx {
    width: 50%;
}

.gt {
    width: 41.66666667%;
}

.gp {
    width: 33.33333333%;
}

.gl {
    width: 25%;
}

.gh {
    width: 16.66666667%;
}

.gd {
    width: 8.33333333%;
}

.hz {
    right: 100%;
}

.ia {
    right: 91.66666667%;
}

.ib {
    right: 83.33333333%;
}

.ic {
    right: 75%;
}

.id {
    right: 66.66666667%;
}

.ie {
    right: 58.33333333%;
}

.if {
    right: 50%;
}

.ig {
    right: 41.66666667%;
}

.ih {
    right: 33.33333333%;
}

.ii {
    right: 25%;
}

.ij {
    right: 16.66666667%;
}

.ik {
    right: 8.33333333%;
}

.il {
    right: auto;
}

.im {
    left: 100%;
}

.io {
    left: 91.66666667%;
}

.ip {
    left: 83.33333333%;
}

.iq {
    left: 75%;
}

.ir {
    left: 66.66666667%;
}

.is {
    left: 58.33333333%;
}

.it {
    left: 50%;
}

.iu {
    left: 41.66666667%;
}

.iv {
    left: 33.33333333%;
}

.iw {
    left: 25%;
}

.ix {
    left: 16.66666667%;
}

.iy {
    left: 8.33333333%;
}

.iz {
    left: auto;
}

.ja {
    margin-left: 100%;
}

.jb {
    margin-left: 91.66666667%;
}

.jc {
    margin-left: 83.33333333%;
}

.jd {
    margin-left: 75%;
}

.je {
    margin-left: 66.66666667%;
}

.jf {
    margin-left: 58.33333333%;
}

.jg {
    margin-left: 50%;
}

.jh {
    margin-left: 41.66666667%;
}

.ji {
    margin-left: 33.33333333%;
}

.jj {
    margin-left: 25%;
}

.jk {
    margin-left: 16.66666667%;
}

.jl {
    margin-left: 8.33333333%;
}

.jm {
    margin-left: 0%;
}

@media (min-width: 768px) {
    .ge, .gi, .gm, .gq, .gu, .gy, .hc, .hg, .hk, .ho, .hs, .hw {
        float: left;
    }

    .hw {
        width: 100%;
    }

    .hs {
        width: 91.66666667%;
    }

    .ho {
        width: 83.33333333%;
    }

    .hk {
        width: 75%;
    }

    .hg {
        width: 66.66666667%;
    }

    .hc {
        width: 58.33333333%;
    }

    .gy {
        width: 50%;
    }

    .gu {
        width: 41.66666667%;
    }

    .gq {
        width: 33.33333333%;
    }

    .gm {
        width: 25%;
    }

    .gi {
        width: 16.66666667%;
    }

    .ge {
        width: 8.33333333%;
    }

    .jn {
        right: 100%;
    }

    .jo {
        right: 91.66666667%;
    }

    .jp {
        right: 83.33333333%;
    }

    .jq {
        right: 75%;
    }

    .jr {
        right: 66.66666667%;
    }

    .js {
        right: 58.33333333%;
    }

    .jt {
        right: 50%;
    }

    .ju {
        right: 41.66666667%;
    }

    .jv {
        right: 33.33333333%;
    }

    .jw {
        right: 25%;
    }

    .jx {
        right: 16.66666667%;
    }

    .jy {
        right: 8.33333333%;
    }

    .jz {
        right: auto;
    }

    .ka {
        left: 100%;
    }

    .kb {
        left: 91.66666667%;
    }

    .kc {
        left: 83.33333333%;
    }

    .kd {
        left: 75%;
    }

    .ke {
        left: 66.66666667%;
    }

    .kf {
        left: 58.33333333%;
    }

    .kg {
        left: 50%;
    }

    .kh {
        left: 41.66666667%;
    }

    .ki {
        left: 33.33333333%;
    }

    .kj {
        left: 25%;
    }

    .kk {
        left: 16.66666667%;
    }

    .kl {
        left: 8.33333333%;
    }

    .km {
        left: auto;
    }

    .kn {
        margin-left: 100%;
    }

    .ko {
        margin-left: 91.66666667%;
    }

    .kp {
        margin-left: 83.33333333%;
    }

    .kq {
        margin-left: 75%;
    }

    .kr {
        margin-left: 66.66666667%;
    }

    .ks {
        margin-left: 58.33333333%;
    }

    .kt {
        margin-left: 50%;
    }

    .ku {
        margin-left: 41.66666667%;
    }

    .kv {
        margin-left: 33.33333333%;
    }

    .kw {
        margin-left: 25%;
    }

    .kx {
        margin-left: 16.66666667%;
    }

    .ky {
        margin-left: 8.33333333%;
    }

    .kz {
        margin-left: 0%;
    }
}

@media (min-width: 992px) {
    .gf, .gj, .gn, .gr, .gv, .gz, .hd, .hh, .hl, .hp, .ht, .hx {
        float: left;
    }

    .hx {
        width: 100%;
    }

    .ht {
        width: 91.66666667%;
    }

    .hp {
        width: 83.33333333%;
    }

    .hl {
        width: 75%;
    }

    .hh {
        width: 66.66666667%;
    }

    .hd {
        width: 58.33333333%;
    }

    .gz {
        width: 56%;
    }

    .gv {
        width: 41.66666667%;
    }

    .gr {
        width: 33.33333333%;
    }

    .gn {
        width: 22%;
    }

    .gj {
        width: 16.66666667%;
    }

    .gf {
        width: 8.33333333%;
    }

    .la {
        right: 100%;
    }

    .lb {
        right: 91.66666667%;
    }

    .lc {
        right: 83.33333333%;
    }

    .ld {
        right: 75%;
    }

    .le {
        right: 66.66666667%;
    }

    .lf {
        right: 58.33333333%;
    }

    .lg {
        right: 50%;
    }

    .lh {
        right: 41.66666667%;
    }

    .li {
        right: 33.33333333%;
    }

    .lj {
        right: 25%;
    }

    .lk {
        right: 16.66666667%;
    }

    .ll {
        right: 8.33333333%;
    }

    .lm {
        right: auto;
    }

    .ln {
        left: 100%;
    }

    .lo {
        left: 91.66666667%;
    }

    .lp {
        left: 83.33333333%;
    }

    .lq {
        left: 75%;
    }

    .lr {
        left: 66.66666667%;
    }

    .ls {
        left: 58.33333333%;
    }

    .lt {
        left: 50%;
    }

    .lu {
        left: 41.66666667%;
    }

    .lv {
        left: 33.33333333%;
    }

    .lw {
        left: 25%;
    }

    .lx {
        left: 16.66666667%;
    }

    .ly {
        left: 8.33333333%;
    }

    .lz {
        left: auto;
    }

    .ma {
        margin-left: 100%;
    }

    .mb {
        margin-left: 91.66666667%;
    }

    .mc {
        margin-left: 83.33333333%;
    }

    .md {
        margin-left: 75%;
    }

    .me {
        margin-left: 66.66666667%;
    }

    .mf {
        margin-left: 58.33333333%;
    }

    .mg {
        margin-left: 50%;
    }

    .mh {
        margin-left: 41.66666667%;
    }

    .mi {
        margin-left: 33.33333333%;
    }

    .mj {
        margin-left: 25%;
    }

    .mk {
        margin-left: 16.66666667%;
    }

    .ml {
        margin-left: 8.33333333%;
    }

    .mm {
        margin-left: 0%;
    }
}


.fade {
    /*transition: opacity 0.1s linear;*/
}

.fade.in {
    opacity: 1;
}

.collapse {
    display: none;
}

.collapse.in {
    display: block;
}

tr.collapse.in {
    display: table-row;
}

tbody.collapse.in {
    display: table-row-group;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-property: height, visibility;
    transition-duration: 0.35s;
    transition-timing-function: ease;
}

.cl {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.cm,
.dropdown {
    position: relative;
}

.fn:focus {
    outline: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #26408A;
    border-radius: 0;
    background-clip: padding-box;
}

.dropdown-menu.eg {
    right: 0;
    left: auto;
}

.dropdown-menu .oa {
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.6;
    color: #777777;
    white-space: nowrap;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    text-decoration: none;
    color: #172b36;
    background-color: #f5f8fa;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    background-color: #3097d1;
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    color: #9da9b0;
}

.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    /*filter: progid:DXImageTransform.Mob.gradient(enabled=false);*/
    cursor: not-allowed;
}

.open > .dropdown-menu {
    display: block;
}

.open > a {
    outline: 0;
}

.oc {
    left: auto;
    right: 0;
}

.od {
    left: 0;
    right: auto;
}

.oe {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.6;
    color: #9da9b0;
    white-space: nowrap;
}

.dropdown-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990;
}

.eg > .dropdown-menu {
    right: 0;
    left: auto;
}

.cm .cl,
.of .dropdown .cl {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.cm .dropdown-menu,
.of .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
}

@media (min-width: 768px) {
    .og .dropdown-menu {
        left: auto;
        right: 0;
    }

    .og .od {
        left: 0;
        right: auto;
    }
}

.oh,
.oi {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.oh > .cg,
.oi > .cg {
    position: relative;
    float: left;
}

.oh > .cg:hover,
.oi > .cg:hover,
.oh > .cg:focus,
.oi > .cg:focus,
.oh > .cg:active,
.oi > .cg:active,
.oh > .cg.active,
.oi > .cg.active {
    z-index: 2;
}

.oh .cg + .cg,
.oh .cg + .oh,
.oh .oh + .cg,
.oh .oh + .oh {
    margin-left: -1px;
}

.oj {
    margin-left: -5px;
}

.oj .cg,
.oj .oh,
.oj .input-group {
    float: left;
}

.oj > .cg,
.oj > .oh,
.oj > .input-group {
    margin-left: 5px;
}

.oh > .cg:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
}

.oh > .cg:first-child {
    margin-left: 0;
}

.oh > .cg:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.oh > .cg:last-child:not(:first-child),
.oh > .fn:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.oh > .oh {
    float: left;
}

.oh > .oh:not(:first-child):not(:last-child) > .cg {
    border-radius: 0;
}

.oh > .oh:first-child:not(:last-child) > .cg:last-child,
.oh > .oh:first-child:not(:last-child) > .fn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.oh > .oh:last-child:not(:first-child) > .cg:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.oh .fn:active,
.oh.open .fn {
    outline: 0;
}

.oh > .cg + .fn {
    padding-left: 8px;
    padding-right: 8px;
}

.oh > .fv + .fn {
    padding-left: 12px;
    padding-right: 12px;
}

.oh.open .fn {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.oh.open .fn.fu {
    box-shadow: none;
}

.cg .cl {
    margin-left: 0;
}

.fv .cl {
    border-width: 5px 5px 0;
    border-bottom-width: 0;
}

.cm .fv .cl {
    border-width: 0 5px 5px;
}

.oi > .cg,
.oi > .oh,
.oi > .oh > .cg {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
}

.oi > .oh > .cg {
    float: none;
}

.oi > .cg + .cg,
.oi > .cg + .oh,
.oi > .oh + .cg,
.oi > .oh + .oh {
    margin-top: -1px;
    margin-left: 0;
}

.oi > .cg:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.oi > .cg:first-child:not(:last-child) {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.oi > .cg:last-child:not(:first-child) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.oi > .oh:not(:first-child):not(:last-child) > .cg {
    border-radius: 0;
}

.oi > .oh:first-child:not(:last-child) > .cg:last-child,
.oi > .oh:first-child:not(:last-child) > .fn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.oi > .oh:last-child:not(:first-child) > .cg:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.ok {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
}

.ok > .cg,
.ok > .oh {
    float: none;
    display: table-cell;
    width: 1%;
}

.ok > .oh .cg {
    width: 100%;
}

.ok > .oh .dropdown-menu {
    left: auto;
}

[data-toggle="buttons"] > .cg input[type="radio"],
[data-toggle="buttons"] > .oh > .cg input[type="radio"],
[data-toggle="buttons"] > .cg input[type="checkbox"],
[data-toggle="buttons"] > .oh > .cg input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.input-group[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0;
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.input-group .form-control:focus {
    z-index: 3;
}

.es > .form-control,
.es > .input-group-addon,
.es > .fj > .cg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

select.es > .form-control,
select.es > .input-group-addon,
select.es > .fj > .cg {
    height: 46px;
    line-height: 46px;
}

textarea.es > .form-control,
textarea.es > .input-group-addon,
textarea.es > .fj > .cg,
select[multiple].es > .form-control,
select[multiple].es > .input-group-addon,
select[multiple].es > .fj > .cg {
    height: auto;
}

.eq > .form-control,
.eq > .input-group-addon,
.eq > .fj > .cg {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

select.eq > .form-control,
select.eq > .input-group-addon,
select.eq > .fj > .cg {
    height: 30px;
    line-height: 30px;
}

textarea.eq > .form-control,
textarea.eq > .input-group-addon,
textarea.eq > .fj > .cg,
select[multiple].eq > .form-control,
select[multiple].eq > .input-group-addon,
select[multiple].eq > .fj > .cg {
    height: auto;
}

.input-group-addon,
.fj,
.input-group .form-control {
    display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child),
.fj:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.input-group-addon,
.fj {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #536570;
    text-align: center;
    background-color: #d4dbe0;
    border: 1px solid #d4dbe0;
    border-radius: 4px;
}

.input-group-addon.ep {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
}

.input-group-addon.er {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
    margin-top: 0;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.fj:first-child > .cg,
.fj:first-child > .oh > .cg,
.fj:first-child > .fn,
.fj:last-child > .cg:not(:last-child):not(.dropdown-toggle),
.fj:last-child > .oh:not(:last-child) > .cg {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.input-group-addon:first-child {
    border-right: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.fj:last-child > .cg,
.fj:last-child > .oh > .cg,
.fj:last-child > .fn,
.fj:first-child > .cg:not(:first-child),
.fj:first-child > .oh:not(:first-child) > .cg {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.input-group-addon:last-child {
    border-left: 0;
}

.fj {
    position: relative;
    font-size: 0;
    white-space: nowrap;
}

.fj > .cg {
    position: relative;
}

.fj > .cg + .cg {
    margin-left: -1px;
}

.fj > .cg:hover,
.fj > .cg:focus,
.fj > .cg:active {
    z-index: 2;
}

.fj:first-child > .cg,
.fj:first-child > .oh {
    margin-right: -1px;
}

.fj:last-child > .cg,
.fj:last-child > .oh {
    z-index: 2;
    margin-left: -1px;
}

.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}


.nav > li {
    position: relative;
    display: block;
}

.nav > li > a {
    position: relative;
    display: block;
}

.web-element .nav > li > a {
    position: relative;
    display: block;
    padding: 5px 15px;
    background: var(--CrashKali-primary);
    opacity: 0.8;
}

.web-element .nav > li {
    position: relative;
    display: block;
    overflow: hidden;
    border-bottom: 1px solid #181e30;
    width: 100%;
}

.web-element .nav > li:last-child {
    border-bottom: 1px solid transparent;
}

.nav > li > a:hover, .nav > li > a:active, nav > li > a:focus, .aoi > li:nth-child(2n) a:hover {
    text-decoration: none;
    color: #fff !important;
}

.left-text {
    text-align: left !important;
}

.topl:hover,
.topl:focus,
.topl:active {
    color: #fff;
}

.white {
    color: #fff !important;
}

.sport > li {
    list-style-type: none;
    background: transparent !important;
    border-bottom: 1px solid #cdcdd1 !important;
}

ul.sport {
    background: #f8f7fb;
}

.sport > li:last-child {
    border-bottom: none !important;
}

.sport > li a {
    /* color: #dcdcdc; */
    /* font-size: 12px; */
    padding: 7px 0;
}

.basic-list li {

}

.pay-instruction li {
    border: none !important;
    font-weight: normal !important;
    color: #444 !important;
    font-size: 20px !important;
}

.pay-instruction .pay-highlight {
    font-size: 25px !important;
    font-weight: 900 !important;
}

.nav > li.disabled > a {
    color: #9da9b0;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
    color: #9da9b0;
    text-decoration: none;
    background-color: transparent;
    cursor: not-allowed;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: #26408A;
    border-color: #3097d1;
}

.nav .nav-divider {
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.nav > li > a > img {
    /*border: 2px solid #24367e;*/
    width: 25px;
    padding: 0;
    border-radius: 100px;
    margin: 0 0px;
}
.ol {
    border-bottom: 1px solid #dddddd;
}

.ol > li {
    float: left;
    margin-bottom: -1px;
}

.ol > li > a {
    margin-right: 2px;
    line-height: 1.6;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.ol > li > a:hover {
    border-color: #d4dbe0 #d4dbe0 #dddddd;
}

.ol > li.active > a,
.ol > li.active > a:hover,
.ol > li.active > a:focus {
    color: #536570;
    background-color: #f5f8fa;
    border: 1px solid #dddddd;
    border-bottom-color: transparent;
    cursor: default;
}

.ol.om {
    width: 100%;
    border-bottom: 0;
}

.ol.om > li {
    float: none;
}

.ol.om > li > a {
    text-align: center;
    margin-bottom: 5px;
}

.ol.om > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
}

@media (min-width: 768px) {
    .ol.om > li {
        display: table-cell;
        width: 1%;
    }

    .ol.om > li > a {
        margin-bottom: 0;
    }
}

.no-pad-left {
    /*padding-left: ;*/
}

.md-screen-no-pad-left {
    padding-left: 0 !important;
}

.mobile-pad-right {
    padding-right: 15px;
}

.ol.om > li > a {
    margin-right: 0;
    border-radius: 4px;
}

.ol.om > .active > a,
.ol.om > .active > a:hover,
.ol.om > .active > a:focus {
    border: 1px solid #dddddd;
}

@media (min-width: 768px) {
    .ol.om > li > a {
        border-bottom: 1px solid #dddddd;
        border-radius: 4px 4px 0 0;
    }

    .ol.om > .active > a,
    .ol.om > .active > a:hover,
    .ol.om > .active > a:focus {
        border-bottom-color: #f5f8fa;
    }
}

.on > li {
    float: left;
}

.on > li > a {
    border-radius: 4px;
}

.on > li + li {
    margin-left: 2px;
}

.on > li.active > a,
.on > li.active > a:hover,
.on > li.active > a:focus {
    color: #ffffff;
    background-color: #3097d1;
}

.nav-stacked > li {
    float: none;
}

.nav-stacked > li + li {
    margin-top: 0;
    margin-left: 0;
}

.om {
    width: 100%;
}

.om > li {
    float: none;
}

.om > li > a {
    text-align: center;
    margin-bottom: 5px;
}

.om > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
}

@media (min-width: 768px) {
    .om > li {
        display: table-cell;
        width: 1%;
    }

    .om > li > a {
        margin-bottom: 0;
    }
}

.oo {
    border-bottom: 0;
}

.oo > li > a {
    margin-right: 0;
    border-radius: 4px;
}

.oo > .active > a,
.oo > .active > a:hover,
.oo > .active > a:focus {
    border: 1px solid #dddddd;
}

@media (min-width: 768px) {
    .oo > li > a {
        border-bottom: 1px solid #dddddd;
        border-radius: 4px 4px 0 0;
    }

    .oo > .active > a,
    .oo > .active > a:hover,
    .oo > .active > a:focus {
        border-bottom-color: #f5f8fa;
    }
}

.op > .oq {
    display: none;
}

.op > .active {
    display: block;
}

.ol .dropdown-menu {
    margin-top: -1px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.ck {
    position: relative;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .ck {
        border-radius: 4px;
    }
}

@media (min-width: 768px) {
    .or {
        float: left;
    }
}

.f {
    overflow-x: visible;
    padding-right: 15px;
    padding-left: 15px;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    -webkit-overflow-scrolling: touch;
}

.f.in {
    overflow-y: auto;
}

.os .f,
.of .f {
    max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
    .os .f,
    .of .f {
        max-height: 200px;
    }
}

.by > .or,
.gb > .or,
.by > .f,
.gb > .f {
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 768px) {
    .by > .or,
    .gb > .or,
    .by > .f,
    .gb > .f {
        margin-right: 0;
        margin-left: 0;
    }
}

.ot {
    z-index: 1000;
    border-width: 0 0 1px;
}

@media (min-width: 768px) {
    .ot {
        border-radius: 0;
    }
}

.os,
.of {
    position:sticky;
    right: 0;
    left: 0;
    z-index: 1030;
}

.nav-options-finance {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
}

.nav-option-content {
    justify-content: flex-end;
    align-items: end;
    align-items: baseline;
}

.os {
    border-width: 0 0 1px;
}

.of {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0;
}

.e {
    float: left;
    padding: 3px 1px;
    font-size: 18px;
    line-height: 22px;
}

.e:hover,
.e:focus {
    text-decoration: none;
}

.e > img {
    display: block;
}

.ou {
    position: relative;
    float: right;
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.ou:focus {
    outline: 0;
}

.ou .ov {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.ou .ov + .ov {
    margin-top: 4px;
}

.navbar-nav {
    margin: 7px -15px;
}

.navbar-nav > li > a {
    line-height: 22px;
}

.navbar-nav .ow {
    padding: 10px 0px !important;
}

.navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.of .navbar-nav > li > .dropdown-menu {
    margin-bottom: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.ox {
}

.ox.fx {
    margin-top: 10px;
    margin-bottom: 10px;
}

.ox.fz {
    margin-top: 14px;
    margin-bottom: 14px;
}

.oy {
    margin-top: 14px;
    margin-bottom: 14px;
}

.pad-r {
    padding-right: 20px;
}

.fit-ipad {
    /*width: -webkit-fill-available;*/
    margin-bottom: 0px;
    margin-left: -5px;
    overflow-x: hidden;
}
.slip-size{
    max-height:42vh;
}
@media (max-width: 1258px) {
    /*fonts in login screen*/
    .font-input{
        font-size:16px;
    }
    .btn-font{
        font-size:2rem!important;
    }
    .sport-check:hover{
        border_bottom:thin solid #ffc107!important;
    }
    /*.button-radius{*/
    /*    border-radius: 1rem!important;*/
    /*}*/
    /*.c-btn-group > .c-btn {*/
    /*width: 35px!importnat;*/
    /*}*/

    /*.c-btn-header {*/
    /*    min-width: 35px !important;*/
    /*}*/

    .match-detail-container {
        width: 19% !important;
    }

    .amt {
        margin-top: 113px !important;
    }


    .market-odds {
        flex-basis: auto;
    }

    /* .betslip-ipad{
      display:none;
    } */
    .amt {
        margin-top: 100px;
    }
}

@media (max-width: 574px) {
    .ss.profile{
        width: 100%;
    }
    .mobile-change {
        /*display: grid !important;*/
        /*grid-template-columns: [col-1]100px[col2]50px[col-end];*/
    }

    .app-header-nav {
        margin-top: -4px !important;
    }
    .app-header-nav-login {
        margin-top: -4px !important;
    }
}

@media (max-width: 640px), (max-width: 574px) {
    /*.image-size {*/
    /*    width: 100px !important;*/
    /*}*/

    .font-btn {
        font-size: 10px;
    }

    .navbar-responsive {
        width: 200px;
    }

    .match button {
        width: 100% !important;
        max-width: 100% !important;
    }

    .mobile-disable {
        /* display: none; */
    }
}

.fm{
    color: #fff;
}

@media (max-width: 767px) {
    ::-webkit-scrollbar {
        width: 1px!important;
    
    }
    .pro-sidebar {
        width: var(--width-side) !important;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--CrashKali-primary);
    }
    
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background:#16202c;
    }
    .to-tabview{
        display: none;
    }
    .casino-category-container {

        width:fit-content;

    }

    .fm{
        color: #fff;
    }
    /*.scroll {*/
    /*    height:100vh;*/
    /*    margin-bottom: 200px;*/
    /*    overflow-y: scroll;*/
    /*}*/
  

    .mobile-top{
        margin-top:12rem;
    }

    .virtual-size{
        display: flex;
        flex-wrap:wrap;
    }
    /*.betslip form{*/
    /*    margin-top:5px!important;*/
    /*    position:absolute;*/
    /*    width:100%;*/
    /*    background:#1f2f38;*/
    /*}*/
    .slip-size{
        max-height:20vh;
    }
    .betslip td.tax-info {
        padding: 10px 4px!important;
    }
    .betslip td{
        padding: 2px 2px!important;
        white-space: nowrap;
    }
    .mobile-height{
        max-height:30vh!important;
    }
    .width-full{
        width:100%;
    }
    .mobile-menu{
        z-index:9999;
    }

    .betslip-container-mobile{
        height:80%!important;
    }
    .live-col{
        flex-direction:column!important;
    }
    .virtual-width{
        width:50%;
    }
    .virtual-width-mobile{
        width:100%!important;
    }
    .web-element{
        margin-bottom:6rem;
    }
    .remove-top{
        top:0!important;
    }
    .amt {
        margin-top: 0px !important;
    }

    .rescale {
        margin-left: -5px;
    }

    .button-toggle {
        display: flex;
        align-items: center;
    }

    /* .change-size {
      display: grid;
      grid-template-columns: [col1] 1fr [col2] 50px [col-end];
    } */
    .w-change1 {
        width: 20%;
    }

    .w-change2 {
        width: 28%;
    }

    .pad-l {
        padding-right: 15px;
    }

    .w-change5 {
        width: 20%;
    }

    .w-change4 {
        width: 70%;
    }

    .navbar-responsive {
        width: 272px;
    }

    .si {
        display: inline-block !important;
    }

    .gz {
        padding-top: 10px;
    }

    .mobi {
        display: block !important;
    }

    .navbar-disable {
        /* display:none; */
        /* display: flex; */
        /*flex-direction: row;*/
        /*justify-content: flex-start;*/
        /*grid-column: 1/12;*/
    }

    .container {
        padding: 0 !important;
    }
    .top-matches div {
        min-height: 24px;
        justify-content: end;
        /*background: #16202C;*/
    }
    /*}*/

    .home-tabs .main-header,
    .home-tabs-active .main-header {
        font-size: 12px;
    }

    .slip-counter {
        background: #bc1b56;
        text-align: center;
        color: var(--dark);
        font-size: 20px;
        padding: 0 5px;
        display: inline-block;
        margin: -5px -1px -5px;
        border-radius: 100px;
        width: 30px;
    }

    #slip-holder {
        position: fixed;
        z-index: 9999999;
        top: 15%;
        right: 10px;
        width: 98%;
        display: none;
        margin: auto;
    }

    .match button {
        max-width: 50%;
        width: 49%;
        display: inline-block;
    }

    .matches {
        display: block;
        overflow: hidden;
    }

    .odds .label {
    }

    .app-navbar .e img {
        max-width: 100px !important;
    }

    .e.logo {
        padding: 0px 0px !important;
    }
    .second-nav {
        top: 90px;
        background: #16202c !important;
    }

    .e {
        padding: 14px 5px !important;
    }



    .shrink-header .icon-holder img {
        display: none;
    }

    .pick {
        cursor: copy;
        cursor: pointer;
        border: 1px solid #26408a;
        margin: 0;
        min-width: 90px;
    }

    .panel-header h2,
    h2 {
        font-size: 16px;
        padding-top: 0px !important;
        margin-bottom: 0px !important;
        padding: 0;
        font-weight: 400;
    }

    .panel-header {
        padding: 0px !important;
    }

    .shrink-header .e {
        padding: 5px 5px !important;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .gd,
    .ge,
    .gf,
    .gg,
    .gh,
    .gi,
    .gj,
    .gk,
    .gl,
    .gm,
    .gn,
    .go,
    .gp,
    .gq,
    .gr,
    .gs,
    .gt,
    .gu,
    .gv,
    .gw,
    .gx,
    .gy,
    .gz,
    .ha,
    .hb,
    .hc,
    .hd,
    .he,
    .hf,
    .hg,
    .hh,
    .hi,
    .hj,
    .hk,
    .hl,
    .hm,
    .hn,
    .ho,
    .hp,
    .hq,
    .hr,
    .hs,
    .ht,
    .hu,
    .hv,
    .hw,
    .hx,
    .hy {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .match-time-real-counter {
        font-size: 10px !important;
        padding: 2px !important;
        line-height: 13px;
        z-index: 100;
    }

    .live-placeholder-game {
        font-size: 15px !important;
    }

    .live-header b {
        font-size: 10px !important;
    }

    /* .shrink-header .second-nav {
      top: 72px !important;
    } */
    li a.live-game.active,
    .navbar-nav li a.live-game.active,
    li a.live-game {
        color: #fff;
        background-color: red;
        padding: 5px;
        margin: 0;
        padding-bottom: 4px !important;
    }
}
.color-inherit{
    background-color: inherit!important;
    font-size:12px;
}
.change-display {
    display: flex !important;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.change-display::-webkit-scrollbar {
    display: none;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout::-webkit-scrollbar {
    display: none;
}

@media (min-width: 979px) {


    .fix-print {
        padding: 8px 3px 3px 2px !important;
        display: contents !important;
        justify-content: center;
        align-items: center;
        font-size: 10px;
    }

    #slip-holder.affix-top {
        position: static;
        margin-top: 0px;
    }


    /*for review  */
    #slip-holder.affix {
        position: fixed;
        top: 110px;
        z-index: 999;
        width: 230px;
        -webkit-transition: opacity 0.5s ease;
        -moz-transition: opacity 0.5s ease;
        -o-transition: opacity 0.5s ease;
        transition: opacity 0.5s ease;
    }

    /*#slip-holder  .hide-on-affix {*/
    /*visibility: visible;*/
    /*opacity: 1;*/
    /*}*/
    /*#slip-holder.affix .hide-on-affix {*/
    /*visibility: hidden;*/
    /*opacity: 0;*/
    /*transition: visibility 0s, opacity 0.5s linear;*/
    /*}*/
}

@media (hover: hover) {
    /*.top-matches button:hover {*/
    /*    background: #ffc428 !important;*/
    /*}*/
}

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.bu */
@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    .amt {
        scroll-margin-top: 140px;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    .ck {
        display: none;
    }

    .cg > .cl,
    .cm > .cg > .cl {
        border-top-color: #000 !important;
    }

    .cb {
        border: 1px solid #000;
    }

    .cn {
        border-collapse: collapse !important;
    }

    .cn td,
    .cn th {
        background-color: #fff !important;
    }

    .co th,
    .co td {
        border: 1px solid #ddd !important;
    }
}

@media (min-width: 768px) {


    .disp-sidebar {
        display: flex;
        z-index: 10;
        margin-right: 5px;
    }

    .df {
        font-size: 21px;
    }

    .fix-display {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin: 0px;
        padding: 10px 0 !important;
    }
}

@media (min-width: 768px) {
    .ed dt {
        float: left;
        width: 160px;
        clear: left;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ed dd {
        margin-left: 180px;
    }
}

@media screen and (max-width: 767px) {
    .hide2 {
        display: none;
    }

    .space-button {
        width: 7%;
    }

    .space-deposit {
        width: 15%;
    }

    .eo {
        width: 100%;
        margin-bottom: 16.5px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #dddddd;
    }

    .eo > .cn {
        margin-bottom: 0;
    }

    .eo > .cn > thead > tr > th,
    .eo > .cn > tbody > tr > th,
    .eo > .cn > tfoot > tr > th,
    .eo > .cn > thead > tr > td,
    .eo > .cn > tbody > tr > td,
    .eo > .cn > tfoot > tr > td {
        white-space: nowrap;
    }

    .eo > .co {
        border: 0;
    }

    .eo > .co > thead > tr > th:first-child,
    .eo > .co > tbody > tr > th:first-child,
    .eo > .co > tfoot > tr > th:first-child,
    .eo > .co > thead > tr > td:first-child,
    .eo > .co > tbody > tr > td:first-child,
    .eo > .co > tfoot > tr > td:first-child {
        border-left: 0;
    }

    .eo > .co > thead > tr > th:last-child,
    .eo > .co > tbody > tr > th:last-child,
    .eo > .co > tfoot > tr > th:last-child,
    .eo > .co > thead > tr > td:last-child,
    .eo > .co > tbody > tr > td:last-child,
    .eo > .co > tfoot > tr > td:last-child {
        border-right: 0;
    }

    .eo > .co > tbody > tr:last-child > th,
    .eo > .co > tfoot > tr:last-child > th,
    .eo > .co > tbody > tr:last-child > td,
    .eo > .co > tfoot > tr:last-child > td {
        border-bottom: 0;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"].form-control,
    input[type="time"].form-control,
    input[type="datetime-local"].form-control,
    input[type="month"].form-control {
        line-height: 36px;
    }

    input[type="date"].ep,
    input[type="time"].ep,
    input[type="datetime-local"].ep,
    input[type="month"].ep,
    .eq input[type="date"],
    .eq input[type="time"],
    .eq input[type="datetime-local"],
    .eq input[type="month"] {
        line-height: 30px;
    }

    input[type="date"].er,
    input[type="time"].er,
    input[type="datetime-local"].er,
    input[type="month"].er,
    .es input[type="date"],
    .es input[type="time"],
    .es input[type="datetime-local"],
    .es input[type="month"] {
        line-height: 46px;
    }
}

@media (min-width: 768px) {
    .fi .et {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .fi .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .fi .ey {
        display: inline-block;
    }

    .fi .input-group {
        display: inline-table;
        vertical-align: middle;
    }

    .fi .input-group .input-group-addon,
    .fi .input-group .fj,
    .fi .input-group .form-control {
        width: auto;
    }

    .fi .input-group > .form-control {
        width: 100%;
    }

    .fi .ff {
        margin-bottom: 0;
        vertical-align: middle;
    }

    .fi .eu,
    .fi .ev {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .fi .eu label,
    .fi .ev label {
        padding-left: 0;
    }

    .fi .eu input[type="radio"],
    .fi .ev input[type="checkbox"] {
        position: relative;
        margin-left: 0;
    }

    .fi .fb .fc {
        top: 0;
    }
}

@media (min-width: 768px) {
    .fk .ff {
        text-align: right;
        margin-bottom: 0;
        padding-top: 7px;
    }
}

@media (min-width: 768px) {
    .fk .fa .ff {
        padding-top: 11px;
        font-size: 18px;
    }
}

.space-between {
    gap: 10%
}

.fit-ipad {
    /*margin-left: -10%;*/
}

.styling-matches{

}

@media (min-width: 768px) {
    .fk .ez .ff {
        padding-top: 6px;
        font-size: 12px;
    }
}

@media (min-width: 768px) {
    .by {
        width: 100%;
    }

    .container {
        width: 100%;
    }
}

@media (max-width: 916px) and (min-width: 768px) {
    span .space-icons {
        padding-right: 0px;
    }

    .change-2 {
        /*display:grid;*/
        /*grid-template-columns: minmax(0,0.5fr) minmax(0,0.5fr) minmax(0,1fr);*/
        flex-basis: content;
    }


}
.change-display{
    flex-wrap: nowrap;
    overflow-x: auto;
}
.change-display::-webkit-scrollbar {
    display: none;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout::-webkit-scrollbar{
    display: none;
}
.spacing{
    width:max-content;
    width: -moz-max-content;
}

@media (max-width: 864px) and (min-width: 768px) {


    .app-header-nav {
        margin-top: 105px !important;
    }
    .app-header-nav-login {
        margin-top: 105px !important;
    }
}
@media (min-width: 992px){
    .betslip td.tax-info {
        padding: 7px 1px!important;
        font-size: 10px;
        font-weight: 600;
    }
    .betslip td{
        padding: 2px 2px!important;
        white-space: nowrap;
    }
    .tablet-only{
        display: none!important;
    }
    .tablet-view{
        display:flex;
    }
    /*todo display buttons login register verify*/
    .mobile-profile1{
        display:flex;
    }
}
@media (min-width:768px) and (max-width: 991px){
    .amt {
        margin-top: 113px !important;
    }
}

.card-small {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: none;
    border-radius: 10px;
    /*transform: translate(1px, 2px);*/
}
.wrapping{
    white-space: nowrap;
    display: flex;
    align-items: center;

}
@media (max-width: 991px) {
    .opacity-reduce-txt.vs-styling {
        font-size: var(--font-size-small-variation1);
    }

    .menu-wrapper::-webkit-scrollbar {
        display: none;
    }
    .menu-t .menu-img {
        width: 100% !important;
        height: 2.8rem !important;
        text-align: center;
        padding: 5px 0px;
        position: relative;
    }
    .menu-wrapper {
        /* max-width: 100vw; */
        width: 100vw;
        /* max-width: 991px; */
        display: inline-block;
        overflow-y: hidden;
        overflow-x: auto;
        background:var(--CrashKali-header-bg);
        /* background: orange!important; */
        -webkit-overflow-scrolling: touch;
        height: 55px;
        padding-left: 4px;
    }
    .betslip td.tax-info {
        padding: 10px 4px!important;
    }
    .betslip td{
        padding: 2px 2px!important;
        white-space: nowrap;
    }
    .casino-category-container {

        width:fit-content;

    }
    .mobile-nav-top {
        width: 100%;
        /* max-width: 767px; */
        white-space: nowrap;
        overflow-x: hidden;
        overflow-y: hidden;
        background: var(--CrashKali-header-bg);
        max-height: 59px;
    }

    .size-1 {
        width: 12%;
    }
    .top-matches .side{
        background: transparent;
    }
    .logo{
        padding-top:0px!important
    }
    /*.tablet-view{*/
    /*    display: none;*/
    /*}*/
    .mobile-profile1{
        display:flex;
        width:auto;
    }
    .gn{
        padding:0px!important;
    }
    .mobile-to-desktop-options{
        display: flex!important;
    }
    .app-header-nav {
        padding-left: 4px;
        margin-top: -25px !important;
    }.app-header-nav-login {
        padding-left: 4px;
        margin-top: -25px !important;
    }


    .image-size{
        width:100% ;
    }
    .top-login-section {
        display: none;

    }
    .to-navcheck{
        display: none;
    }
    .desktop-ipad-size{
        display: block!important;
    }
    .width-button-odd{
        width:100% !important
    }
    .slip-top{
        margin-top:6rem;
    }

    .bloc-icon .active{
        border-bottom: 1px solid yellow;
    }
    .slip-counter {
        background: #bc1b56;
        text-align: center;
        color: var(--dark);
        font-size: 20px;
        padding: 0 5px;
        display: inline-block;
        margin: -5px -1px -5px;
        border-radius: 100px;
        width: 30px;
    }
    .nav__betslip {
        margin: -7px auto;
        height: 55px;
        border-radius: 50%;
        width: 55px;
        font-weight: 800;
        font-size: 14px;
        border: none;
    }
    .tablet-only{
        display: flex!important;
    }
    .matches .top-matches{
        /*border-bottom: 1px solid #334c5c!important;*/
    }
    .border-info{
        border: 1px solid black;
    }
    .container-size{
        width:95%!important;
    }
    .card-sm {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        border: 10px solid rgba(0, 0, 0, .125);
        border-radius: 5rem;
        margin: 1.5rem;
        display: flex;
        align-items: center;
        justify-content:center;
        /*transform: translate(1px, 2px);*/
    }


    .bet-fix {
        flex-direction: row !important;
        /*margin-left:auto!important;*/
    }

    .space-bets {
        flex-direction: column !important;
        gap: 10px !important;

    }

    .size-info {
        width: 39% !important;
    }

    .c-btn-group {
        width: 100%;
        margin-left: 0 !important;
    }

    .c-btn-group > .c-btn {
        width: 100% ;
        /*height: 20px !important;*/
    }


    .c-btn-header {
        height: 24px !important;

    }

 }
@media (max-width: 1200px){
    .print-width{
        width:130px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {

    /*.change-display {*/
    /*    flex-wrap: nowrap;*/
    /*    font-size: 11px;*/
    /*    display: grid !important;*/
    /*    !*code needed to commented out after commenting out casino option*!*/
    /*    !*grid-template-columns: [col0]0px[col1]60px[col2]70px[col3]100px[col4]80px[col5]120px[col6]120px[col7]140px[col8]120px[col9]90px[col10]70px[col11]108px[col-end];*!*/
    /*    grid-template-columns: [col0]0px[col1]60px[col2]70px[col3]100px[col4]200px[col5]80px[col7]110px[col8]130px[col9]120px[col10]100px[col11]105px[col12]0[col-end];*/
    /*    overflow: auto;*/
    /*}*/
 }
@media (min-width: 992px) and (max-width: 1160px) {
    .small{
        width:auto!important;
    }

    .top-matches .side {
        width: 35px;
        height: 24px;
    }
    /*.bet-fix {*/
    /*    flex-direction: row !important;*/
    /*    !*margin-left:auto!important;*!*/
    /*}*/

    .btn-spacing {
        word-spacing: 1px;
        font-size: 11px;
        font-weight: 700;
    }

    .by {
        width: 100%;
    }


}

@media (min-width: 1160px) {
    .fix-print {
        font-size: 12px;
    }

    .font-btn {
        font-weight: 200;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        letter-spacing: 1px;
        align-items: center;
        text-tranform: capitalize;
    }

    .btn-spacing {
        font-size: 15px;
    }

    .app-header-nav {
        /* margin-top: 48px; */
        margin-top: 49px;
    }
    .app-header-nav-login {
        margin-top: 48px;
    }

    .shrink-header .second-nav {
        top: 68px;
    }

    .amt {
        /*margin-top: 130px !important;*/
        scroll-margin-top: 140px;
    }
}

@media (min-width: 1400px) {
    .team_category_game {
        display: flex;
        align-items: center;
    }
    .compt-teams .compt-teams-item{
        overflow-x: hidden;
        width: 200px!important;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .team_category_game small {
        overflow-x: hidden;
        width: 200px!important;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .separations{
        gap:14%!important;
    }
    .space-between {
        width: 45% !important;
        /*gap: 18%;*/
    }
}

@media (min-width: 1800px) {
    .space-between {
        width: 45% !important;
        gap: 26%;
    }
}

@media (min-width: 1500px) {
    .gap-size{
        gap:40%;
    }

    .c-btn-header {
        min-width: 52px !important;
    }
}


@media (min-width: 768px) {
    .ge,
    .gi,
    .gm,
    .gq,
    .gu,
    .gy,
    .hc,
    .hg,
    .hk,
    .ho,
    .hs,
    .hw {
        float: left;
    }

    .hw {
        width: 100%;
    }

    .hs {
        width: 91.66666667%;
    }

    .ho {
        width: 83.33333333%;
    }

    .hk {
        width: 75%;
    }

    .hg {
        width: 66.66666667%;
    }

    .hc {
        width: 58.33333333%;
    }

    .gy {
        width: 50%;
    }

    .gu {
        width: 41.66666667%;
    }

    .gq {
        width: 33.33333333%;
    }

    .gm {
        width: 25%;
    }

    .gi {
        width: 16.66666667%;
    }

    .ge {
        width: 8.33333333%;
    }

    .jn {
        right: 100%;
    }

    .jo {
        right: 91.66666667%;
    }

    .jp {
        right: 83.33333333%;
    }

    .jq {
        right: 75%;
    }

    .jr {
        right: 66.66666667%;
    }

    .js {
        right: 58.33333333%;
    }

    .jt {
        right: 50%;
    }

    .ju {
        right: 41.66666667%;
    }

    .jv {
        right: 33.33333333%;
    }

    .jw {
        right: 25%;
    }

    .jx {
        right: 16.66666667%;
    }

    .jy {
        right: 8.33333333%;
    }

    .jz {
        right: auto;
    }

    .ka {
        left: 100%;
    }

    .kb {
        left: 91.66666667%;
    }

    .kc {
        left: 83.33333333%;
    }

    .kd {
        left: 75%;
    }

    .ke {
        left: 66.66666667%;
    }

    .kf {
        left: 58.33333333%;
    }

    .kg {
        left: 50%;
    }

    .kh {
        left: 41.66666667%;
    }

    .ki {
        left: 33.33333333%;
    }

    .kj {
        left: 25%;
    }

    .kk {
        left: 16.66666667%;
    }

    .kl {
        left: 8.33333333%;
    }

    .km {
        left: auto;
    }

    .kn {
        margin-left: 100%;
    }

    .ko {
        margin-left: 91.66666667%;
    }

    .kp {
        margin-left: 83.33333333%;
    }

    .kq {
        margin-left: 75%;
    }

    .kr {
        margin-left: 66.66666667%;
    }

    .ks {
        margin-left: 58.33333333%;
    }

    .kt {
        margin-left: 50%;
    }

    .ku {
        margin-left: 41.66666667%;
    }

    .kv {
        margin-left: 33.33333333%;
    }

    .kw {
        margin-left: 25%;
    }

    .kx {
        margin-left: 16.66666667%;
    }

    .ky {
        margin-left: 8.33333333%;
    }

    .kz {
        margin-left: 0%;
    }
}


@media (min-width: 992px) {
    .gf,
    .gj,
    .gn,
    .gr,
    .gv,
    .gz,
    .hd,
    .hh,
    .hl,
    .hp,
    .ht,
    .hx {
        float: left;
    }

    .hx {
        width: 100%;
    }

    .ht {
        width: 91.66666667%;
    }

    .hp {
        width: 83.33333333%;
    }

    .hl {
        width: 75%;
    }

    .hh {
        width: 66.66666667%;
    }

    .hd {
        width: 58.33333333%;
    }

    .gz {
        width: 62%;
    }

    .gv {
        width: 41.66666667%;
    }

    .gr {
        width: 33.33333333%;
    }

    .gn {
        width: 23%;
        padding: 0px 2px;
    }

    .gj {
        width: 16.66666667%;
    }

    .gf {
        width: 8.33333333%;
    }

    .la {
        right: 100%;
    }

    .lb {
        right: 91.66666667%;
    }

    .lc {
        right: 83.33333333%;
    }

    .ld {
        right: 75%;
    }

    .le {
        right: 66.66666667%;
    }

    .lf {
        right: 58.33333333%;
    }

    .lg {
        right: 50%;
    }

    .lh {
        right: 41.66666667%;
    }

    .li {
        right: 33.33333333%;
    }

    .lj {
        right: 25%;
    }

    .lk {
        right: 16.66666667%;
    }

    .ll {
        right: 8.33333333%;
    }

    .lm {
        right: auto;
    }

    .ln {
        left: 100%;
    }

    .lo {
        left: 91.66666667%;
    }

    .lp {
        left: 83.33333333%;
    }

    .lq {
        left: 75%;
    }

    .lr {
        left: 66.66666667%;
    }

    .ls {
        left: 58.33333333%;
    }

    .lt {
        left: 50%;
    }

    .lu {
        left: 41.66666667%;
    }

    .lv {
        left: 33.33333333%;
    }

    .lw {
        left: 25%;
    }

    .lx {
        left: 16.66666667%;
    }

    .ly {
        left: 8.33333333%;
    }

    .lz {
        left: auto;
    }

    .ma {
        margin-left: 100%;
    }

    .mb {
        margin-left: 91.66666667%;
    }

    .mc {
        margin-left: 83.33333333%;
    }

    .md {
        margin-left: 75%;
    }

    .me {
        margin-left: 66.66666667%;
    }

    .mf {
        margin-left: 58.33333333%;
    }

    .mg {
        margin-left: 50%;
    }

    .mh {
        margin-left: 41.66666667%;
    }

    .mi {
        margin-left: 33.33333333%;
    }

    .mj {
        margin-left: 25%;
    }

    .mk {
        margin-left: 16.66666667%;
    }

    .ml {
        margin-left: 8.33333333%;
    }

    .mm {
        margin-left: 0%;
    }
}

@media (min-width: 1900px) {
    .gz {
        width: 66%
    }
}

@media (min-width: 2000px) {
    .gz {
        width: 74%
    }
}

@media (min-width: 1400px) {
    .gz {
        width: 62%
    }
}

@media (min-width: 1600px) {
    .gz {
        width: 64%
    }
}

@media (min-width: 1700px) {
    .gz {
        width: 66%
    }
}

@media (min-width: 1800px) {
    .gz {
        width: 70%
    }
}


@media (min-width: 768px) {
    .og .dropdown-menu {
        left: auto;
        right: 0;
    }
}

@media (min-width: 768px) {
    .ol.om > li {
        display: table-cell;
        width: 1%;
    }

    .ol.om > li > a {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .ol.om > li > a {
        border-bottom: 1px solid #dddddd;
        border-radius: 4px 4px 0 0;
    }

    .ol.om > .active > a,
    .ol.om > .active > a:hover,
    .ol.om > .active > a:focus {
        border-bottom-color: #f5f8fa;
    }
}

@media (min-width: 768px) {
    .om > li {
        display: table-cell;
        width: 1%;
    }

    .om > li > a {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .oo > li > a {
        border-bottom: 1px solid #dddddd;
        border-radius: 4px 4px 0 0;
    }

    .or {
        float: left;
    }

    .oo > .active > a,
    .oo > .active > a:hover,
    .oo > .active > a:focus {
        border-bottom-color: #f5f8fa;
    }

    .ck {
        border-radius: 4px;
    }

    .f {
        width: auto;
        border-top: 0;
        box-shadow: none;
    }

    .f.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }

    .f.in {
        overflow-y: visible;
    }

    .os .f,
    .ot .f,
    .of .f {
        padding-left: 0;
        padding-right: 0;
    }

    .by > .or,
    .gb > .or,
    .by > .f,
    .gb > .f {
        margin-right: 0;
        margin-left: 0;
    }

    .os,
    .of {
        border-radius: 0;
    }

    .ot {
        border-radius: 0;
    }

    .ck > .by .e,
    .ck > .gb .e {
        margin-left: -15px;
    }

    .ou {
        display: none;
    }
}

@media (max-width: 480px) {
}

@media (max-device-width: 480px) and (orientation: landscape) {
    .os .f,
    .of .f {
        max-height: 200px;
    }
}

/*@media (max-width: 768px) and (min-width: 767px) {*/
/*  .shrink-header .second-nav {*/
/*    top: 0px;*/
/*  }*/
/*}*/

@media (max-width: 768px) {
    .mobile-slip {
        position: absolute;
        width: 100%;
        margin-top: 35%;
    }

    table.bdd {
        font-size: 10px;
    }

    table.basic-table.bd {
        font-size: 10px;
    }

    .top-login-section {
        /* display: none; */
        padding-left: 20px !important;
    }

    /* .shrink-header .second-nav {
        top: 62px ;
      } */
    .space-top {
        margin-top: 27px;
    }

    /* .by.amt {
        margin-top: 30px !important;
      } */
    .scrollbar-container {
        height: auto;
    }



    #myCarousel {
        margin-bottom: 0px;
    }

    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }

    .panel-header {
        /* background: #ffd604; */
        /* color: #24367e; */
        padding: 10px;
        margin-bottom: 2px;
    }

    .shrink-header .top-nav {
        top: 0px;
    }

    .shrink-header .top-nav-login {
        top: 0px;
    }
    .shrink-header .profile a {
        display: none;
    }

    .homepage .header-holder {
        margin-top: 4px;
        background: #ffd604;
        color: #24367e;
    }

    .top-nav {
        top: 0px;
        z-index: 9999 !important;
    }
    .top-nav-login {
        top: 0px;
        z-index: 9999 !important;
    }
    .navbar-nav .open .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu .oe {
        padding: 5px 15px 5px 25px;
    }

    .navbar-nav .open .dropdown-menu > li > a {
        line-height: 22px;
    }

    .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-nav .open .dropdown-menu > li > a:focus {
        background-image: none;
    }
}

@media (min-width: 768px) {
    .navbar-nav {
        float: left;
        margin: 0;
    }

    .navbar-nav > li {
        float: left;
        line-height: 5px;
        display: flex;
        align-items: center;
    }

    .navbar-nav > li > a {
    }
}

@media (min-width: 768px) {
    .ow .et {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: top;
    }

    .ow .form-control {
        display: inline-block;
        width: 190px;
        vertical-align: middle;
    }

    .ow .ey {
        display: inline-block;
    }

    .ow .input-group {
        display: inline-table;
        vertical-align: middle;
    }

    .ow .input-group .input-group-addon,
    .ow .input-group .fj,
    .ow .input-group .form-control {
        width: auto;
    }

    .ow .input-group > .form-control {
        width: 100%;
    }

    .ow .ff {
        margin-bottom: 0;
        vertical-align: middle;
    }

    .ow .eu,
    .ow .ev {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .ow .eu label,
    .ow .ev label {
        padding-left: 0;
    }

    .ow .eu input[type="radio"],
    .ow .ev input[type="checkbox"] {
        position: relative;
        margin-left: 0;
    }

    .ow .fb .fc {
        top: 0;
    }
}

@media (max-width: 1014px) {

    .amt {
        margin-top: 110px;
    }

    .move-up {
        margin-top: -10px
    }
}

@media (max-width: 1278px ) {

    .c-btn-group > .c-btn {
        min-width: 40px ;
        transition: min-width 0.01s ease-in-out;
    }

    .space-bets {
        gap: 10px !important;
    }

    .match-detail-container {
        margin-right: 2% !important;
        width: 19.6% !important;
        transition: width 0.01s ease-in-out;
    }
}

@media (max-width: 1147px) {
    .c-btn-group > .c-btn {
        min-width: 4rem ;
        transition: min-width 0.01s ease-in-out;
    }
    .betslip-container {
        position: sticky !important;
        /*top: 120px !important;*/
        background: #253743 !important;
    }

    .space-bets {
        gap: 2px !important;
    }


    .hide1 {
        display: none;
    }

    .odd-fix {
        font-size: 13px;
    }

    .sign-up.btn {
        padding: 7px 7px;
        margin-right: 10px;
        font-size: 16px;

        width: 100px;
    }

    .nav-fix {
        margin-top: 150px;
    }

    .mobile-disable {
        padding-top: 16px;
    }

    .nav-helpers {
        justify-content: flex-start !important;
    }

}

@media (max-width: 767px) {

    .sticky-jackpot{
        top:0px;}
    .d-content {
        display: contents;
    }

    .pad-2 {
        padding: 0 2px;
    }

    .ow .et {
        margin-bottom: 5px;
    }

    .nav-helpers {
        display: none !important;
    }

    .ow .et:last-child {
        margin-bottom: 0;
    }

    .heads {
        font-size: 12px !important;
    }
}

@media (max-width:767px){

    .betslip-container{
        z-index:2000;
    }

    .size-1{
        width:15%;
    }

    .mobile-wrap{
        flex-wrap:nowrap;
        overflow-x: auto;
    }
    .league-container {
        max-width: 100vw;
        width: 100vw;
        display: inline-block;
        overflow-y: hidden;
        overflow-x: auto;
        height: 4rem;
        display: flex;
        align-items: center;
        overflow-x: auto;
        padding-left: 0px;
        background: #162024;
        padding-top: 6px;
        height: 4.2rem;
        margin-top: -7px;
    }
    .league-container::-webkit-scrollbar{
        display:none!important;
    }
    .top-nav::-webkit-scrollbar{
        display:none!important;
        padding-bottom:0px!important;
    }
    .top-nav-login::-webkit-scrollbar{
        display:none!important;
        padding-bottom:0px!important;
    }
    .jackpot-resize{
        height:100px!important;
    }
    .accordion-bg{
        background: #0e131b;
    }
    .pro-menu > ul{
        display:flex;
        overflow-x: auto;
        /*justify-content:space-between;*/
    }
    .mybets-font {
        font-size: 10px;
        font-weight: 600;
        font-feature-settings: "dlig";
        font-variant: discretionary-ligatures;
        word-break: break-word;
    }
    .live-items{
        width: auto;
        overflow: hidden
    }
    .live-items> div{
       padding: 0;
    }

    .sidebar-live > .pro-menu{
        border:1px solid;
        padding-top: 2px!important;
        padding-bottom: 2px !important;
    }
    .inner-live{
        padding-top: 5px!important;
        padding-bottom: 5px !important;
    }
    .pro-menu-item > div{
        width: 200px;
    }
    .live-items >div{
        width:140px!important;
    }

    .pro-menu-item.sidebar-mobile > div{
        width:100%!important;
    }
    .live-slide{
        float: left!important;
    }

}
@media (max-width: 514px) {

    .ss.profile{
        padding:0px;
    }

}


@media (min-width: 768px) {
    .ow {
        width: auto;
        border: 0;
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: none;
    }

    .oy {
        float: left;
        margin-left: 15px;
        margin-right: 15px;
    }

    .oz {
        float: left !important;
    }

    .second-nav .og {
        float: left !important;
    }


    .og ~ .og {
        margin-right: 0;
    }
}

.pa {
    background-color: #ffffff;
    border-color: #d3e0e9;
}

.pa .e {
    color: #777777;
}

.pa .e:hover,
.pa .e:focus {
    color: #5e5e5e;
    background-color: transparent;
}

.pa .oy {
    color: #777777;
}

.pa .navbar-nav > li > a {
    color: #777777;
}

.pa .navbar-nav > li > a:hover,
.pa .navbar-nav > li > a:focus {
    color: #333333;
    background-color: transparent;
}

.pa .navbar-nav > .active > a,
.pa .navbar-nav > .active > a:hover,
.pa .navbar-nav > .active > a:focus {
    color: #333333;
    background-color: transparent;
}

.pa .navbar-nav > .disabled > a,
.pa .navbar-nav > .disabled > a:hover,
.pa .navbar-nav > .disabled > a:focus {
    color: #cccccc;
    background-color: transparent;
}

.pa .ou {
    border-color: #dddddd;
}

.pa .ou:hover,
.pa .ou:focus {
    background-color: #dddddd;
}

.pa .ou .ov {
    background-color: #888888;
}

.pa .f,
.pa .ow {
    border-color: #d3e0e9;
}

.pa .navbar-nav > .open > a,
.pa .navbar-nav > .open > a:hover,
.pa .navbar-nav > .open > a:focus {
    background-color: transparent;
    color: #333333;
}

.pa .pb {
    color: #777777;
}

.pa .pb:hover {
    color: #333333;
}

.pa .fu {
    color: #777777;
}

.pa .fu:hover,
.pa .fu:focus {
    color: #333333;
}

.pa .fu[disabled]:hover,
fieldset[disabled] .pa .fu:hover,
.pa .fu[disabled]:focus,
fieldset[disabled] .pa .fu:focus {
    color: #cccccc;
}

.pc .e {
    color: rgba(255, 255, 255, 0.8);
}

.pc .e:hover,
.pc .e:focus {
    color: #ffffff;
    background-color: transparent;
}

.pc .oy {
    color: #ffffff;
}

.pc .navbar-nav > li > a:hover,
.pc .navbar-nav > li > a:focus {
    color: #ffffff;
    /*background-color:  var();*/
    border-radius: 5px;
}


.pc .navbar-nav > .active > a,
.pc .navbar-nav > .active > a:hover,
.pc .navbar-nav > .active > a:focus {
    color: #ffffff;
}

.carousel-control {
    width: 10%;
}

.carousel-control .fa-chevron-left,
.carousel-control .fa-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
}

.carousel-control .fa-chevron-left,
.carousel-control .icon-prev {
    margin-left: -15px;
}

.carousel-control .fa-chevron-right,
.carousel-control .icon-next {
    margin-left: -15px;
}

.pc .navbar-nav > .disabled > a,
.pc .navbar-nav > .disabled > a:hover,
.pc .navbar-nav > .disabled > a:focus {
    color: #444444;
    background-color: transparent;
}

.pc .ou {
    border-color: transparent;
    background: transparent;
    border-radius: 0;
    padding: 5px;
}

.pc .ou:hover,
.pc .ou:focus {
    background-color: transparent;
    opacity: 0.8;
}

.pc .ou .ov {
    background-color: #27357f;
}

.pc .navbar-nav > .open > a,
.pc .navbar-nav > .open > a:hover,
.pc .navbar-nav > .open > a:focus {
    background-color: transparent;
    color: #ffffff;
}

@media (max-width: 767px) {
    .pc .navbar-nav .open .dropdown-menu > .oe {
        border-color: #1c73a5;
    }

    .pc .navbar-nav .open .dropdown-menu .oa {
        background-color: #1c73a5;
    }

    .pc .navbar-nav .open .dropdown-menu > li > a {
        color: rgba(255, 255, 255, 0.8);
    }

    .pc .navbar-nav .open .dropdown-menu > li > a:hover,
    .pc .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #ffffff;
        background-color: transparent;
    }

    .pc .navbar-nav .open .dropdown-menu > .active > a,
    .pc .navbar-nav .open .dropdown-menu > .active > a:hover,
    .pc .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #ffffff;
        background-color: transparent;
    }

    .pc .navbar-nav .open .dropdown-menu > .disabled > a,
    .pc .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .pc .navbar-nav .open .dropdown-menu > .disabled > a:focus {
        color: #444444;
        background-color: transparent;
    }

    .pa .navbar-nav .open .dropdown-menu > li > a {
        color: #777777;
    }

    .pa .navbar-nav .open .dropdown-menu > li > a:hover,
    .pa .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #333333;
        background-color: transparent;
    }

    .pa .navbar-nav .open .dropdown-menu > .active > a,
    .pa .navbar-nav .open .dropdown-menu > .active > a:hover,
    .pa .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #333333;
        background-color: transparent;
    }

    .pa .navbar-nav .open .dropdown-menu > .disabled > a,
    .pa .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .pa .navbar-nav .open .dropdown-menu > .disabled > a:focus {
        color: #cccccc;
        background-color: transparent;
    }
}

.pc .pb {
    color: rgba(255, 255, 255, 0.8);
}

.pc .pb:hover {
    color: #ffffff;
}

.pc .fu {
    color: rgba(255, 255, 255, 0.8);
}

.pc .fu:hover,
.pc .fu:focus {
    color: #ffffff;
}

.pc .fu[disabled]:hover,
fieldset[disabled] .pc .fu:hover,
.pc .fu[disabled]:focus,
fieldset[disabled] .pc .fu:focus {
    color: #444444;
}

.pd {
    padding: 8px 15px;
    margin-bottom: 22px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.pd > li {
    display: inline-block;
}

.pd > li + li:before {
    content: "/\00a0";
    padding: 0 5px;
    color: #cccccc;
}

.pd > .active {
    color: #9da9b0;
}

.pe {
    display: inline-block;
    padding-left: 0;
    margin: 22px 0;
    border-radius: 4px;
}

.pe > li {
    display: inline;
}

.pe > li > a,
.pe > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.6;
    text-decoration: none;
    color: #3097d1;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    margin-left: -1px;
}

.pe > li:first-child > a,
.pe > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.pe > li:last-child > a,
.pe > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.pe > li > a:hover,
.pe > li > span:hover,
.pe > li > a:focus,
.pe > li > span:focus {
    z-index: 2;
    color: #216a94;
    background-color: #d4dbe0;
    border-color: #dddddd;
}

.pe > .active > a,
.pe > .active > span,
.pe > .active > a:hover,
.pe > .active > span:hover,
.pe > .active > a:focus,
.pe > .active > span:focus {
    z-index: 3;
    color: #ffffff;
    background-color: #3097d1;
    border-color: #3097d1;
    cursor: default;
}

.pe > .disabled > span,
.pe > .disabled > span:hover,
.pe > .disabled > span:focus,
.pe > .disabled > a,
.pe > .disabled > a:hover,
.pe > .disabled > a:focus {
    color: #9da9b0;
    background-color: #ffffff;
    border-color: #dddddd;
    cursor: not-allowed;
    pointer-events: none;
}

.pf > li > a,
.pf > li > span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.pf > li:first-child > a,
.pf > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}

.pf > li:last-child > a,
.pf > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}

.pg > li > a,
.pg > li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.pg > li:first-child > a,
.pg > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.pg > li:last-child > a,
.pg > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.ph {
    padding-left: 0;
    margin: 22px 0;
    list-style: none;
    text-align: center;
}

.ph li {
    display: inline;
}

.ph li > a,
.ph li > span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 15px;
}

.ph li > a:hover,
.ph li > a:focus {
    text-decoration: none;
    background-color: #d4dbe0;
}

.ph .next > a,
.ph .next > span {
    float: right;
}

.ph .pi > a,
.ph .pi > span {
    float: left;
}

.ph .disabled > a,
.ph .disabled > a:hover,
.ph .disabled > a:focus,
.ph .disabled > span {
    color: #9da9b0;
    background-color: #ffffff;
    cursor: not-allowed;
    pointer-events: none;
}

.cb {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

a.cb:hover,
a.cb:focus {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

.cb:empty {
    display: none;
}

.cg .cb {
    position: relative;
    top: -1px;
}

.pj {
    background-color: #9da9b0;
}

.pj[href]:hover,
.pj[href]:focus {
    background-color: #819099;
}

.pk {
    background-color: #3097d1;
}

.pk[href]:hover,
.pk[href]:focus {
    background-color: #2579a9;
}

.pl {
    background-color: #4eb76e;
}

.pl[href]:hover,
.pl[href]:focus {
    background-color: #3d9558;
}

.pm {
    background-color: #8eb4cb;
}

.pm[href]:hover,
.pm[href]:focus {
    background-color: #6b9dbb;
}

.pn {
    background-color: #cbb956;
}

.pn[href]:hover,
.pn[href]:focus {
    background-color: #b6a338;
}

.po {
    background-color: #bf5329;
}

.po[href]:hover,
.po[href]:focus {
    background-color: #954120;
}

.fo {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-color: #9da9b0;
    border-radius: 10px;
}

.fo:empty {
    display: none;
}

.cg .fo {
    position: relative;
    top: -1px;
}

.fz .fo,
.ga > .cg .fo {
    top: 0;
    padding: 1px 5px;
}

a.fo:hover,
a.fo:focus {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

.b.active > .fo,
.on > .active > a > .fo {
    color: #3097d1;
    background-color: #ffffff;
}

.b > .fo {
    float: right;
}

.b > .fo + .fo {
    margin-right: 5px;
}

.on > li > a > .fo {
    margin-left: 3px;
}

.pp {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    color: inherit;
    background-color: #d4dbe0;
}

.pp h1,
.pp .cy {
    color: inherit;
}

.pp p {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 200;
}

.pp > hr {
    border-top-color: #b6c2cb;
}

.by .pp,
.gb .pp {
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
}

.pp .by {
    max-width: 100%;
}

@media screen and (min-width: 768px) {
    .pp {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .by .pp,
    .gb .pp {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pp h1,
    .pp .cy {
        font-size: 63px;
    }
}

.cq {
    display: block;
    padding: 4px;
    margin-bottom: 22px;
    line-height: 1.6;
    background-color: #f5f8fa;
    border: 1px solid #dddddd;
    border-radius: 4px;
    transition: border 0.2s ease-in-out;
}

.cq > img,
.cq a > img {
    margin-left: auto;
    margin-right: auto;
}

a.cq:hover,
a.cq:focus,
a.cq.active {
    border-color: #3097d1;
}

.cq .pq {
    padding: 9px;
    color: #1e3948;
}

input.text-danger::placeholder {
    color: #842029 !important;
    opacity: 1; /* Firefox */
}

input.text-danger:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #842029 !important;
}

input.text-danger::-ms-input-placeholder { /* Microsoft Edge */
    color: #842029 !important;
}

.text-danger {
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}

.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}

.alert {
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}


.alert {
    margin-top: 5px;
}

.alert h4 {
    margin-top: 0;
    color: inherit;
}

.alert .pr {
    font-weight: bold;
}

.alert > p,
.alert > ul {
    margin-bottom: 0;
}

.alert > p + p {
    margin-top: 5px;
}


.ps .close,
.alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
}

.pt {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
}

.pt hr {
    border-top-color: #c9e2b3;
}

.pt .pr {
    color: #2b542c;
}

.pu {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
}

.pu hr {
    border-top-color: #a6e1ec;
}

.pu .pr {
    color: #245269;
}

.pv {
    background-color: #F2DEDE;
    border-color: #B35856;
    color: #B25654;
}

.pv hr {
    border-top-color: #e1c68d;
}

.pv .pr {
    color: #66512c;
}

.pw {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}

.pw hr {
    border-top-color: #e4b9c0;
}

.pw .pr {
    color: #843534;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0;
    }
    to {
        background-position: 0 0;
    }
}

.px {
    overflow: hidden;
    height: 22px;
    margin-bottom: 22px;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.py {
    float: left;
    width: 0%;
    height: 100%;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
    text-align: center;
    background-color: #3097d1;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: width 0.6s ease;
}

.pz .py,
.qa {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
}

.px.active .py,
.py.active {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
}

.qb {
    background-color: #4eb76e;
}

.pz .qb {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.qc {
    background-color: #8eb4cb;
}

.pz .qc {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.qd {
    background-color: #cbb956;
}

.pz .qd {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.qe {
    background-color: #bf5329;
}

.pz .qe {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.qf {
    margin-top: 15px;
}

.qf:first-child {
    margin-top: 0;
}

.qf,
.qg {
    zoom: 1;
    overflow: hidden;
}

.qg {
    width: 10000px;
}

.qh {
    display: block;
}

.qh.ct {
    max-width: none;
}

.qi,
.qf > .eg {
    padding-left: 10px;
}

.qj,
.qf > .qk {
    padding-right: 10px;
}

.qj,
.qi,
.qg {
    display: table-cell;
    vertical-align: top;
}

.ql {
    vertical-align: middle;
}

.qm {
    vertical-align: bottom;
}

.qn {
    margin-top: 0;
    margin-bottom: 5px;
}

.qo {
    padding-left: 0;
    list-style: none;
}

.ca {
    margin-bottom: 20px;
    padding-left: 0;
}

.b {
    position: relative;
    display: block;
    padding: 12px 10px;
    margin-bottom: -1px;
    background-color: #ffffff;
    border-bottom: 1px solid #dddde6;
    font-weight: 500;
}

.b:first-child {

}

.b:last-child {
    margin-bottom: 0;
}

a.b,
button.b {
    color: #555555;
}
button{
    cursor: pointer;
}
a{
    cursor: pointer;
}

a.b .qp,
button.b .qp {
    color: #333333;
}

a.b:hover,
button.b:hover,
a.b:focus,
button.b:focus {
    text-decoration: none;
    color: #555555;
    background-color: #f5f8fa;
}

button.b {
    width: 100%;
    text-align: left;
}

.b.disabled,
.b.disabled:hover,
.b.disabled:focus {
    background-color: #d4dbe0;
    color: #9da9b0;
    cursor: not-allowed;
}

.b.disabled .qp,
.b.disabled:hover .qp,
.b.disabled:focus .qp {
    color: inherit;
}

.b.disabled .qq,
.b.disabled:hover .qq,
.b.disabled:focus .qq {
    color: #9da9b0;
}

.b.active,
.b.active:hover,
.b.active:focus {
    z-index: 2;
    color: #ffffff;
    background-color: #3097d1;
    border-color: #3097d1;
}

.b.active .qp,
.b.active:hover .qp,
.b.active:focus .qp,
.b.active .qp > small,
.b.active:hover .qp > small,
.b.active:focus .qp > small,
.b.active .qp > .de,
.b.active:hover .qp > .de,
.b.active:focus .qp > .de {
    color: inherit;
}

.b.active .qq,
.b.active:hover .qq,
.b.active:focus .qq {
    color: #d7ebf6;
}

.qr {
    color: #3c763d;
    background-color: #dff0d8;
}

a.qr,
button.qr {
    color: #3c763d;
}

a.qr .qp,
button.qr .qp {
    color: inherit;
}

a.qr:hover,
button.qr:hover,
a.qr:focus,
button.qr:focus {
    color: #3c763d;
    background-color: #d0e9c6;
}

a.qr.active,
button.qr.active,
a.qr.active:hover,
button.qr.active:hover,
a.qr.active:focus,
button.qr.active:focus {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d;
}

.qs {
    color: #31708f;
    background-color: #d9edf7;
}

a.qs,
button.qs {
    color: #31708f;
}

a.qs .qp,
button.qs .qp {
    color: inherit;
}

a.qs:hover,
button.qs:hover,
a.qs:focus,
button.qs:focus {
    color: #31708f;
    background-color: #c4e3f3;
}

a.qs.active,
button.qs.active,
a.qs.active:hover,
button.qs.active:hover,
a.qs.active:focus,
button.qs.active:focus {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f;
}

.qt {
    color: #8a6d3b;
    background-color: #fcf8e3;
}

a.qt,
button.qt {
    color: #8a6d3b;
}

a.qt .qp,
button.qt .qp {
    color: inherit;
}

a.qt:hover,
button.qt:hover,
a.qt:focus,
button.qt:focus {
    color: #8a6d3b;
    background-color: #faf2cc;
}

a.qt.active,
button.qt.active,
a.qt.active:hover,
button.qt.active:hover,
a.qt.active:focus,
button.qt.active:focus {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b;
}

.qu {
    color: #a94442;
    background-color: #f2dede;
}

a.qu,
button.qu {
    color: #a94442;
}

a.qu .qp,
button.qu .qp {
    color: inherit;
}

a.qu:hover,
button.qu:hover,
a.qu:focus,
button.qu:focus {
    color: #a94442;
    background-color: #ebcccc;
}

a.qu.active,
button.qu.active,
a.qu.active:hover,
button.qu.active:hover,
a.qu.active:focus,
button.qu.active:focus {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442;
}

.qp {
    margin-top: 0;
    margin-bottom: 5px;
}

.qq {
    margin-bottom: 0;
    line-height: 1.3;
}

.qv {
    margin-bottom: 0;
}

.qx {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.qx > .dropdown .fn {
    color: inherit;
}

.qy {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}

.qy > a,
.qy > small,
.qy > .de,
.qy > small > a,
.qy > .de > a {
    color: inherit;
}

.qz {
    padding: 10px 15px;
    background-color: #f5f8fa;
    border-top: 1px solid #d3e0e9;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.qv > .ca,
.qv > .ra > .ca {
    margin-bottom: 0;
}

.qv > .ca .b,
.qv > .ra > .ca .b {
    border-width: 1px 0;
    border-radius: 0;
}

.qv > .ca:first-child .b:first-child,
.qv > .ra > .ca:first-child .b:first-child {
    border-top: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.qv > .ca:last-child .b:last-child,
.qv > .ra > .ca:last-child .b:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.qv > .qx + .ra > .ca .b:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.qx + .ca .b:first-child {
    border-top-width: 0;
}

.ca + .qz {
    border-top-width: 0;
}

.qv > .cn,
.qv > .eo > .cn,
.qv > .ra > .cn {
    margin-bottom: 0;
}

.qv > .cn caption,
.qv > .eo > .cn caption,
.qv > .ra > .cn caption {
    padding-left: 15px;
    padding-right: 15px;
}

.qv > .cn:first-child,
.qv > .eo:first-child > .cn:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.qv > .cn:first-child > thead:first-child > tr:first-child,
.qv > .eo:first-child > .cn:first-child > thead:first-child > tr:first-child,
.qv > .cn:first-child > tbody:first-child > tr:first-child,
.qv > .eo:first-child > .cn:first-child > tbody:first-child > tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.qv > .cn:first-child > thead:first-child > tr:first-child td:first-child,
.qv > .eo:first-child > .cn:first-child > thead:first-child > tr:first-child td:first-child,
.qv > .cn:first-child > tbody:first-child > tr:first-child td:first-child,
.qv > .eo:first-child > .cn:first-child > tbody:first-child > tr:first-child td:first-child,
.qv > .cn:first-child > thead:first-child > tr:first-child th:first-child,
.qv > .eo:first-child > .cn:first-child > thead:first-child > tr:first-child th:first-child,
.qv > .cn:first-child > tbody:first-child > tr:first-child th:first-child,
.qv > .eo:first-child > .cn:first-child > tbody:first-child > tr:first-child th:first-child {
    border-top-left-radius: 3px;
}

.qv > .cn:first-child > thead:first-child > tr:first-child td:last-child,
.qv > .eo:first-child > .cn:first-child > thead:first-child > tr:first-child td:last-child,
.qv > .cn:first-child > tbody:first-child > tr:first-child td:last-child,
.qv > .eo:first-child > .cn:first-child > tbody:first-child > tr:first-child td:last-child,
.qv > .cn:first-child > thead:first-child > tr:first-child th:last-child,
.qv > .eo:first-child > .cn:first-child > thead:first-child > tr:first-child th:last-child,
.qv > .cn:first-child > tbody:first-child > tr:first-child th:last-child,
.qv > .eo:first-child > .cn:first-child > tbody:first-child > tr:first-child th:last-child {
    border-top-right-radius: 3px;
}

.qv > .cn:last-child,
.qv > .eo:last-child > .cn:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.qv > .cn:last-child > tbody:last-child > tr:last-child,
.qv > .eo:last-child > .cn:last-child > tbody:last-child > tr:last-child,
.qv > .cn:last-child > tfoot:last-child > tr:last-child,
.qv > .eo:last-child > .cn:last-child > tfoot:last-child > tr:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.qv > .cn:last-child > tbody:last-child > tr:last-child td:first-child,
.qv > .eo:last-child > .cn:last-child > tbody:last-child > tr:last-child td:first-child,
.qv > .cn:last-child > tfoot:last-child > tr:last-child td:first-child,
.qv > .eo:last-child > .cn:last-child > tfoot:last-child > tr:last-child td:first-child,
.qv > .cn:last-child > tbody:last-child > tr:last-child th:first-child,
.qv > .eo:last-child > .cn:last-child > tbody:last-child > tr:last-child th:first-child,
.qv > .cn:last-child > tfoot:last-child > tr:last-child th:first-child,
.qv > .eo:last-child > .cn:last-child > tfoot:last-child > tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
}

.qv > .cn:last-child > tbody:last-child > tr:last-child td:last-child,
.qv > .eo:last-child > .cn:last-child > tbody:last-child > tr:last-child td:last-child,
.qv > .cn:last-child > tfoot:last-child > tr:last-child td:last-child,
.qv > .eo:last-child > .cn:last-child > tfoot:last-child > tr:last-child td:last-child,
.qv > .cn:last-child > tbody:last-child > tr:last-child th:last-child,
.qv > .eo:last-child > .cn:last-child > tbody:last-child > tr:last-child th:last-child,
.qv > .cn:last-child > tfoot:last-child > tr:last-child th:last-child,
.qv > .eo:last-child > .cn:last-child > tfoot:last-child > tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
}

.qv > .qw + .cn,
.qv > .qw + .eo,
.qv > .cn + .qw,
.qv > .eo + .qw {
    border-top: 1px solid #dddddd;
}

.qv > .cn > tbody:first-child > tr:first-child th,
.qv > .cn > tbody:first-child > tr:first-child td {
    border-top: 0;
}

.qv > .co,
.qv > .eo > .co {
    border: 0;
}

.qv > .co > thead > tr > th:first-child,
.qv > .eo > .co > thead > tr > th:first-child,
.qv > .co > tbody > tr > th:first-child,
.qv > .eo > .co > tbody > tr > th:first-child,
.qv > .co > tfoot > tr > th:first-child,
.qv > .eo > .co > tfoot > tr > th:first-child,
.qv > .co > thead > tr > td:first-child,
.qv > .eo > .co > thead > tr > td:first-child,
.qv > .co > tbody > tr > td:first-child,
.qv > .eo > .co > tbody > tr > td:first-child,
.qv > .co > tfoot > tr > td:first-child,
.qv > .eo > .co > tfoot > tr > td:first-child {
    border-left: 0;
}

.qv > .co > thead > tr > th:last-child,
.qv > .eo > .co > thead > tr > th:last-child,
.qv > .co > tbody > tr > th:last-child,
.qv > .eo > .co > tbody > tr > th:last-child,
.qv > .co > tfoot > tr > th:last-child,
.qv > .eo > .co > tfoot > tr > th:last-child,
.qv > .co > thead > tr > td:last-child,
.qv > .eo > .co > thead > tr > td:last-child,
.qv > .co > tbody > tr > td:last-child,
.qv > .eo > .co > tbody > tr > td:last-child,
.qv > .co > tfoot > tr > td:last-child,
.qv > .eo > .co > tfoot > tr > td:last-child {
    border-right: 0;
}

.qv > .co > thead > tr:first-child > td,
.qv > .eo > .co > thead > tr:first-child > td,
.qv > .co > tbody > tr:first-child > td,
.qv > .eo > .co > tbody > tr:first-child > td,
.qv > .co > thead > tr:first-child > th,
.qv > .eo > .co > thead > tr:first-child > th,
.qv > .co > tbody > tr:first-child > th,
.qv > .eo > .co > tbody > tr:first-child > th {
    border-bottom: 0;
}

.qv > .co > tbody > tr:last-child > td,
.qv > .eo > .co > tbody > tr:last-child > td,
.qv > .co > tfoot > tr:last-child > td,
.qv > .eo > .co > tfoot > tr:last-child > td,
.qv > .co > tbody > tr:last-child > th,
.qv > .eo > .co > tbody > tr:last-child > th,
.qv > .co > tfoot > tr:last-child > th,
.qv > .eo > .co > tfoot > tr:last-child > th {
    border-bottom: 0;
}

.qv > .eo {
    border: 0;
    margin-bottom: 0;
}

.rb {
    margin-bottom: 22px;
}

.rb .qv {
    margin-bottom: 0;
    border-radius: 4px;
}

.rb .qv + .qv {
    margin-top: 5px;
}

.rb .qx {
    border-bottom: 0;
}

.rb .qx + .ra > .qw,
.rb .qx + .ra > .ca {
    border-top: 1px solid #d3e0e9;
}

.rb .qz {
    border-top: 0;
}

.rb .qz + .ra .qw {
    border-bottom: 1px solid #d3e0e9;
}

.rc {
    /*border-color:;*/
}

.rc > .qx {
    color: #1e3948;
    background-color: #f5f5f5;
    border-color: #d3e0e9;
}

.rc > .qx + .ra > .qw {
    border-top-color: #d3e0e9;
}

.rc > .qx .fo {
    color: #f5f5f5;
    background-color: #1e3948;
}

.rc > .qz + .ra > .qw {
    border-bottom-color: #d3e0e9;
}

.rd {
    border-color: #3097d1;
}

.rd > .qx {
    color: #ffffff;
    background-color: #3097d1;
    border-color: #3097d1;
}

.rd > .qx + .ra > .qw {
    border-top-color: #3097d1;
}

.rd > .qx .fo {
    color: #3097d1;
    background-color: #ffffff;
}

.rd > .qz + .ra > .qw {
    border-bottom-color: #3097d1;
}

.re {
    border-color: #d6e9c6;
}

.re > .qx {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.re > .qx + .ra > .qw {
    border-top-color: #d6e9c6;
}

.re > .qx .fo {
    color: #dff0d8;
    background-color: #3c763d;
}

.re > .qz + .ra > .qw {
    border-bottom-color: #d6e9c6;
}

.rf {
    border-color: #bce8f1;
}

.rf > .qx {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.rf > .qx + .ra > .qw {
    border-top-color: #bce8f1;
}

.rf > .qx .fo {
    color: #d9edf7;
    background-color: #31708f;
}

.rf > .qz + .ra > .qw {
    border-bottom-color: #bce8f1;
}

.rg {
    border-color: #faebcc;
}

.rg > .qx {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.rg > .qx + .ra > .qw {
    border-top-color: #faebcc;
}

.rg > .qx .fo {
    color: #fcf8e3;
    background-color: #8a6d3b;
}

.rg > .qz + .ra > .qw {
    border-bottom-color: #faebcc;
}

.rh {
    border-color: #ebccd1;
}

.rh > .qx {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.rh > .qx + .ra > .qw {
    border-top-color: #ebccd1;
}

.rh > .qx .fo {
    color: #f2dede;
    background-color: #a94442;
}

.rh > .qz + .ra > .qw {
    border-bottom-color: #ebccd1;
}

.ri {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.ri .rj,
.ri iframe,
.ri embed,
.ri object,
.ri video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
}

.rk {
    padding-bottom: 56.25%;
}

.rl {
    padding-bottom: 75%;
}

.rm {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.rm blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
}

.rn {
    padding: 24px;
    border-radius: 6px;
}

.ro {
    padding: 9px;
    border-radius: 3px;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.2;
    filter: alpha(opacity=20);
}

.close:hover,
.close:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity=50);
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    font-size: 25px;
    color: #000022;
    opacity: 0.7;
}

.modal-open {
    overflow: hidden;
}

.cd {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.cd.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
    /*transition: -webkit-transform 0.1s ease-out;*/
    /*transition: transform 0.1s ease-out;*/
}

.cd.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-open .cd {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}

.modal-content {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #9da9b0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000000;
}

.modal-backdrop.fade {
    opacity: 0.4;
    filter: alpha(opacity=0);
}

.modal-backdrop.in {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.d {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.d .close {
    margin-top: -2px;
}

.modal-title {
    margin: 0;
    line-height: 1.6;
}

.modal-body {
    position: relative;
    padding: 15px;
    max-height: 29rem;
}

.rp {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.rp .cg + .cg {
    margin-left: 5px;
    margin-bottom: 0;
}

.rp .oh .cg + .cg {
    margin-left: -1px;
}

.rp .btn-block + .btn-block {
    margin-left: 0;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto;
    }

    .modal-content {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    .rq {
        width: 300px;
    }
}
/*.sticky-responsive {*/
/*    position: sticky;*/
/*    top: 115px;*/
/*}*/
@media (min-width: 992px) {

    .c-btn-header{
        width: 100%;
    }
    .size-info {
        width: 34%;
    }

    .ce {
        width: 900px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    opacity: 0;
    filter: alpha(opacity=0);
}

.tooltip.in {
    opacity: 0.9;
    filter: alpha(opacity=90);
}

.tooltip.top {
    margin-top: -3px;
    padding: 5px 0;
}

.tooltip.right {
    margin-left: 3px;
    padding: 0 5px;
}

.tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0;
}

.tooltip.left {
    margin-left: -3px;
    padding: 0 5px;
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #ffffff;
    text-align: center;
    background-color: #000000;
    border-radius: 4px;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
}

.tooltip.top-left .tooltip-arrow {
    bottom: 0;
    right: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
}

.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000000;
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000000;
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000000;
}

.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000000;
}

.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000000;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 14px;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #d4dbe0;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.popover.top {
    margin-top: -10px;
}

.popover.right {
    margin-left: 10px;
}

.popover.bottom {
    margin-top: 10px;
}

.popover.left {
    margin-left: -10px;
}

.popover-title {
    margin: 0;
    padding: 8px 14px;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}

.popover-content {
    padding: 9px 14px;
}

.popover > .arrow,
.popover > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.popover > .arrow {
    border-width: 11px;
}

.popover > .arrow:after {
    border-width: 10px;
    content: "";
}

.popover.top > .arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #99a9b5;
    border-top-color: #d4dbe0;
    bottom: -11px;
}

.popover.top > .arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #ffffff;
}

.popover.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: #99a9b5;
    border-right-color: #d4dbe0;
}

.popover.right > .arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #ffffff;
}

.popover.bottom > .arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #99a9b5;
    border-bottom-color: #d4dbe0;
    top: -11px;
}

.popover.bottom > .arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #ffffff;
}

.popover.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #99a9b5;
    border-left-color: #d4dbe0;
}

.popover.left > .arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: #ffffff;
    bottom: -10px;
}

.carousel {
    position: relative;
}

.cr {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.cr > .item {
    display: none;
    position: relative;
    transition: 0.6s ease-in-out left;
}

.cr > .item > img,
.cr > .item > a > img {
    line-height: 1;
}

@media all and (transform-3d), (-webkit-transform-3d) {
    .cr > .item {
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px;
    }

    .cr > .item.next,
    .cr > .item.active.right {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        left: 0;
    }

    .cr > .item.prev,
    .cr > .item.active.left {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        left: 0;
    }

    .cr > .item.next.left,
    .cr > .item.prev.right,
    .cr > .item.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}

.cr > .active,
.cr > .next,
.cr > .prev {
    display: block;
}

.cr > .active {
    left: 0;
}

.cr > .next,
.cr > .prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.cr > .next {
    left: 100%;
}

.cr > .prev {
    left: -100%;
}

.cr > .next.left,
.cr > .prev.right {
    left: 0;
}

.cr > .active.left {
    left: -100%;
}

.cr > .active.right {
    left: 100%;
}

.rr {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15%;
    opacity: 0.5;
    filter: alpha(opacity=50);
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0);
}

.rr.left {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-repeat: repeat-x;
    /*filter: progid:DXImageTransform.Mob.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);*/
}

.rr.right {
    left: auto;
    right: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-repeat: repeat-x;
    /*filter: progid:DXImageTransform.Mob.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);*/
}

.rr:hover,
.rr:focus {
    outline: 0;
    color: #ffffff;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90);
}

.rr .rs,
.rr .rt,
.rr .ru,
.rr .rv {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
}

.rr .rs,
.rr .ru {
    left: 50%;
    margin-left: -10px;
}

.rr .rt,
.rr .rv {
    right: 50%;
    margin-right: -10px;
}

.rr .rs,
.rr .rt {
    width: 20px;
    height: 20px;
    line-height: 1;
}

.rr .rs:before {
    content: '\2039';
}

.rr .rt:before {
    content: '\203a';
}

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center;
}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
    background-color: #000 \9;
    background-color: rgba(0, 0, 0, 0);
}

.carousel-indicators .active {
    margin: 0;
    width: 12px;
    height: 12px;
    background-color: #ffffff;
}

.rw {
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.rw .cg {
    text-shadow: none;
}

@media screen and (min-width: 768px) {
    .rr .ru,
    .rr .rv,
    .rr .rs,
    .rr .rt {
        width: 30px;
        height: 30px;
        margin-top: -10px;
        font-size: 30px;
    }

    .rr .ru,
    .rr .rs {
        margin-left: -10px;
    }

    .rr .rv,
    .rr .rt {
        margin-right: -10px;
    }

    .rw {
        left: 20%;
        right: 20%;
        padding-bottom: 30px;
    }

    .carousel-indicators {
        bottom: 0px;
    }
}

.rx:before,
.rx:after,
.ed dd:before,
.ed dd:after,
.fk .et:before,
.fk .et:after,
.by:before,
.by:after,
.gb:before,
.gb:after,
.gc:before,
.gc:after,
.oj:before,
.oj:after,
.oi > .oh:before,
.oi > .oh:after,
.nav:before,
.nav:after,
.ck:before,
.ck:after,
.or:before,
.or:after,
.f:before,
.f:after,
.ph:before,
.ph:after,
.qw:before,
.qw:after,
.d:before,
.d:after,
.rp:before,
.rp:after {
    content: " ";
    display: table;
}

.rx:after,
.ed dd:after,
.fk .et:after,
.by:after,
.gb:after,
.gc:after,
.oj:after,
.oi > .oh:after,
.nav:after,
.ck:after,
.or:after,
.f:after,
.ph:after,
.qw:after,
.d:after,
.rp:after {
    clear: both;
}

.ry {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.eg {
    float: right !important;
}

.qk {
    float: left !important;
}

.hide {
    display: none !important;
}

.rz {
    display: block !important;
}

.sa {
    visibility: hidden;
}

.sb {
    /*font: 0/0 a;*/
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.hidden {
    display: none !important;
}

.affix {
    position: fixed;
}

@-ms-viewport {
    /*width: device-width;*/
}

.sc,
.sd,
.se,
.sf {
    display: none !important;
}

.sg,
.sh,
.si,
.sj,
.sk,
.sl,
.sm,
.sn,
.so,
.sp,
.sq,
.sr {
    display: none !important;
}

@media (max-width: 767px) {
    .sc {
        display: block !important;
    }

    table.sc {
        display: table !important;
    }

    tr.sc {
        display: table-row !important;
    }

    th.sc,
    td.sc {
        display: table-cell !important;
    }
}

@media (max-width: 767px) {

    .sg {
        display: block !important;
    }
}
@media (min-width: 992px){
    .mobile-only{
        display: none!important;
        margin-top: 0.5rem;
    }
}

@media (max-width: 767px) {
    .sh {
        display: inline !important;
    }
}

@media (max-width: 767px) {
    .si {
        display: inline-block !important;
    }
}

@media (min-width: 769px) and (max-width: 991px) {
    .logo {
        position: relative;
        color: #fff !important;
        /* padding-top: 8px; */
        padding-top: 4px;
    }
    /*.change-display {*/
    /*    flex-wrap: nowrap;*/
    /*    font-size: 11px;*/
    /*    display: grid !important;*/
    /*    !*code needed to commented out after commenting out casino option*!*/
    /*    !*grid-template-columns: [col0]0px[col1]60px[col2]70px[col3]100px[col4]80px[col5]120px[col6]120px[col7]140px[col8]120px[col9]90px[col10]70px[col11]108px[col-end];*!*/
    /*    grid-template-columns: [col0]0px[col1]60px[col2]70px[col3]100px[col4]200px[col5]80px[col7]110px[col8]130px[col9]130px[col10]50px[col11]120px[col12]0[col-end];*/
    /*    overflow: auto;*/
    /*}*/
    .sticky-testimony{
        top: 107px;
    }
    .sticky-responsive{
        top: 134px!important
    }
    .sd {
        display: block !important;
    }

    table.sd {
        display: table !important;
    }

    tr.sd {
        display: table-row !important;
    }

    th.sd,
    td.sd {
        display: table-cell !important;
    }
}
.to-flex-1{
    flex-direction: column;
}
@media (min-width: 768px) and (max-width: 991px) {
    .sj {
        display: block !important;
    }
    .navbar-expand-md .navbar-toggler {
         display: flex!important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .sk {
        display: inline !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .sl {
        display: inline-block !important;
    }
}
.display-ipad-more-options{
    display:flex;
    justify-content:flex-end;
}
@media (min-width: 992px)  {
    .justify-spacing-ipad{
        justify-content:center!important;
    }

    .fix-view-2{
        width:77.8% !important;
    }
    .desk-top{
        margin-top:0.3rem;
    }
    .se {
        display: block !important;
    }

    table.se {
        display: table !important;
    }

    tr.se {
        display: table-row !important;
    }

    th.se,
    td.se {
        display: table-cell !important;
    }
}
/*@media (min-width: 768px) and (max-width: 1259px) {*/

/*    .live-pro-sidebar{*/
/*        !*width:150px!important;*!*/
/*        min-width:200px!important;*/
/*    }*/
/*    .pro-sidebar{*/
/*        width:200px;*/
/*        min-width:200px*/
/*    }*/
/*    .card-small {*/
/*        position: relative;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        min-width: 0;*/
/*        word-wrap: break-word;*/
/*        background-clip: border-box;*/
/*        border: 1px solid rgba(0, 0, 0, .125);*/
/*        border-radius: 10px;*/
/*        !*transform: translate(1px, 2px);*!*/
/*    }*/
/*    .container-size {*/
/*        width: 95%!important;*/
/*        padding-right: 1.5rem!important;*/
/*        padding-left: 1.5rem!important;*/
/*        margin-right: 1.5rem!important;*/
/*        margin-left: 1.5rem!important;*/

/*    }*/
/*    .to-none{*/
/*        display: none;*/
/*    }*/
/*    .c-btn-group {*/
/*        width: 100%;*/
/*        margin-left: 0 !important;*/
/*    }*/
/*    .to-tabview{*/
/*        display: flex;*/
/*    }*/

/*    .to-navcheck{*/
/*        display: flex;*/
/*    }*/
/*    .to-profilecheck{*/
/*        display:none;*/
/*    }*/
/*    .to-flex-2{*/
/*        display:flex!important;*/
/*        flex-direction:row;*/
/*    }*/
/*    .size-info{*/
/*        width:100%!important;*/
/*    }*/
/*    .to-deskview{*/
/*        display: none!important;*/
/*    }*/
/*    .to-block{*/
/*        display: block!important;*/
/*    }*/
/*    .to-flex-1{*/
/*        flex-direction: column;*/
/*    }*/
/*    .to-flex{*/
/*        flex-direction: row;*/
/*    }*/
/*    .space-bets {*/
/*        flex-direction: column !important;}*/

/*}*/


/*@media (min-width: 992px) and (max-width: 1259px) {*/
/*    .sticky-responsive{*/
/*        top:110px*/
/*    }*/
/*    .sm {*/
/*        display: block !important;*/
/*    }*/
/*}*/

/*@media (min-width: 992px) and (max-width: 1259px) {*/
/*    .sn {*/
/*        display: inline !important;*/
/*    }*/
/*}*/

/*@media (min-width: 992px) and (max-width: 1259px) {*/
/*    .so {*/
/*        display: inline-block !important;*/
/*    }*/
/*}*/


.scrollbar-container {
    height: 100vh;
}

@media (max-width: 768px) {
    .live-color{
        margin-left: 2px;
        background: red;
    }

    /*.change-display {*/
    /*    flex-wrap: nowrap;*/
    /*    font-size: 11px;*/
    /*    display: grid !important;*/
    /*    !*code needed to commented out after commenting out casino option*!*/
    /*    !*grid-template-columns: [col0]0px[col1]60px[col2]70px[col3]100px[col4]80px[col5]120px[col6]120px[col7]140px[col8]120px[col9]90px[col10]70px[col11]108px[col-end];*!*/
    /*    grid-template-columns: [col30]0px[col1]60px[col2]70px[col3]100px[col4]80px[col5]120px[col7]140px[col8]120px[col9]120px[col10]130px[col11]150px[col12]0[col-end];*/
    /*    overflow: auto;*/
    /*}*/
    table.bdd {
        font-size: 10px;
    }

    table.basic-table.bd {
        font-size: 10px;
    }


    .shrink-header .second-nav {
        top: 62px !important;
    }

    .by.amt {
        margin-top: 30px !important;
    }

    .scrollbar-container {
        height: auto;
    }


}

.fire {
    width: 30px !important;
    height: 30px;
}

@media (min-width: 865px) and (max-width: 991px) {
    .app-header-nav {
        top: 62px
    }
    .app-header-nav-login {
        top: 62px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .betslip-container{
        width:0px
    }
    .st {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .casino-category-container {

        width:fit-content;

    }
    .su {
        display: none !important;
    }
}



.sw {
    display: none !important;
}

@media print {
    .sw {
        display: block !important;
    }

    table.sw {
        display: table !important;
    }

    tr.sw {
        display: table-row !important;
    }

    th.sw,
    td.sw {
        display: table-cell !important;
    }
}

.sx {
    display: none !important;
}

@media print {
    .sx {
        display: block !important;
    }
}

.sy {
    display: none !important;
}

@media print {
    .sy {
        display: inline !important;
    }
}

.sz {
    display: none !important;
}

@media print {
    .sz {
        display: inline-block !important;
    }
}

@media print {
    .ta {
        display: none !important;
    }
}

body {
    letter-spacing: 0;
}

.df {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0;
}

@media (min-width: 768px) {
    .df {
        font-size: 21px;
    }
}

.tb li {
    margin-bottom: 3px;
}

.tc li {
    border-top: 1px solid #9da9b0;
}

.tc li:last-child {
    border-bottom: 1px solid #9da9b0;
}

.td {
}

.te {
    text-align: left;
}

.tf {
    text-align: right;
}

.tg {
    text-align: center;
}

@media (min-width: 768px) {
    .th {
        text-align: left;
    }

    .ti {
        text-align: right;
    }

    .tj {
        text-align: center;
    }
}

@media (min-width: 992px) {
    .tk {
        text-align: left;
    }

    .tl {
        text-align: right;
    }

    .tm {
        text-align: center;
    }
}


.alert-dark {
    background-color: rgba(0, 0, 0, 0.9);
    border-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
}

.alert-dark hr {
    border-top-color: rgba(0, 0, 0, 0.8);
}

.alert-dark .pr {
    color: #e6e6e6;
}

.alert-dark .close {
    text-shadow: 0 1px 0 #000;
}

.alert-dark .close:hover,
.alert-dark .close:focus {
    color: #fff;
}

.tq {
    border-radius: 0;
}

.tr {
    color: #536570;
    background-color: transparent;
    border-color: #536570;
}

.tr:focus,
.tr.fl,
.tr:hover,
.tr:active,
.tr.active,
.open > .fn.tr {
    color: #ffffff;
    background-color: #536570;
    box-shadow: none;
}

.tr.disabled,
.tr[disabled],
fieldset[disabled] .tr,
.tr.disabled:hover,
.tr[disabled]:hover,
fieldset[disabled] .tr:hover,
.tr.disabled:focus,
.tr[disabled]:focus,
fieldset[disabled] .tr:focus,
.tr.disabled.fl,
.tr[disabled].fl,
fieldset[disabled] .tr.fl,
.tr.disabled:active,
.tr[disabled]:active,
fieldset[disabled] .tr:active,
.tr.disabled.active,
.tr[disabled].active,
fieldset[disabled] .tr.active {
    border-color: #536570;
}

.tr .fo {
    color: #ffffff;
    background-color: #536570;
}

.ts {
    color: #2a88bd;
    background-color: transparent;
    border-color: #2a88bd;
}

.ts:focus,
.ts.fl,
.ts:hover,
.ts:active,
.ts.active,
.open > .fn.ts {
    color: #ffffff;
    background-color: #2a88bd;
    box-shadow: none;
}

.ts.disabled,
.ts[disabled],
fieldset[disabled] .ts,
.ts.disabled:hover,
.ts[disabled]:hover,
fieldset[disabled] .ts:hover,
.ts.disabled:focus,
.ts[disabled]:focus,
fieldset[disabled] .ts:focus,
.ts.disabled.fl,
.ts[disabled].fl,
fieldset[disabled] .ts.fl,
.ts.disabled:active,
.ts[disabled]:active,
fieldset[disabled] .ts:active,
.ts.disabled.active,
.ts[disabled].active,
fieldset[disabled] .ts.active {
    border-color: #2a88bd;
}

.ts .fo {
    color: #ffffff;
    background-color: #2a88bd;
}

.tt {
    color: #44a762;
    background-color: transparent;
    border-color: #44a762;
}

.tt:focus,
.tt.fl,
.tt:hover,
.tt:active,
.tt.active,
.open > .fn.tt {
    color: #ffffff;
    background-color: #44a762;
    box-shadow: none;
}

.tt.disabled,
.tt[disabled],
fieldset[disabled] .tt,
.tt.disabled:hover,
.tt[disabled]:hover,
fieldset[disabled] .tt:hover,
.tt.disabled:focus,
.tt[disabled]:focus,
fieldset[disabled] .tt:focus,
.tt.disabled.fl,
.tt[disabled].fl,
fieldset[disabled] .tt.fl,
.tt.disabled:active,
.tt[disabled]:active,
fieldset[disabled] .tt:active,
.tt.disabled.active,
.tt[disabled].active,
fieldset[disabled] .tt.active {
    border-color: #44a762;
}

.tt .fo {
    color: #ffffff;
    background-color: #44a762;
}

.tu {
    color: #7da8c3;
    background-color: transparent;
    border-color: #7da8c3;
}

.tu:focus,
.tu.fl,
.tu:hover,
.tu:active,
.tu.active,
.open > .fn.tu {
    color: #ffffff;
    background-color: #7da8c3;
    box-shadow: none;
}

.tu.disabled,
.tu[disabled],
fieldset[disabled] .tu,
.tu.disabled:hover,
.tu[disabled]:hover,
fieldset[disabled] .tu:hover,
.tu.disabled:focus,
.tu[disabled]:focus,
fieldset[disabled] .tu:focus,
.tu.disabled.fl,
.tu[disabled].fl,
fieldset[disabled] .tu.fl,
.tu.disabled:active,
.tu[disabled]:active,
fieldset[disabled] .tu:active,
.tu.disabled.active,
.tu[disabled].active,
fieldset[disabled] .tu.active {
    border-color: #7da8c3;
}

.tu .fo {
    color: #ffffff;
    background-color: #7da8c3;
}

.tv {
    color: #c5b142;
    background-color: transparent;
    border-color: #c5b142;
}

.tv:focus,
.tv.fl,
.tv:hover,
.tv:active,
.tv.active,
.open > .fn.tv {
    color: #ffffff;
    background-color: #c5b142;
    box-shadow: none;
}

.tv.disabled,
.tv[disabled],
fieldset[disabled] .tv,
.tv.disabled:hover,
.tv[disabled]:hover,
fieldset[disabled] .tv:hover,
.tv.disabled:focus,
.tv[disabled]:focus,
fieldset[disabled] .tv:focus,
.tv.disabled.fl,
.tv[disabled].fl,
fieldset[disabled] .tv.fl,
.tv.disabled:active,
.tv[disabled]:active,
fieldset[disabled] .tv:active,
.tv.disabled.active,
.tv[disabled].active,
fieldset[disabled] .tv.active {
    border-color: #c5b142;
}

.tv .fo {
    color: #ffffff;
    background-color: #c5b142;
}

.tw {
    color: #aa4a24;
    background-color: transparent;
    border-color: #aa4a24;
}

.tw:focus,
.tw.fl,
.tw:hover,
.tw:active,
.tw.active,
.open > .fn.tw {
    color: #ffffff;
    background-color: #aa4a24;
    box-shadow: none;
}

.tw.disabled,
.tw[disabled],
fieldset[disabled] .tw,
.tw.disabled:hover,
.tw[disabled]:hover,
fieldset[disabled] .tw:hover,
.tw.disabled:focus,
.tw[disabled]:focus,
fieldset[disabled] .tw:focus,
.tw.disabled.fl,
.tw[disabled].fl,
fieldset[disabled] .tw.fl,
.tw.disabled:active,
.tw[disabled]:active,
fieldset[disabled] .tw:active,
.tw.disabled.active,
.tw[disabled].active,
fieldset[disabled] .tw.active {
    border-color: #aa4a24;
}

.tw .fo {
    color: #ffffff;
    background-color: #aa4a24;
}

.cg {
    font-size: 14px;
    /*text-transform: default;*/
    /*letter-spacing: none;*/
}

.tx {
    border: 0;
}

.fv,
.fw > .cg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}

.fx,
.fy > .cg {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.fz,
.ga > .cg {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.ty:after {
    position: relative;
    top: 1px;
    content: '〉';
    display: inline-block;
    padding-left: .3em;
    color: inherit;
}

.oj .tz {
    float: left;
}

.oj > .tz {
    margin-left: 5px;
}

.ua {
    float: left;
    width: 1px;
    height: 34px;
    margin-left: 10px;
    margin-right: 5px;
    background-color: #d4dbe0;
}

.ok.ub {
    width: calc(100% + 10px);
    margin-left: -5px;
    border-spacing: 5px;
}

.uc {
    padding-left: 1.25em;
    padding-right: 1.25em;
    border-radius: 1000em;
}

.ud {
    border-radius: 0;
}

.ue {
    display: inline-block;
    padding: 6px 36px 6px 12px;
    font-size: 14px;
    line-height: 1.6;
    color: #536570;
    vertical-align: middle;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAMAAACzvE1FAAAADFBMVEUzMzMzMzMzMzMzMzMKAG/3AAAAA3RSTlMAf4C/aSLHAAAAPElEQVR42q3NMQ4AIAgEQTn//2cLdRKppSGzBYwzVXvznNWs8C58CiussPJj8h6NwgorrKRdTvuV9v16Afn0AYFOB7aYAAAAAElFTkSuQmCC) no-repeat right 12px center;
    background-color: #ffffff;
    background-clip: padding-box;
    background-size: 8px 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none \9;
    padding-right: 12px \9;
}

.ue:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.ue:disabled {
    background-color: #d4dbe0;
    cursor: not-allowed;
}

.uf {
    padding-top: 3px;
    padding-bottom: 3px;
}

.ug > label {
    padding-left: 25px;
    font-weight: normal;
}

.ug.ew,
.ug.ex {
    padding-left: 25px;
}

.ug.ew > label,
.ug.ex > label {
    padding-left: 0;
}

.ug input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    /* Put the input behind the label so it doesn't overlay text */
}

.uh {
    position: absolute;
    top: 1px;
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
    text-align: center;
    background-color: #ffffff;
    background-clip: padding-box;
    background-size: 75% 75%;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.ui:hover .uj {
  background-color: #ccc;
}
*/
/* Focus */
.ug input:focus ~ .uh {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

/* Checked state */
.ug input:checked ~ .uh {
    background-color: #3097d1;
    border-color: #3097d1;
    box-shadow: none;
}

/* Active */
.ug input:active ~ .uh {
    background-color: #6fb6df;
    border-color: #6fb6df;
    box-shadow: none;
}

.ug input:disabled ~ .uh {
    background-color: #f2f2f2;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: none;
    cursor: not-allowed;
}

/* Checkbox modifiers */
.fk .uh {
    margin-top: 6px;
}

.uk .uh {
    border-radius: .33rem;
}

.uk input:checked ~ .uh {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
}

/* Radio modifiers */
.ul .uh {
    border-radius: 50%;
}

.ul input:checked ~ .uh {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
}

/* Alternately, use another character */
.um input:checked ~ .uh {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xLjQsMEwwLDEuNGwwLjcsMC43bDEuOCwxLjhMMC43LDUuN0wwLDYuNGwxLjQsMS40bDAuNy0wLjdsMS44LTEuOGwxLjgsMS44bDAuNywwLjdsMS40LTEuNEw3LjEsNS43DQoJTDUuMywzLjlsMS44LTEuOGwwLjctMC43TDYuNCwwTDUuNywwLjdMMy45LDIuNUwyLjEsMC43QzIuMSwwLjcsMS40LDAsMS40LDB6Ii8+DQo8L3N2Zz4NCg==);
}

.un input:checked ~ .uh {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0wLDN2Mmg4VjNIMHoiLz4NCjwvc3ZnPg0K);
}

.uo .ug + .ug {
    margin-left: 20px;
}

.up .ug:after {
    display: block;
    content: "";
}

.ew,
.ex {
    position: relative;
}

.ew label,
.ex label {
    margin-bottom: 0;
    cursor: pointer;
}

.form-control {
    transition: none;
}

.form-control:focus {
    background-color: #fff;
    box-shadow: none;
    border-color: #24367e;
}

.uq {
    max-height: 500px;
    overflow: auto;
    border-radius: 6px;
}

.modal-body + .modal-body {
    border-top: 1px solid #e5e5e5;
}

.ur:before,
.ur:after {
    content: " ";
    display: table;
}

.ur:after {
    clear: both;
}

.ur:before,
.ur:after {
    content: " ";
    display: table;
}

.ur:after {
    clear: both;
}

.us {
    float: left;
    width: 50%;
    padding: 15px;
    border: solid #e5e5e5;
    border-width: 1px 0 0;
}

.us + .us {
    border-left-width: 1px;
}

.us:first-child {
    border-bottom-left-radius: 5px;
}

.us:last-child {
    border-bottom-right-radius: 5px;
}

.us:hover,
.us:focus,
.us:active {
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #e5e5e5;
}

.ut {
    display: table;
    width: 100%;
    height: 100vh;
}

.ut .uu,
.ut .uv {
    display: table-cell;
    vertical-align: middle;
}

.ut .uu {
    vertical-align: bottom;
}

@media (min-width: 768px) {
    .uw {
        padding-right: 40px;
        padding-left: 40px;
    }
}

.dropdown-menu {
    padding: 10px 0;
}

.dropdown-menu > li > a {
    padding: 5px 20px;
}

.h:before {
    position: relative;
    top: 2px;
    display: inline-block;
    speak: none;
    font-size: 100%;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.carousel .ux,
.carousel .uy {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
}

.carousel .ux {
    left: 50%;
    margin-left: -10px;
}

.carousel .uy {
    right: 50%;
    margin-right: -10px;
}

.uz:before {
    content: "\EA01";
}

.va:before {
    content: "\EA02";
}

.vb:before {
    content: "\EA03";
}

.vc:before {
    content: "\EA04";
}

.vd:before {
    content: "\EA05";
}

.ve:before {
    content: "\EA06";
}

.vf:before {
    content: "\EA07";
}

.vg:before {
    content: "\EA08";
}

.vh:before {
    content: "\EA09";
}

.vi:before {
    content: "\EA0A";
}

.vj:before {
    content: "\EA0B";
}

.vk:before {
    content: "\EA0C";
}

.vl:before {
    content: "\EA0D";
}

.vm:before {
    content: "\EA0E";
}

.vn:before {
    content: "\EA0F";
}

.vo:before {
    content: "\EA10";
}

.vp:before {
    content: "\EA11";
}

.vq:before {
    content: "\EA12";
}

.vr:before {
    content: "\EA13";
}

.vs:before {
    content: "\EA14";
}

.vt:before {
    content: "\EA15";
}

.vu:before {
    content: "\EA16";
}

.vv:before {
    content: "\EA17";
}

.vw:before {
    content: "\EA18";
}

.vx:before {
    content: "\EA19";
}

.vy:before {
    content: "\EA1A";
}

.vz:before {
    content: "\EA1B";
}

.wa:before {
    content: "\EA1C";
}

.wb:before {
    content: "\EA1D";
}

.wc:before {
    content: "\EA1E";
}

.wd:before {
    content: "\EA1F";
}

.we:before {
    content: "\EA20";
}

.wf:before {
    content: "\EA21";
}

.wg:before {
    content: "\EA22";
}

.wh:before {
    content: "\EA23";
}

.wi:before {
    content: "\EA24";
}

.wj:before {
    content: "\EA25";
}

.wk:before {
    content: "\EA26";
}

.wl:before {
    content: "\EA27";
}

.wm:before {
    content: "\EA28";
}

.wn:before {
    content: "\EA29";
}

.wo:before {
    content: "\EA2A";
}

.wp:before {
    content: "\EA2B";
}

.wq:before {
    content: "\EA2C";
}

.wr:before {
    content: "\EA2D";
}

.ws:before {
    content: "\EA2E";
}

.wt:before {
    content: "\EA2F";
}

.wu:before {
    content: "\EA30";
}

.wv:before {
    content: "\EA31";
}

.ww:before {
    content: "\EA32";
}

.wx:before {
    content: "\EA33";
}

.wy:before {
    content: "\EA34";
}

.wz:before {
    content: "\EA35";
}

.xa:before {
    content: "\EA36";
}

.xb:before {
    content: "\EA37";
}

.xc:before {
    content: "\EA38";
}

.xd:before {
    content: "\EA39";
}

.xe:before {
    content: "\EA3A";
}

.xf:before {
    content: "\EA3B";
}

.xg:before {
    content: "\EA3C";
}

.xh:before {
    content: "\EA3D";
}

.xi:before {
    content: "\EA3E";
}

.xj:before {
    content: "\EA3F";
}

.xk:before {
    content: "\EA40";
}

.xl:before {
    content: "\EA41";
}

.xm:before {
    content: "\EA42";
}

.xn:before {
    content: "\EA43";
}

.xo:before {
    content: "\EA44";
}

.xp:before {
    content: "\EA45";
}

.xq:before {
    content: "\EA46";
}

.xr:before {
    content: "\EA47";
}

.xs:before {
    content: "\EA48";
}

.xt:before {
    content: "\EA49";
}

.ux:before {
    content: "\EA4A";
}

.uy:before {
    content: "\EA4B";
}

.xu:before {
    content: "\EA4C";
}

.xv:before {
    content: "\EA4D";
}

.xw:before {
    content: "\EA4E";
}

.xx:before {
    content: "\EA4F";
}

.xy:before {
    content: "\EA50";
}

.xz:before {
    content: "\EA51";
}

.ya:before {
    content: "\EA52";
}

.yb:before {
    content: "\EA53";
}

.yc:before {
    content: "\EA54";
}

.yd:before {
    content: "\EA55";
}

.ye:before {
    content: "\EA56";
}

.yf:before {
    content: "\EA57";
}

.yg:before {
    content: "\EA58";
}

.yh:before {
    content: "\EA59";
}

.yi:before {
    content: "\EA5A";
}

.yj:before {
    content: "\EA5B";
}

.yk:before {
    content: "\EA5C";
}

.yl:before {
    content: "\EA5D";
}

.ym:before {
    content: "\EA5E";
}

.yn:before {
    content: "\EA5F";
}

.yo:before {
    content: "\EA60";
}

.yp:before {
    content: "\EA61";
}

.yq:before {
    content: "\EA62";
}

.yr:before {
    content: "\EA63";
}

.ys:before {
    content: "\EA64";
}

.yt:before {
    content: "\EA65";
}

.yu:before {
    content: "\EA66";
}

.yv:before {
    content: "\EA67";
}

.yw:before {
    content: "\EA68";
}

.yx:before {
    content: "\EA69";
}

.yy:before {
    content: "\EA6A";
}

.yz:before {
    content: "\EA6B";
}

.za:before {
    content: "\EA6C";
}

.zb:before {
    content: "\EA6D";
}

.zc:before {
    content: "\EA6E";
}

.zd:before {
    content: "\EA6F";
}

.ze:before {
    content: "\EA70";
}

.zf:before {
    content: "\EA71";
}

.zg:before {
    content: "\EA72";
}

.zh:before {
    content: "\EA73";
}

.zi:before {
    content: "\EA74";
}

.zj:before {
    content: "\EA75";
}

.zk:before {
    content: "\EA76";
}

.zl:before {
    content: "\EA77";
}

.zm:before {
    content: "\EA78";
}

.zn:before {
    content: "\EA79";
}

.zo:before {
    content: "\EA7A";
}

.zp:before {
    content: "\EA7B";
}

.zq:before {
    content: "\EA7C";
}

.zr:before {
    content: "\EA7D";
}

.zs:before {
    content: "\EA7E";
}

.zt:before {
    content: "\EA7F";
}

.zu:before {
    content: "\EA80";
}

.zv:before {
    content: "\EA81";
}

.zw:before {
    content: "\EA82";
}

.zx:before {
    content: "\EA83";
}

.zy:before {
    content: "\EA84";
}

.zz:before {
    content: "\EA85";
}

.aaa:before {
    content: "\EA86";
}

.aab:before {
    content: "\EA87";
}

.aac:before {
    content: "\EA88";
}

.aad:before {
    content: "\EA89";
}

.aae:before {
    content: "\EA8A";
}

.aaf:before {
    content: "\EA8B";
}

.aag:before {
    content: "\EA8C";
}

.aah:before {
    content: "\EA8D";
}

.aai:before {
    content: "\EA8E";
}

.aaj:before {
    content: "\EA8F";
}

.aak:before {
    content: "\EA90";
}

.aal:before {
    content: "\EA91";
}

.aam:before {
    content: "\EA92";
}

.aan:before {
    content: "\EA93";
}

.aao:before {
    content: "\EA94";
}

.aap:before {
    content: "\EA95";
}

.aaq:before {
    content: "\EA96";
}

.aar:before {
    content: "\EA97";
}

.aas:before {
    content: "\EA98";
}

.aat:before {
    content: "\EA99";
}

.aau:before {
    content: "\EA9A";
}

.aav:before {
    content: "\EA9B";
}

.aaw:before {
    content: "\EA9C";
}

.aax:before {
    content: "\EA9D";
}

.aay:before {
    content: "\EA9E";
}

.aaz:before {
    content: "\EA9F";
}

.aba:before {
    content: "\EAA0";
}

.abb:before {
    content: "\EAA1";
}

.abc:before {
    content: "\EAA2";
}

.abd:before {
    content: "\EAA3";
}

.abe:before {
    content: "\EAA4";
}

.abf:before {
    content: "\EAA5";
}

.abg:before {
    content: "\EAA6";
}

.abh:before {
    content: "\EAA7";
}

.abi:before {
    content: "\EAA8";
}

.abj:before {
    content: "\EAA9";
}

.abk:before {
    content: "\EAAA";
}

.abl:before {
    content: "\EAAB";
}

.abm:before {
    content: "\EAAC";
}

.abn:before {
    content: "\EAAD";
}

.abo:before {
    content: "\EAAE";
}

.abp:before {
    content: "\EAAF";
}

.abq:before {
    content: "\EAB0";
}

.abr:before {
    content: "\EAB1";
}

.abs:before {
    content: "\EAB2";
}

.abt:before {
    content: "\EAB3";
}

.abu:before {
    content: "\EAB4";
}

.abv:before {
    content: "\EAB5";
}

.abw:before {
    content: "\EAB6";
}

.abx:before {
    content: "\EAB7";
}

.aby:before {
    content: "\EAB8";
}

.abz:before {
    content: "\EAB9";
}

.aca:before {
    content: "\EABA";
}

.acb:before {
    content: "\EABB";
}

.acc:before {
    content: "\EABC";
}

.acd:before {
    content: "\EABD";
}

.ace:before {
    content: "\EABE";
}

.acf:before {
    content: "\EABF";
}

.acg:before {
    content: "\EAC0";
}

.ach:before {
    content: "\EAC1";
}

.aci:before {
    content: "\EAC2";
}

.acj:before {
    content: "\EAC3";
}

.ack:before {
    content: "\EAC4";
}

.acl:before {
    content: "\EAC5";
}

.acm:before {
    content: "\EAC6";
}

.acn:before {
    content: "\EAC7";
}

.aco:before {
    content: "\EAC8";
}

.acp:before {
    content: "\EAC9";
}

.acq:before {
    content: "\EACA";
}

.acr:before {
    content: "\EACB";
}

.acs:before {
    content: "\EACC";
}

.act:before {
    content: "\EACD";
}

.acu:before {
    content: "\EACE";
}

.acv:before {
    content: "\EACF";
}

.acw:before {
    content: "\EAD0";
}

.acx:before {
    content: "\EAD1";
}

.acy:before {
    content: "\EAD2";
}

.acz:before {
    content: "\EAD3";
}

.ada:before {
    content: "\EAD4";
}

.adb:before {
    content: "\EAD5";
}

.adc:before {
    content: "\EAD6";
}

.add:before {
    content: "\EAD7";
}

.ade:before {
    content: "\EAD8";
}

.adf:before {
    content: "\EAD9";
}

.adg:before {
    content: "\EADA";
}

.adh:before {
    content: "\EADB";
}

.adi:before {
    content: "\EADC";
}

.adj:before {
    content: "\EADD";
}

.adk:before {
    content: "\EADE";
}

.adl:before {
    content: "\EADF";
}

.adm:before {
    content: "\EAE0";
}

.adn:before {
    content: "\EAE1";
}

.ado:before {
    content: "\EAE2";
}

.adp:before {
    content: "\EAE3";
}

.adq:before {
    content: "\EAE4";
}

.adr:before {
    content: "\EAE5";
}

.ads:before {
    content: "\EAE6";
}

.adt:before {
    content: "\EAE7";
}

.adu:before {
    content: "\EAE8";
}

.adv:before {
    content: "\EAE9";
}

.adw:before {
    content: "\EAEA";
}

.adx:before {
    content: "\EAEB";
}

.ady:before {
    content: "\EAEC";
}

.adz:before {
    content: "\EAED";
}

.aea:before {
    content: "\EAEE";
}

.aeb:before {
    content: "\EAEF";
}

.aec:before {
    content: "\EAF0";
}

.aed:before {
    content: "\EAF1";
}

.aee:before {
    content: "\EAF2";
}

.aef:before {
    content: "\EAF3";
}

.aeg:before {
    content: "\EAF4";
}

.aeh:before {
    content: "\EAF5";
}

.aei:before {
    content: "\EAF6";
}

.aej:before {
    content: "\EAF7";
}

.aek:before {
    content: "\EAF8";
}

.ael:before {
    content: "\EAF9";
}

.aem:before {
    content: "\EAFA";
}

.aen:before {
    content: "\EAFB";
}

.aeo:before {
    content: "\EAFC";
}

.aep:before {
    content: "\EAFD";
}

.aeq:before {
    content: "\EAFE";
}

.aer:before {
    content: "\EAFF";
}

.aes:before {
    content: "\EB00";
}

.aet:before {
    content: "\EB01";
}

.aeu:before {
    content: "\EB02";
}

.aev:before {
    content: "\EB03";
}

.aew:before {
    content: "\EB04";
}

.aex:before {
    content: "\EB05";
}

.aey:before {
    content: "\EB06";
}

.aez:before {
    content: "\EB07";
}

.afa:before {
    content: "\EB08";
}

.afb:before {
    content: "\EB09";
}

.afc:before {
    content: "\EB0A";
}

.afd:before {
    content: "\EB0B";
}

.afe:before {
    content: "\EB0C";
}

.aff:before {
    content: "\EB0D";
}

.afg:before {
    content: "\EB0E";
}

.afh:before {
    content: "\EB0F";
}

.afi:before {
    content: "\EB10";
}

.afj:before {
    content: "\EB11";
}

.afk:before {
    content: "\EB12";
}

.afl:before {
    content: "\EB13";
}

.afm:before {
    content: "\EB14";
}

.afn:before {
    content: "\EB15";
}

.afo:before {
    content: "\EB16";
}

.afp:before {
    content: "\EB17";
}

.afq:before {
    content: "\EB18";
}

.afr:before {
    content: "\EB19";
}

.afs:before {
    content: "\EB1A";
}

.aft:before {
    content: "\EB1B";
}

.afu:before {
    content: "\EB1C";
}

.afv:before {
    content: "\EB1D";
}

.afw:before {
    content: "\EB1E";
}

.afx:before {
    content: "\EB1F";
}

.afy:before {
    content: "\EB20";
}

.afz:before {
    content: "\EB21";
}

.aga:before {
    content: "\EB22";
}

.agb:before {
    content: "\EB23";
}

.agc:before {
    content: "\EB24";
}

.agd:before {
    content: "\EB25";
}

.age:before {
    content: "\EB26";
}

.agf:before {
    content: "\EB27";
}

.agg:before {
    content: "\EB28";
}

.agh:before {
    content: "\EB29";
}

.agi:before {
    content: "\EB2A";
}

.agj:before {
    content: "\EB2B";
}

.agk:before {
    content: "\EB2C";
}

.agl:before {
    content: "\EB2D";
}

.agm:before {
    content: "\EB2E";
}

.agn:before {
    content: "\EB2F";
}

.ago:before {
    content: "\EB30";
}

.agp:before {
    content: "\EB31";
}

.agq:before {
    content: "\EB32";
}

.agr:before {
    content: "\EB33";
}

.ags:before {
    content: "\EB34";
}

.agt:before {
    content: "\EB35";
}

.agu:before {
    content: "\EB36";
}

.agv:before {
    content: "\EB37";
}

.agw:before {
    content: "\EB38";
}

.agx:before {
    content: "\EB39";
}

.agy:before {
    content: "\EB3A";
}

.agz:before {
    content: "\EB3B";
}

.aha:before {
    content: "\EB3C";
}

.ahb:before {
    content: "\EB3D";
}

.ahc:before {
    content: "\EB3E";
}

.ahd:before {
    content: "\EB3F";
}

.ahe:before {
    content: "\EB40";
}

.ahf:before {
    content: "\EB41";
}

.ahg:before {
    content: "\EB42";
}

.ahh:before {
    content: "\EB43";
}

.ahi:before {
    content: "\EB44";
}

.ahj:before {
    content: "\EB45";
}

.ahk:before {
    content: "\EB46";
}

.ahl:before {
    content: "\EB47";
}

.ahm:before {
    content: "\EB48";
}

.ahn:before {
    content: "\EB49";
}

.aho:before {
    content: "\EB4A";
}

.ahp:before {
    content: "\EB4B";
}

.ahq:before {
    content: "\EB4C";
}

.ahr:before {
    content: "\EB4D";
}

.ahs:before {
    content: "\EB4E";
}

.aht:before {
    content: "\EB4F";
}

.ahu:before {
    content: "\EB50";
}

.ahv:before {
    content: "\EB51";
}

.ahw:before {
    content: "\EB52";
}

.ahx:before {
    content: "\EB53";
}

.ahy:before {
    content: "\EB54";
}

.ahz:before {
    content: "\EB55";
}

.aia:before {
    content: "\EB56";
}

.aib:before {
    content: "\EB57";
}

.aic:before {
    content: "\EB58";
}

.aid:before {
    content: "\EB59";
}

.aie:before {
    content: "\EB5A";
}

.aif:before {
    content: "\EB5B";
}

.aig:before {
    content: "\EB5C";
}

.aih:before {
    content: "\EB5D";
}

.aii:before {
    content: "\EB5E";
}

.aij:before {
    content: "\EB5F";
}

.aik:before {
    content: "\EB60";
}

.ail:before {
    content: "\EB61";
}

.aim:before {
    content: "\EB62";
}

.ain:before {
    content: "\EB63";
}

.aio:before {
    content: "\EB64";
}

.aip:before {
    content: "\EB65";
}

.aiq:before {
    content: "\EB66";
}

.air:before {
    content: "\EB67";
}

.ais:before {
    content: "\EB68";
}

.ait:before {
    content: "\EB69";
}

.aiu:before {
    content: "\EB6A";
}

.aiv:before {
    content: "\EB6B";
}

.aiw:before {
    content: "\EB6C";
}

.aix:before {
    content: "\EB6D";
}

.aiy:before {
    content: "\EB6E";
}

.aiz:before {
    content: "\EB6F";
}

.aja:before {
    content: "\EB70";
}

.ajb:before {
    content: "\EB71";
}

.ajc:before {
    content: "\EB72";
}

.ajd:before {
    content: "\EB73";
}

.aje:before {
    content: "\EB74";
}

.ajf:before {
    content: "\EB75";
}

.ajg:before {
    content: "\EB76";
}

.ajh:before {
    content: "\EB77";
}

.aji:before {
    content: "\EB78";
}

.ajj:before {
    content: "\EB79";
}

.ajk:before {
    content: "\EB7A";
}

.ajl:before {
    content: "\EB7B";
}

.ajm:before {
    content: "\EB7C";
}

.ajn:before {
    content: "\EB7D";
}

.ajo:before {
    content: "\EB7E";
}

.ajp:before {
    content: "\EB7F";
}

.ajq:before {
    content: "\EB80";
}

.ajr:before {
    content: "\EB81";
}

.ajs:before {
    content: "\EB82";
}

.ajt:before {
    content: "\EB83";
}

.aju:before {
    content: "\EB84";
}

.ajv:before {
    content: "\EB85";
}

.ajw:before {
    content: "\EB86";
}

.ajx:before {
    content: "\EB87";
}

.ajy:before {
    content: "\EB88";
}

.ajz:before {
    content: "\EB89";
}

.aka:before {
    content: "\EB8A";
}

.akb:before {
    content: "\EB8B";
}

.akc:before {
    content: "\EB8C";
}

.akd:before {
    content: "\EB8D";
}

.ake:before {
    content: "\EB8E";
}

.akf:before {
    content: "\EB8F";
}

.akg:before {
    content: "\EB90";
}

.akh:before {
    content: "\EB91";
}

.aki:before {
    content: "\EB92";
}

.akj:before {
    content: "\EB93";
}

.akk:before {
    content: "\EB94";
}

.akl:before {
    content: "\EB95";
}

.akm:before {
    content: "\EB96";
}

.akn:before {
    content: "\EB97";
}

.ako:before {
    content: "\EB98";
}

.akp:before {
    content: "\EB99";
}

.akq:before {
    content: "\EB9A";
}

.akr:before {
    content: "\EB9B";
}

.aks:before {
    content: "\EB9C";
}

.akt:before {
    content: "\EB9D";
}

.aku,
.aku:hover,
.aku:focus {
    color: inherit;
    text-decoration: inherit;
}

.akv {
    position: relative !important;
}

.akw {
    position: absolute !important;
}

.akx {
    position: fixed !important;
}

.aky {
    width: 25% !important;
}

.akz {
    width: 50% !important;
}

.ala {
    width: 75% !important;
}

.alb {
    width: 100% !important;
}

.alc {
    margin: 0 !important;
}

.ald {
    margin-top: 0 !important;
}

.ale {
    margin-right: 0 !important;
}

.alf {
    margin-bottom: 0 !important;
}

.alg {
    margin-left: 0 !important;
}

.alh {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.ali {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.alj {
    margin: 20px !important;
}

.alk {
    margin-top: 20px !important;
}

.all {
    margin-right: 20px !important;
}

.alm {
    margin-bottom: 20px !important;
}

.aln {
    margin-left: 20px !important;
}

.alo {
    margin-right: 20px !important;
    margin-left: 20px !important;
}

.alp {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.alq {
    margin-right: auto !important;
    margin-left: auto !important;
}

.alr {
    margin: 30px !important;
}

.als {
    margin-top: 30px !important;
}

.alt {
    margin-right: 30px !important;
}

.alu {
    margin-bottom: 0px !important;
}

.alv {
    margin-left: 30px !important;
}

.alw {
    margin-right: 30px !important;
    margin-left: 30px !important;
}

.alx {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.aly {
    margin: 60px !important;
}

.alz {
    margin-top: 60px !important;
}

.ama {
    margin-right: 60px !important;
}

.amb {
    margin-bottom: 60px !important;
}

.amc {
    margin-left: 60px !important;
}

.amd {
    margin-right: 60px !important;
    margin-left: 60px !important;
}

.ame {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

.amf {
    padding: 0 !important;
}

.amg {
    padding-top: 0 !important;
}

.amh {
    padding-right: 0 !important;
}

.ami {
    padding-bottom: 0 !important;
}

.amj {
    padding-left: 0 !important;
}

.p-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.amk {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.aml {
    padding: 20px !important;
}

.amm {
    padding-top: 20px !important;
}

.amn {
    padding-right: 20px !important;
}

.amo {
    padding-bottom: 20px !important;
}

.amp {
    padding-left: 20px !important;
}

.amq {
    padding-right: 20px !important;
    padding-left: 20px !important;
}

.amr {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.ams {
    padding: 30px !important;
}

.amt {
    margin-top: 130px;
    scroll-margin-top: 140px;
}

.amu {
    padding-right: 30px !important;
}

.amv {
    padding-bottom: 30px !important;
}

.amw {
    padding-left: 30px !important;
}

.amx {
    padding-right: 30px !important;
    padding-left: 30px !important;
}

.amy {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.amz {
    padding: 60px !important;
}

.ana {
    padding-top: 60px !important;
}

.anb {
    padding-right: 60px !important;
}

.anc {
    padding-bottom: 60px !important;
}

.and {
    padding-left: 60px !important;
}

.ane {
    padding-right: 60px !important;
    padding-left: 60px !important;
}

.anf {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}

.ang {
    padding-top: 50px;
}

.navbar-nav > li > a > .fo {
    margin-left: 5px;
}

.anh {
    float: left;
    margin-right: 5px;
}

.pa .ani,
.pa .ani:hover,
.pa .ani:focus {
    background-color: #d3e0e9;
}

.pc .ani,
.pc .ani:hover,
.pc .ani:focus {
    color: #fff;
    background-color: #1c73a5;
}

.anj .or {
    padding-left: 5px;
    padding-right: 5px;
}

@media (min-width: 768px) {
    .anj {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.ank {
    border: 0;
    box-shadow: none;
}

.pc .ank {
    background-color: #4da6d7;
}

.pc .ank:focus {
    color: #3097d1;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(255, 255, 255, 0.5);
}

.navbar-nav > .active > a,
.navbar-nav > .active > a:hover,
.navbar-nav > .active > a:focus {
    font-weight: inherit;
}

.anl,
.anl:active,
.anl:hover,
.anl:focus,
.anl:hover:active,
.anl:active:focus {
    background: transparent;
    outline: none !important;
    box-shadow: none !important;
}

.anl img {
    height: 35px;
    /*width: 35px;*/
}

.anm > li {
    float: left;
}

.anm > li > a {
    display: inline-block;
    padding: 10px 0;
    font-weight: 300;
    color: inherit;
    border-bottom: 4px solid transparent;
}

.anm > li > a:hover,
.anm > li > a:focus {
    color: #3097d1;
    background-color: transparent;
}

.anm > li + li {
    margin-left: 30px;
}

.anm > li.active > a,
.anm > li.active > a:hover,
.anm > li.active > a:focus {
    font-weight: 500;
    color: #3097d1;
    border-bottom-color: #3097d1;
}

.anm.om > li + li {
    margin-left: 0;
}

.anm.om > li > a {
    margin-bottom: 0;
}

.anm.nav-stacked > li {
    float: none;
    margin-left: 0;
}

.anm.nav-stacked > li + li {
    margin-top: 0;
}

.anm.nav-stacked > li.active > a,
.anm.nav-stacked > li.active > a:hover,
.anm.nav-stacked > li.active > a:focus {
    border-left-color: #3097d1;
}

.anm.nav-stacked > li > a {
    display: block;
    padding: 5px 10px 5px 30px;
    border-bottom: 0;
    border-left: 4px solid transparent;
}

.anm.nav-stacked > li > a:hover,
.anm.nav-stacked > li > a:focus {
    border-left: 4px solid;
}

.anm.nav-stacked .ann {
    padding-left: 34px;
}

.anm.nav-stacked .nav-divider {
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.2);
    margin-left: 34px;
    margin-top: 20px;
    margin-bottom: 25px;
}

img[data-action="zoom"] {
    cursor: pointer;
    /*cursor: -webkit-zoom-in;*/
    /*cursor: -moz-zoom-in;*/
}

.zoom-img,
.zoom-img-wrap {
    position: relative;
    z-index: 1080;
    transition: all 300ms;
}

img.zoom-img {
    cursor: pointer;
    /*cursor: -webkit-zoom-out;*/
    /*cursor: -moz-zoom-out;*/
}

.zoom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1079;
    background: #ffffff;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms;
}

.zoom-overlay-open .zoom-overlay {
    filter: "alpha(opacity=100)";
    opacity: 1;
}

.zoom-overlay-open,
.zoom-overlay-transitioning {
    cursor: default;
}

.qf,
.qg {
    overflow: visible;
}

.ano {
    list-style: none;
    padding: 0;
}

.anp {
    display: inline-block;
    width: 32px;
    margin-right: 5px;
    vertical-align: top;
}

.anp:last-child {
    margin-right: 0;
}

.anp img {
    width: 100%;
}

.anq {
    position: fixed;
    top: 65px;
    left: 15px;
    right: 15px;
    z-index: 1090;
}

.anq.anr {
    position: relative;
    top: auto;
    right: auto;
    z-index: auto;
}

.anq .alert {
    -webkit-animation: slide-from-top 1000ms cubic-bezier(0.2, 0.7, 0.5, 1);
    animation: slide-from-top 1000ms cubic-bezier(0.2, 0.7, 0.5, 1);
    margin-bottom: 10px;
}

.anq .ps .close,
.anq .alert-dismissible .close {
    top: -5px;
}

@media (min-width: 768px) {
    .anq {
        left: auto;
        width: 400px;
    }
}

@-webkit-keyframes slide-from-right {
    0% {
        -webkit-transform: translateX(30%);
        transform: translateX(30%);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slide-from-right {
    0% {
        -webkit-transform: translateX(30%);
        transform: translateX(30%);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes slide-from-top {
    0% {
        -webkit-transform: translateY(-30%);
        transform: translateY(-30%);
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-from-top {
    0% {
        -webkit-transform: translateY(-30%);
        transform: translateY(-30%);
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.ans {
    position: relative;
    display: table;
    width: 100%;
    height: 300px;
    padding-bottom: 48px;
    margin-bottom: 0;
    text-align: center;
    background-size: cover;
    border: 0;
}

.ans .by,
.ans .gb {
    display: table-cell;
    width: 1000000px;
    text-align: center;
    vertical-align: middle;
}

.ans .ant {
    max-width: 300px;
    margin: 0 auto;
}

.ans .qh {
    display: inline-block;
    width: 80px;
    margin-right: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.anu {
    font-size: 16px;
    color: #fff;
}

.anv {
    margin-top: 20px;
    color: #fff;
}

@media (min-width: 480px) {
    .ans {
        height: 400px;
    }
}

@media (min-width: 768px) {
    .ans {
        height: 500px;
    }

    .ans .qh {
        width: 110px;
    }
}

.anw {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    line-height: 0;
}

.anw .nav {
    display: inline-block;
    border-bottom: 0;
}

.anw .nav > li > a {
    padding: 0 10px 18px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
}

.anw .ol > li > a {
    transition: color 0.2s ease-in-out;
}

.anw .ol > li > a:focus,
.anw .ol > li > a:hover,
.anw .nav > li.active > a {
    color: #fff;
    background: transparent;
    border-color: transparent;
}

.anw .active:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    margin-left: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f5f8fa;
}

@media (min-width: 768px) {
    .anw .nav > li > a {
        padding-bottom: 23px;
    }
}

.anx .qf.b {
    margin-top: 0;
}

.anx .any {
    width: 100%;
}

.anx .anz,
.anx .any {
    margin: 20px 0 25px;
}

.anx .any img,
.anx .anz {
    max-width: 100%;
}

.anx .qh {
    width: 42px;
    margin-right: 5px;
}

.anx .qg .qo .qh {
    width: 40px;
}

.anx .qo .qh {
    display: none;
}

.anx .qh {
    border-radius: 100%;
}

.anx .qf .dp a {
    color: #9da9b0;
    font-weight: 700;
}

.anx .aoa {
    margin-top: 5px;
}

.anx .qn > h5 {
    margin-bottom: 0;
    margin-top: 5px;
}

@media (min-width: 768px) {
    .anx .qo .qh {
        display: block;
    }

    .anx .qh {
        width: 60px;
    }
}

.aob .qh {
    width: 42px;
    margin-right: 5px;
}

.aob .aoc {
    position: relative;
    padding: 10px 15px;
    background-color: #f5f8fa;
    border-radius: 9px;
}

.aob .aoc + .aoc {
    margin-top: 5px;
}

.aob .aoc:first-child:before {
    position: absolute;
    top: 16px;
    left: -8px;
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #f5f8fa;
}

.aob .aod a {
    font-weight: 400;
}

.aob .aoe .aoc {
    color: #fff;
    background-color: #3097d1;
}

.aob .aoe .aoc:first-child:before {
    left: auto;
    right: -8px;
    margin-left: 0;
    margin-right: 2px;
    border-right: 0;
    border-left: 6px solid #3097d1;
}

.aob .aoe .qh {
    margin-left: 5px;
    margin-right: 0;
}

.cj .b {
    margin-top: 0;
    padding: 15px;
}

.cj .qh {
    width: 42px;
    margin-right: 5px;
    border-radius: 100%;
}

.cj .aof {
    color: #536570;
}

.cj .qg p:last-child {
    margin-bottom: 0;
}

.cj .cg.eg {
    margin-top: 7px;
}

.modal-body .cj {
    margin-bottom: 5px;
}

.modal-body .cj .b {
    border-left: 0;
    border-right: 0;
}

.modal-body .cj .b:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom: 0;
}

.modal-body .cj .b:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top: 0;
}

.aog .qy {
    margin-bottom: 5px;
}

.aog .qx {
    height: 150px;
    background-size: cover;
}

.aoh {
    max-width: 100px;
    margin-top: -70px;
    margin-bottom: 5px;
    border: 3px solid #fff;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.aoi {
    list-style: none;
    padding: 0;
}

.aoj {
    display: inline-block;
    padding: 0 10px;
    border-right: 1px solid #d4dbe0;
}

.aoj:last-child {
    border-right: 0;
}

.aok a {
    margin-left: 5px;
}

#myCarousel {
    /* height: 240px; */
    margin-bottom: -1px;
    /* background: url(/img/banner2.png); */
    /* margin-top: 20px; */
    /* border-radius: 15px; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 60%;
    position: relative;
    /* background-size: cover; */
    /* width: 715px; */
    scroll-margin-top: 150px;
}

.bet-pick .odd-value {
    text-align: right;
}

.carousel-content {
    /* position: absolute; */
    /* top: 0; */
    /* width: auto; */
    /* height: auto; */
    /* left: auto; */
    /* right: 0; */
    text-align: center;
    padding-top: 30px;
}

.medium-head {
    font-size: 28px;
}

.carousel-desc-img img {
    display: inline-block;
    max-width: 95px;
    margin-right: 15px;
}

.carousel-desc-img .company-payment-details {
    background: #000;
    font-size: 15px;
    width: 200px;
    border-radius: 25px;
    padding: 6px 15px;
    display: inline-block;
}

.company-paybill {
    font-size: 40px;
    line-height: 1;
}

.carousel-desc-img {
    background: rgba(255, 255, 255, 0.95);
    padding: 3px 10px;
    max-width: 600px;
    float: none;
    margin: auto;
    border: 1px solid #ffffff;
}

.carousel-inner > .item {

    color: #fff;
    font-size: 50px;
    font-weight: bold;
}

.block-shadow {
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
}

.banner-carousel .item {
    color: #ffffff;
}

.banner-carousel {
    text-align: center;
}

.item-description {
    font-size: 25px;
    line-height: 1;
    text-align: right;
    text-shadow: 1px 2px 1px #939090;
}

.no-pad-left {
    padding-left: 0;
}

.vague-positioning {
    padding-top: 40px;
}

.item-cash {
    font-size: 43px;
    font-weight: bold;
    text-shadow: 1px 4px 1px #d29c19;
    color: #ffc841;
    text-align: left;
    line-height: 1;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}


.bottom-std-margin-spacing {
    margin-bottom: 15px !important;
}

.errorMessage {
    background: #fffc;
    padding: 10px;
    color: red;
}

/*Logo design*/

.logo-sign {
    border: 4px solid #ffffff80;
    border-radius: 100%;
    width: 54px;
    height: 53px;
    font-size: 30px;
    margin-right: 5px;
    display: inline-block;
    line-height: 45px;
    text-align: center;
    background: #7a2c46;
}

/*.betslip-container {*/
/*    position: sticky !important;*/
/*    !*top: 5px !important;*!*/
/*    background: #253743 !important;*/
/*}*/


.betslip-container-mobile {
    bottom: 0;
    height: 80%;
}

.bet-slip-footer-toggle {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.logo-sign-inner {
    border: 4px solid #7b2d47;
    width: 45px;
    border-radius: 100%;
    display: inherit;
    height: 45px;
    line-height: 40px;
    background: #963d5b;
    /* outline: 2px solid; */
    font-size: 30px;
    box-shadow: inset 0px 1px 12px rgb(0, 0, 0);
    -moz-box-shadow: inset 0px 1px 12px rgb(0, 0, 0);
    -webkit-box-shadow: inset 0px 1px 12px rgb(0, 0, 0);
    text-shadow: 3px -2px 0px #a74664;
    color: #fff;
    font-weight: bold;
    /* transform: rotate(45 deg); */
}

.logo-text {
    font-size: 35px;
    font-weight: 500;
    letter-spacing: -3px;
}

.logo-detail {
    position: absolute;
    bottom: 10px;
    background: linear-gradient(to right, #e8ae20 25%, #e8ae2099 100%);
    color: #421826;
    font-size: 10px;
    padding: 3px 8px;
    line-height: 1;
    border-radius: 1px;
    font-weight: bold;
    width: 45px;
    text-align: center;
    background-color: #e8ae20;
    border: 1px solid #ffffff1a;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    right: 19px;
}

.logo {
    position: relative;
    color: #fff !important;
    /*padding-top: 8px;*/
    padding-top: 4px;
}

.panel {

    border-radius: 0px;
}

.error-404 {
    background: #6e2f44;
}

.top-matches:nth-child(odd) {

}

.top-matches:nth-child(2n) {
    /*background: #253743;*/
    /*background: #16202C;*/
}

.top-matches:nth-child(2n + 1) {

    /* box-sizing: ; */
}

.basic-table tr:nth-child(2n + 1) {
    background: #f9f9f9;
}

.black-bg {

}

.clickable-row:nth-child(2n) {
    background: #f9f9f9;
    /* box-sizing: ; */
}
.to-deskview{
    display: none!important;
}

.password-change {
    background: #FFC428;
    color: #201f1f;
    margin-top: -8px;
    padding: 5px;
    font-weight: normal;
    font-size: 19px;
    text-align: center;
}
.space-bets {
    flex-direction: column !important;
}
.login-mobile{
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.padding-mobile{
    padding-top:12rem;
}
.to-check-change{
    display:flex;
}

.notify-badge {
    background: red;
    border-radius: 10px 0 15px 0;
    color: #fff;
    font-size: 10px;
    margin-top: 5px !important;
    padding: 1px 5px;
    position: absolute;
    right: -10px;
    text-align: center;
    top: -2px;
}
.bloc-icon:hover{
    border-bottom:thin solid #ffc107!important;
}

@media (min-width: 768px){
    /*todo changed mobile display here*/
    .display-ipad-dates{
        display:flex!important;
    }
    .mobile-only{
        display: none;
    }
    .display-ipad-remove-id{
        display:none!important;
    }
}

@media (min-width: 768px){
    .compt-teams{
        display:block!important;
    }

    .slip-body >tr{
        line-height:11.5px;
        white-space: nowrap;
    }
    .virtual-game-container{
        width:100%;
    }
    .virtual-width {
        width: 16.5%;
        /* height: 25rem; */
        max-height: 25rem;
    }
    .virtual-game-container div img {
        width: 100%;
        max-height: 17rem!important;
        min-height: 16rem;
        height: 17rem;
    }
    .font-slip{
        font-size:11px;
    }
    .mobile-width-full{
        width:100%;
    }
    .sidebar-mobile-column{
        display:flex;
        flex-direction:column;
    }
    .mobile-betslip{
        background:#1f2f38;
        height:100vh;
        z-index: 10000;
    }
    .footer-mobile{
        width: 99%;
        padding:0.1rem 1.5rem;
        margin-top: 0.2rem;
        border-radius: 3rem;
        margin-bottom: 1rem;
        background: none;
    }
    .c-btn-group> .c-btn{
        height: 32px!important;
        line-height: 30px!important;
        font-weight: 400;
        min-width:40px;

    }
    /*.matches .top-matches button{*/
    /*    width:33%!important;*/
    /*}*/

    .margin-l-mobile{
        margin-left: 0.2rem;
    }
    .to-check-change:first-child:empty{
        display: none!important;
    }
    .login-color{
        color: aqua;
    }
    .login-button{
        /*border-radius: 1rem!important;*/
        /*line-height: 2;*/
        /*height: 2.3rem!important;*/
    }


    .font-btn{
        /*width: max-content!important;*/
    }
    .top-nav{
        padding-bottom: 0px;
    }
    .top-nav-login{
        padding-bottom: 0px;
    }
    /*mobile header slider*/
    .menu-table {
        max-width: 768px !important;
        overflow: hidden !important;
    }

    .menu-table .menu-t {
        min-width: 6rem;
        opacity: 0.7;
    }


    table.menu-table tr {
        white-space: nowrap;
        height: 6rem;
    }

    table tr td .menu-t {
        display: inline-block;
        padding-right: 4px;
        height: 20px !important;
        margin-top: 5px !important;
        font-size: 13px;
    }

    .tr-style{
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .active {
        /*background-color: #16202C !important;*/
        border-bottom: thin solid yellow;

    }
    .active > p{
        color: yellow;
    }



    .mobile-top{
        margin-top:15rem;
    }
    .menu-t .menu-img {
        width: 100% !important;
        height: 2.8rem !important;
        text-align: center;
        padding: 5px 0px;
        position:relative;
    }

    /*ends here*/
    .w-change1{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }


    .menu-t a {
    }

    .menu-t img {
        height: 23px!important;
        margin-top: -6px!important;
    }
    .menu-t.nare-league img {
        height: 40px!important;
        margin-top: -13px!important;
        width: 38px!important;
    }
    .menu-t.nare-games img{
        height: 29px!important;
        margin-top: -7px!important;
    }
    .menu-t div.inner-div {
        /* background-color: #2D4352; */
        color: #FFFFFF;
        border-radius: 4px;
        padding: 0px 5px;
        border: none;
        margin-bottom: -3px;
    }

    .more-sports {
        padding: 2px 5px!important;
        font-weight: 700!important;
        font-size: 13px;
    }


    .matches .top-matches{
        padding-top: 0px!important;
        padding-bottom: 0px !important;
        border: none;
    }
    .icon-size{
        font-size: 2rem;
    }
    .top-matches .side:first-child{
        background: none!important;
        color: #fff;
    }

    .change-date1{
        width: 100%!important;
    }
    .container-size {
        width: 100%!important;
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
        margin-right: 0.5rem!important;
        /*margin-left: 1.5rem!important;*/

    }


    .to-none{
        display: none;
    }
    .c-btn-group {
        width: 100%;
        margin-left: 0 !important;
    }
    .top-matches .side{
        height: 2rem!important  ;
    }

    .to-profile-check{
        display:flex;
    }
    .to-profile-check:empty{
        display:none;
    }
    .to-tabview:empty{
        display:none!important;
    }
    .markets-container-data-check checking:empty{
        display:none;
    }
    .to-deskview:empty{
        display:none;
    }
    .size-info {
        width: 50%!important;
    }




    .to-flex{
        flex-direction: column;
    }

}
.to-block{
    display: flex!important;
}
.matches .top-matches button {
    height: 40px;
}
.match-detail-container {
    width: 100%!important;
}
@media (max-width:514px){
    .image-size {
        width: 58%;
    }
    .resize-mobile{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 0.2rem;
        padding-left: 0;

    }
    .mobile-width{
        width: 100%;
    }
    .font-btn{
        font-size: 14px;
    }
    .change-size{
        width: 100%;
    }
    .menu-control{
        width: 100%;
        display: flex;
        justify-content: space-between
    }
}
@media (min-width: 401px) and (max-width: 416px){
    .top-matches .side{
        width: 3rem;
    }
}

@media (max-width: 400px){

    .top-matches .side{
        height: 1.5rem!important  ;
    }
    .matches .top-matches {
        padding-right: 0rem!important;
        padding-left: 0rem!important;
    }
    .container-size {
        width: 100%!important;
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
        margin-right: 0.5rem!important;
        /* margin-left: 1.5rem!important; */
    }
}

@media (min-width: 768px) {
    hr{
        margin-top:0px;
        margin-bottom: 0px;
        margin-left: 0.5rem;
    }
    .app-sidebar {
        display: block !important;
    }

    .off-canvas {
        top: 0 !important;
        margin-top: 70px;
    }


    .password-change {

        font-size: 15px;
    }

    .hidden-mobile {
        display: none;
    }



    .thedate {
        display: none;
    }


}

@media (min-width: 767px) {
    .mobile-only{
        display: flex;
    }

    .visible-mobile {
        display: none;
    }
}

[custom] {
    cursor: pointer;
}

.jp-mobi button.odds {
    border-radius: 2px;
    margin-left: 1px;
}

.empty-list {
    padding: 10px;
}

.nopadding {
    padding: 0 !important;
}


.top-matches.live-match button {
    width: 49% !important;
    margin-left: 2px;
    border: 1px solid #ECECEC !important;
    margin-top: 3px;
    margin-bottom: 2px;
    align-content: center;

}

.top-matches.live-match button.market-butt {
    font-size: 12px;
    text-transform: none;
}


.live-match .match-header {
    /*background-image: url("../img/field.jpeg");*/
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    width: 100%;
    background-position: center center;
}

.live-placeholder-game {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.ll .match-d h4, .ll .comp-detail h4 {
    font-size: 14px;
    padding: 2px;
    margin: 5px;
    color: #FFC428;
    text-align: center;
}

.spacerlive {
    height: 5px;
}

#live_matches {
    background: #edeced;
    padding: 0;
    width: 30%;
    margin-left: 5px;
    margin-right: 10px;
    padding: 3px;
}

#live_matches.full {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
}

#live_matches.full .one-live {
    padding-bottom: 5px;
}

.one-live-head {
    background-color: #C79797;
    padding-left: 10px;
}

.one-live-head .live-competition {
    color: #fff;
    font-weight: bold;
    opacity: 0.86;
}


#live_matches.top-matches a {
    color: #6C6A6A;
    text-decoration: none;
}


#live_matches.top-matches a:hover {
    text-decoration: none;
    cursor: pointer;
}

.live-header {
    color: #fff;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 2px;
    background: red;
    border-radius: 2px;
    margin-right: 10px;
}

h4.live-header {
    background-color: transparent;
}

.live-header b {
    color: #004d00;
    background: #e3e3e3;
    padding: 5px;
    border-radius: 2px;
    font-size: 12px;
}

#live_matches .cmp-d {
    font-size: 13px;
    display: block;
}

li a.live-game.active, .navbar-nav li a.live-game.active, li a.live-game {
    color: #fff;
    background-color: red;
    line-height: 30px;
}

li a.live-game.active:hover, .navbar-nav li a.live-game.active:hover, li a.live-game:hover {
    color: #fff;
    background-color: #C00000 !important;
}


#live_matches a.active {
    background: #f4f4c7;
}


.minus-odds {
    color: #0d0309 !important;
    font-weight: bold;
}


.plus-odds {
    color: #ffffff;
    font-weight: bold;
}

.neut-odds {
    color: #6d676b;
    font-weight: bold;

}

.minus-odds-white {
    color: #FF0000 !important;;
    font-weight: bold;
}

.plus-odds-white {
    color: #32CD32;
    font-weight: bold;
}

.top-matches div.gametime {
    float: right;
    color: #a3a300;
    font-size: 12px;
}

.gametime {
    color: #a3a300;
    font-size: 12px;
}
.c-resize{
    min-width:25%!important
}

.home-team.disabled, .disabled, .draw.disabled, .away_team.disabled, .market-butt.disabled {
    background-color: #999999 !important;
    color: #000 !important;
}

.live-stats {
    background-color: #005130;;
    opacity: 0.5;
    margin-top: 5px;
    filter: alpha(opacity=50); /* For IE8 and earlier */
}

.live-stats:hover {
    opacity: 0.8;
    margin-top: 5px;
    filter: alpha(opacity=70); /* For IE8 and earlier */
}

.live-stats div {
    line-height: 20px;
    padding-left: 5px !important;
    padding-top: 1px !important;
    padding-right: 5px !important;
    font-size: 12px;
}

.red-card {
    color: red;
    opacity: 1.0 !important;
}

.yellow-card {
    color: yellow;
    opacity: 1.0 !important;
}


.center {
    text-align: center;
    display: block;
}

.no-data {
    margin: 0px;
    margin-top: 5px;
    padding-top: 10px;
    padding-left: 10px !important;
    line-height: 30px;
    background-color: #6d676b;
    color: #cecece;
}

.panel-header.liveg {
    height: 40px;
}

.panel-header.liveg .livelink a {
    color: #fff;
    opacity: 0.9;
    font-size: 11px;
    background: red;
    padding: 5px;
    border-radius: 2px;
    float: right;
}

.jp-header-text {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
}

.jp-header-text .jp-header-amount {
    padding: 5px 0;
    font-weight: bold;
    color: #FFC428;
}

.jp-header-text .jp-header-top {
    padding: 5px 0 0;
}

.btn-square {
    border-radius: 0px !important;
    border: unset !important;
}

.deposit-input::placeholder {
    color: gray;
}

.deposit-input {
    border: 2px solid gray;
}

::placeholder{
    color:#000;
    opacity:1;
}

.input-field {
    height: 40px!important;
    line-height: 40px;
    border: none;
}

.deposit-withdraw-button {
    height: 40px;
    line-height: 20px;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    background: var(--CrashKali-button-login);
    color: var(--dark);
}

.input-square {
    border-radius: 0 !important;
}

.deposit-funds-icon {
    height: 60px;
    width: 250px !important;
    max-width: unset !important;
}

.right {
    float: right !important;
}

.jackpot-amount {
    position: relative;
    transform: translate(-50%, -50%);
    left: 50%;
    letter-spacing: 5px;
    -webkit-background-clip: text;
    display: inline-block;
    width: auto;
    font-weight: 700;
    font-size: 60px;
    color: #FFE26C !important;
    line-height: 38px;
    text-shadow: 0px 0px 20px rgb(0 0 0 / 94%), 0px 2px 0px #ea9203;
}


/*todo check height of right betslip*/


.to-profile-check:empty{
    display:none;
}
.betslip-container::-webkit-scrollbar {
    display: none;
}

.betslip-container-mobile {
    /*bottom: 0;*/
    /*height: 100%;*/
}

.bet-slip-footer-toggle {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.background-primary {
    background-color: var(--Crashkali-lite);
    /* background-color: var(--CrashKali-header-bg); */
}

.modal-50w {
    width: 100vw !important;
}

/*.match-category-container{*/
/*    overflow: hidden;*/
/*    white-space: nowrap;*/
/*    padding: 2px;*/
/*}*/

.filters-modal .modal-content {
    background-color: var(--CrashKali-header-bg);
    color: #fff;
}
.market-item:hover {
    background-color: #253743;
    transition: .5s;
    border-radius: 4px;
}

.hover-none:hover {
    background-color: unset !important;
}


.overflow-ellipsis {

}

.match-detail-container {
    width: 21.6% ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4.7%;
    /*max-width: 20% !important;*/
    /*margin-right: 2% !important;*/
}

.odd-button {
    height: 35px !important;
    line-height: 15px;
    width: 45px;
    text-align: center !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #16202c;
}



.c-btn {
    padding: .25em .5em;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    width: 50px;
}

.c-btn-header {
    padding: 0em .5em;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    min-width: 55px;
    color: white;
    text-transform: uppercase;
    /*border-right: 1px solid gray;*/
    font-weight: bold;
    font-size:var(--font-size-small);
    opacity:0.7;
}

.c-btn:hover,
.c-btn:focus {
    cursor: pointer;
    /*background-color: red;*/
    /* color: #fff; */
}

.change-match {

}

.change-date1 {
    width: 27%;
    padding-top: 0px;
    /* width: min-content; */
}

.size-info{
    width:39%;
}
.c-btn-group {
    display: inline-flex;
    /*border: 1px solid red;*/
    overflow-y:hidden;
    overflow: hidden;
    border-radius: 4px;
    margin-left: 2% !important;
}


.c-btn-group > .c-btn {
    border-radius: 0;
    border: none;
    border-right: 1px solid #16202c;

    line-height: 20px;
    /*background-color: black;*/
    /*background: rgb(51, 76, 92);*/
    background: var(--odds-button);
}
/*

*/
.c-btn-group > .c-btn:last-child {
    border-right: none;
}

.c-btn-group--block {
    display: flex;
}

.c-btn-group--block > .c-btn {
    flex-grow: 1;
}

.c-btn-group--vertical-block {
    display: flex;
    flex-direction: column;
}

.c-btn-group--vertical-block > .c-btn {
    flex-grow: 1;
    border: none;
}

.c-btn-group--vertical-block > .c-btn:last-child {
    border-bottom: none;
}

.markets-header-container {
    position: sticky;
    position: -webkit-sticky;
    top: 100px; /* required */
}

.fixed-markets-header {
    top: 134px;
    align-self: center;
    opacity: 1;
    width: 61%;
    left: auto;
    right: auto;
    position: sticky !important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #1c313b;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: var(--CrashKali-header-bg);
    color: white;
    border: none !important;
    line-height: 2;
    border-radius: 5px;
    margin-top: 4px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    line-height:3;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover{
    border-color:transparent!important;
}

.nav-tabs {
    border: none !important;
}

.text-elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.promotion {
    /*width: 96%;*/
    margin-left: 0;
    margin-top: 10px;
    padding: 5px;
    border-radius: 5px;
    font-size: 13px;
    color: white;
    text-align: left;
    box-shadow: 0 4px 8px 0 var(--CrashKali-primary), 0 6px 20px 0 var(--btn-color)!important;
}

.promotion-header {
    padding: 5px;
    font-size: 14px;
}

.promotions-header {
    color: white;
    text-align: center;
    padding: 0px 10px;
}

.promo-instructions {
    text-align: left;
}

.promo-instructions hr {
    border-top: 1px solid #f16a0a;
    color: #f16a0a;
}

.promo-instructions ul {
    font-size: inherit;
    color: inherit;
    list-style: none;
    margin-top: 2px;
}

/* bitrixm */
.b24-widget-button-position-bottom-left {
    left: 2px !important;
}

.b24-widget-button-inner-mask {
    display: none !important;
}

.b24-widget-button-block {
    width: 55px !important;
    height: 55px !important;
}

.steps-title {
    margin-left: 10px;
    display: inline-block;
    margin-top: 2px;
    margin-bottom: 2px;
}

.cashback-table {
    background-color: rgb(31, 47, 56);
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    width: 98%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    text-align: center;
}

.cashback-table {
    background-color: rgb(31, 47, 56);
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    width: 98%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    text-align: center;
}

.cashback-table thead tr {
    background-color: #737980;
    color: #ffffff;
    text-align: center;
}

.cashback-table th,
.cashback-table td {
    padding: 12px 15px;
}

.cashback-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.cashback-table tbody tr:nth-of-type(even) {
    background-color: rgb(31, 47, 56);
}

.cashback-table tbody tr:last-of-type {
    border-bottom: 2px solid;
}

@media screen and (max-width: 600px) {
    .promotion-body img {
        height: 80px;
    }
}

@media screen and (max-width: 992px) {
    .promotion-body img {
        height: 100px;
    }
}

@media screen and (max-width: 1440px) {
    .promotion-body img {
        height: 100px;
    }
}

.casino-category-button {
    border: thin solid rgba(0,0,0,.15);
    border-radius: 25px;
    font-size: 16px;
    font-weight: 700;
    margin-right: 5px;
    padding: 5px 15px;
    white-space: nowrap;
    width: fit-content;
    width: fit-content;
    background: var(--odds-button);
}


.casino-category-button:hover {
    background: #f98f06;
}
.casino-category-button:active{
    background: #f98f06!important;
}
.casino-category-button.active-category{
    background: #f98f06!important;
}

.clearfix:before,
.clearfix:after {
    display: table;

    content: ' ';
}

.clearfix:after {
    clear: both;
}

.page-404 .outer {
    position: absolute;
    top: 0;

    display: table;

    width: 100%;
    height: 100%;
}

.modal-width {
    width: 94%;
}

.page-404 .outer .middle {
    display: table-cell;

    vertical-align: middle;
}

.page-404 .outer .middle .inner {
    width: 300px;
    margin-right: auto;
    margin-left: auto;
}

.card-radius {
    border-radius: 1.1rem;
}

.profile-bg {
    /*background: #253743;*/
    background: var(--CrashKali-primary);
}

.user-style {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
}

.line-between {
    border: 1px solid white;
    height: 50%;
}

.page-404 .outer .middle .inner .inner-circle {
    height: 300px;

    border-radius: 50%;
    background-color: #ffffff;
}

.page-404 .outer .middle .inner .inner-circle:hover i {
    color: #FCC024 !important;
    background-color: #f5f5f5;
    box-shadow: 0 0 0 15px #FCC024;
}

.page-404 .outer .middle .inner .inner-circle:hover span {
    color: #FCC024;
}

.page-404 .outer .middle .inner .inner-circle i {
    font-size: 5em;
    line-height: 1em;

    float: right;

    width: 1.6em;
    height: 1.6em;
    margin-top: -.7em;
    margin-right: -.5em;
    padding: 20px;

    -webkit-transition: all .4s;
    transition: all .4s;
    text-align: center;

    color: #f5f5f5 !important;
    border-radius: 50%;
    background-color: #FCC024;
    box-shadow: 0 0 0 15px #f0f0f0;
}

.page-404 .outer .middle .inner .inner-circle span {
    font-size: 11em;
    font-weight: 700;
    line-height: 1.2em;

    display: block;

    -webkit-transition: all .4s;
    transition: all .4s;
    text-align: center;

    color: #e0e0e0;
}

.page-404 .outer .middle .inner .inner-status {
    font-size: 20px;

    display: block;

    margin-top: 20px;
    margin-bottom: 5px;

    text-align: center;

    color: #FCC024;
}

.page-404 .outer .middle .inner .inner-detail {
    line-height: 1.4em;

    display: block;

    margin-bottom: 10px;

    text-align: center;

    color: #999999;
}


.mo-fire {
    width: 100px;
    height: auto;
    position: absolute;
    right: auto;
    left: auto;
}

.mo-fire svg {
    width: 100%;
    height: auto;
    position: relative
}

.flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
}

.flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
}

.flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
}

.flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
}

.flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
}

.flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
}

.flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
}

.flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
}

@keyframes flameWobble {
    50% {
        transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
}

@keyframes flamefly {
    0% {
        transform: translate(0) rotate(180deg);
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translate(-20px, -100px) rotate(180deg);
        opacity: 0;
    }
}

.deposit-button {
    height: 40px;
    line-height: 20px;
    padding: 4px;
}

.withdraw-button {
    height: 40px;
    line-height: 20px;
    padding: 4px;
}

/*lmt*/
.sr-bb .srt-base-2 {
    background-color: #0E0E29;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-active {
    background-color: #301739;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
    background-color: #301739;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3 {
    background-color: #301739;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-active {
    background-color: #3c183a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
    background-color: #3c183a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-background {
    background-color: #0E0E29;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-4 {
    background-color: #201537;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-active {
    background-color: #51193d;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
    background-color: #51193d;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-6 {
    background-color: transparent;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.3);
}

.sr-bb .srt-primary-1 {
    background-color: #FD2954;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-active {
    background-color: #fd3e65;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-hoverable:hover {
    background-color: #fd3e65;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
    background-color: #fd5476;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-2 {
    background-color: #fe6987;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-3 {
    background-color: #fd3e65;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-4 {
    background-color: #a02148;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-5 {
    background-color: #a02148;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover {
    background-color: #FD2954;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-6 {
    background-color: #fe839c;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-7 {
    background-color: #FD2954;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8 {
    background-color: #FD2954;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8-is-active-1 {
    background-color: #fd3e65;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
    background-color: #fd3e65;
    color: #ffffff;
    border-color: #0E0E29;
}

.sr-bb .srt-primary-9 {
    background-color: #5a1a3e;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-primary-10 {
    background-color: #ce254e;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-11 {
    background-color: #fd3e65;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-12 {
    background-color: #FD2954;
    color: #ffffff;
    border-color: #ffffff;
}

.sr-bb .srt-primary-13 {
    background-color: #201537;
    color: #ffffff;
    border-color: rgba(253, 41, 84, 0.3);
}

.sr-bb .srt-base-1-primary-1 {
    background-color: transparent;
    color: #FD2954;
    border-color: #FD2954;
}

.sr-bb .srt-base-1-primary-2 {
    background-color: transparent;
    color: #fe6987;
    border-color: #fe6987;
}

.sr-bb .srt-base-1-primary-3 {
    background-color: transparent;
    color: #fd3e65;
    border-color: #fd3e65;
}

.sr-bb .srt-base-1-primary-4 {
    background-color: transparent;
    color: #a02148;
    border-color: #a02148;
}

.sr-bb .srt-base-1-primary-5 {
    background-color: transparent;
    color: #a02148;
    border-color: #a02148;
}

.sr-bb .srt-base-1-primary-6 {
    background-color: transparent;
    color: #fe839c;
    border-color: #fe839c;
}

.sr-bb .srt-base-1-primary-7 {
    background-color: transparent;
    color: #FD2954;
    border-color: #FD2954;
}

.sr-bb .srt-base-1-primary-8 {
    background-color: transparent;
    color: #FD2954;
    border-color: #FD2954;
}

.sr-bb .srt-base-1-primary-9 {
    background-color: transparent;
    color: #5a1a3e;
    border-color: #5a1a3e;
}

.sr-bb .srt-base-1-primary-10 {
    background-color: transparent;
    color: #ce254e;
    border-color: #ce254e;
}

.sr-bb .srt-base-1-primary-11 {
    background-color: transparent;
    color: #fd3e65;
    border-color: #fd3e65;
}

.sr-bb .srt-base-1-primary-13 {
    background-color: #0E0E29;
    color: #201537;
    border-color: rgba(253, 41, 84, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
    background-color: transparent;
    color: #33334f;
    border-color: #33334f;
}

.sr-bb .srt-base-1-neutral-2 {
    background-color: transparent;
    color: #e8e8eb;
    border-color: #e8e8eb;
}

.sr-bb .srt-base-1-neutral-3 {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.12);
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
    background-color: transparent;
    color: #adadb8;
    border-color: #adadb8;
}

.sr-bb .srt-base-1-neutral-5 {
    background-color: transparent;
    color: #c4c4cd;
    border-color: #c4c4cd;
}

.sr-bb .srt-base-1-neutral-6 {
    background-color: transparent;
    color: #4c4c65;
    border-color: #4c4c65;
}

.sr-bb .srt-base-1-neutral-7 {
    background-color: transparent;
    color: #adadb8;
    border-color: #adadb8;
}

.sr-bb .srt-base-1-neutral-8 {
    background-color: transparent;
    color: #33334f;
    border-color: #33334f;
}

.sr-bb .srt-base-1-neutral-9 {
    background-color: transparent;
    color: #222241;
    border-color: #222241;
}

.sr-bb .srt-base-1-neutral-10 {
    background-color: transparent;
    color: #30304d;
    border-color: #30304d;
}

.sr-bb .srt-base-1-neutral-11 {
    background-color: transparent;
    color: #727286;
    border-color: #727286;
}

.sr-bb .srt-base-1-neutral-12 {
    background-color: transparent;
    color: #b9b9c2;
    border-color: #b9b9c2;
}

.sr-bb .srt-base-1-neutral-13 {
    background-color: transparent;
    color: #2c2c49;
    border-color: #2c2c49;
}

.sr-bb .srt-base-1-is-active-primary {
    background-color: rgba(253, 41, 84, 0.12);
    color: #FD2954;
    border-color: #FD2954;
}

.sr-bb .srt-base-1-is-active-home-1 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #0495E3;
    border-color: #0495E3;
}

.sr-bb .srt-base-1-is-active-away-1 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #FD2954;
    border-color: #FD2954;
}

.sr-bb .srt-base-1-is-active-home-2 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #0495E3;
    border-color: #fe839c;
}

.sr-bb .srt-base-1-is-active-away-2 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #FD2954;
    border-color: #fe839c;
}

.sr-bb .srt-base-1-is-active-home-3 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #0495E3;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #FD2954;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #096eaf;
    border-color: #096eaf;
}

.sr-bb .srt-base-1-is-active-away-4 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #b7234b;
    border-color: #b7234b;
}

.sr-bb .srt-base-1-is-active-home-5 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #5cbaed;
    border-color: #5cbaed;
}

.sr-bb .srt-base-1-is-active-away-5 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #fe7490;
    border-color: #fe7490;
}

.sr-bb .srt-base-1-is-active-primary-1 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #FD2954;
    border-color: #FD2954;
}

.sr-bb .srt-base-1-is-active-primary-2 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #fe6987;
    border-color: #fe6987;
}

.sr-bb .srt-base-1-is-active-primary-3 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #fd3e65;
    border-color: #fd3e65;
}

.sr-bb .srt-base-1-is-active-primary-4 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #a02148;
    border-color: #a02148;
}

.sr-bb .srt-base-1-is-active-primary-5 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #a02148;
    border-color: #a02148;
}

.sr-bb .srt-base-1-is-active-primary-6 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #fe839c;
    border-color: #fe839c;
}

.sr-bb .srt-base-1-is-active-primary-7 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #FD2954;
    border-color: #FD2954;
}

.sr-bb .srt-base-1-is-active-primary-8 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #FD2954;
    border-color: #FD2954;
}

.sr-bb .srt-base-1-is-active-primary-9 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #5a1a3e;
    border-color: #5a1a3e;
}

.sr-bb .srt-base-1-is-active-primary-10 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #ce254e;
    border-color: #ce254e;
}

.sr-bb .srt-base-1-is-active-primary-11 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #fd3e65;
    border-color: #fd3e65;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #33334f;
    border-color: #33334f;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #e8e8eb;
    border-color: #e8e8eb;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
    background-color: rgba(253, 41, 84, 0.12);
    color: rgba(255, 255, 255, 0.12);
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #adadb8;
    border-color: #adadb8;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #c4c4cd;
    border-color: #c4c4cd;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #4c4c65;
    border-color: #4c4c65;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #adadb8;
    border-color: #adadb8;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #33334f;
    border-color: #33334f;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #222241;
    border-color: #222241;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #30304d;
    border-color: #30304d;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #727286;
    border-color: #727286;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #b9b9c2;
    border-color: #b9b9c2;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
    background-color: rgba(253, 41, 84, 0.12);
    color: #2c2c49;
    border-color: #2c2c49;
}

.sr-bb .srt-home-1 {
    background-color: #0495E3;
    color: #ffffff;
    border-color: #0495E3;
}

.sr-bb .srt-away-1 {
    background-color: #FD2954;
    color: #ffffff;
    border-color: #FD2954;
}

.sr-bb .srt-home-2 {
    background-color: #0495E3;
    color: #ffffff;
    border-color: #fe839c;
}

.sr-bb .srt-away-2 {
    background-color: #FD2954;
    color: #ffffff;
    border-color: #fe839c;
}

.sr-bb .srt-home-3 {
    background-color: #0495E3;
    color: #ffffff;
    border-color: #0E0E29;
}

.sr-bb .srt-away-3 {
    background-color: #FD2954;
    color: #ffffff;
    border-color: #0E0E29;
}

.sr-bb .srt-home-4 {
    background-color: #096eaf;
    color: #ffffff;
    border-color: #096eaf;
}

.sr-bb .srt-away-4 {
    background-color: #b7234b;
    color: #ffffff;
    border-color: #b7234b;
}

.sr-bb .srt-home-5 {
    background-color: #5cbaed;
    color: #ffffff;
    border-color: #5cbaed;
}

.sr-bb .srt-away-5 {
    background-color: #fe7490;
    color: #ffffff;
    border-color: #fe7490;
}

.sr-bb .srt-home-6 {
    background-color: rgba(4, 149, 227, 0.2);
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-away-6 {
    background-color: rgba(253, 41, 84, 0.2);
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-home-6-is-hoverable:hover {
    background-color: rgba(4, 149, 227, 0.2);
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-away-6-is-hoverable:hover {
    background-color: rgba(253, 41, 84, 0.2);
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-1 {
    background-color: #33334f;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-2 {
    background-color: #e8e8eb;
    color: #000000;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-3 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-4 {
    background-color: #adadb8;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-5 {
    background-color: #c4c4cd;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-6 {
    background-color: #4c4c65;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-7 {
    background-color: #adadb8;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-8 {
    background-color: #33334f;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-9 {
    background-color: #222241;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-10 {
    background-color: #30304d;
    color: rgba(255, 255, 255, 0.82);
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-11 {
    background-color: #727286;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-12 {
    background-color: #b9b9c2;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-13 {
    background-color: #2c2c49;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-win {
    background-color: #0495E3;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-draw {
    background-color: rgba(255, 255, 255, 0.4);
    color: #000000;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-lose {
    background-color: #FD2954;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-text-secondary {
    opacity: 0.8;
    -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-text-disabled {
    opacity: 0.6;
}

.sr-bb .srt-text-tertiary {
    opacity: 0.7;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
    opacity: 0.8;
    -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
    opacity: 0.6;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
    opacity: 0.7;
}

.sr-bb .srt-icon {
    opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
    opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23), 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.26), 0px 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
    box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
    box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
    box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
    box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
    box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
    fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
    stroke: #2579ad;
}

.sr-bb .srt-fill-green-card {
    fill: #2cbd00;
}

.sr-bb .srt-stroke-green-card {
    stroke: #2cbd00;
}

.sr-bb .srt-fill-soccer-yellow-card {
    fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
    stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
    stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
    stroke: #4fbe30;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
    fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
    fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
    fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
    stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
    fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
    stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
    fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
    stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
    fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
    stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
    fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
    stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
    fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
    stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
    fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
    stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
    fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
    stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
    fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
    stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
    fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
    stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
    background-color: #f5a623;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
    background-color: rgba(245, 166, 35, 0.3);
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-penalty {
    background-color: #f4a621;
    color: #ffffff;
    border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
    background-color: #ac182e;
    color: #ffffff;
    border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
    background-color: #ac182e;
    color: #ffffff;
    border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
    background-color: #4b90de;
    color: #ffffff;
    border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
    background-color: #f5a623;
    color: #ffffff;
    border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
    fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
    stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
    fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
    stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
    fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
    stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
    background-color: #009900;
    color: #ffffff;
    border-color: #009900;
}

.sr-bb .srt-mlb-run-2 {
    background-color: #ffffff;
    color: #009900;
    border-color: #009900;
}

.sr-bb .srt-mlb-hit-1 {
    background-color: #f7ab11;
    color: #ffffff;
    border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
    background-color: #ffffff;
    color: #f7ab11;
    border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
    background-color: #00a4ff;
    color: #ffffff;
    border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
    background-color: #d0021b;
    color: #ffffff;
    border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
    background-color: #ffffff;
    color: #d0021b;
    border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
    background-color: #212121;
    color: #ffffff;
    border-color: #ffffff;
}

.sr-bb .srt-fill-mlb-base {
    fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
    fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
    fill: #0495E3;
}

.sr-bb .srt-stroke-change-increase {
    stroke: #0495E3;
}

.sr-bb .srt-fill-change-decrease {
    fill: #FD2954;
}

.sr-bb .srt-stroke-change-decrease {
    stroke: #FD2954;
}

.sr-bb .srt-fill-text {
    fill: #ffffff;
}

.sr-bb .srt-fill-text-invert {
    fill: #ffffff;
}

.sr-bb .srt-fill-text-secondary {
    fill-opacity: 0.8;
}

.sr-bb .srt-fill-text-disabled {
    fill-opacity: 0.6;
}

.sr-bb .srt-fill-base-1 {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
    fill: rgba(253, 41, 84, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
    stroke: rgba(253, 41, 84, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
    fill: #2c2c49;
}

.sr-bb .srt-stroke-base-1-active-2 {
    stroke: #2c2c49;
}

.sr-bb .srt-fill-base-1-primary {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
    stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
    fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
    stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
    fill: #301739;
}

.sr-bb .srt-stroke-base-2-active {
    stroke: #301739;
}

.sr-bb .srt-fill-base-2-hover {
    fill: #301739;
}

.sr-bb .srt-stroke-base-2-hover {
    stroke: #301739;
}

.sr-bb .srt-fill-base-3 {
    fill: #301739;
}

.sr-bb .srt-stroke-base-3 {
    stroke: #301739;
}

.sr-bb .srt-fill-base-3-active {
    fill: #3c183a;
}

.sr-bb .srt-stroke-base-3-active {
    stroke: #3c183a;
}

.sr-bb .srt-fill-base-3-hover {
    fill: #3c183a;
}

.sr-bb .srt-stroke-base-3-hover {
    stroke: #3c183a;
}

.sr-bb .srt-fill-primary-1 {
    fill: #FD2954;
}

.sr-bb .srt-stroke-primary-1 {
    stroke: #FD2954;
}

.sr-bb .srt-fill-primary-2 {
    fill: #fe6987;
}

.sr-bb .srt-stroke-primary-2 {
    stroke: #fe6987;
}

.sr-bb .srt-fill-primary-3 {
    fill: #fd3e65;
}

.sr-bb .srt-stroke-primary-3 {
    stroke: #fd3e65;
}

.sr-bb .srt-fill-primary-4 {
    fill: #a02148;
}

.sr-bb .srt-stroke-primary-4 {
    stroke: #a02148;
}

.sr-bb .srt-fill-primary-5 {
    fill: #a02148;
}

.sr-bb .srt-stroke-primary-5 {
    stroke: #a02148;
}

.sr-bb .srt-fill-primary-6 {
    fill: #fe839c;
}

.sr-bb .srt-stroke-primary-6 {
    stroke: #fe839c;
}

.sr-bb .srt-fill-primary-7 {
    fill: #FD2954;
}

.sr-bb .srt-stroke-primary-7 {
    stroke: #FD2954;
}

.sr-bb .srt-fill-primary-8 {
    fill: #FD2954;
}

.sr-bb .srt-stroke-primary-8 {
    stroke: #FD2954;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
    fill: #fd3e65;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
    stroke: #fd3e65;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
    fill: #fd3e65;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
    stroke: #fd3e65;
}

.sr-bb .srt-fill-primary-9 {
    fill: #5a1a3e;
}

.sr-bb .srt-stroke-primary-9 {
    stroke: #5a1a3e;
}

.sr-bb .srt-fill-primary-10 {
    fill: #ce254e;
}

.sr-bb .srt-stroke-primary-10 {
    stroke: #ce254e;
}

.sr-bb .srt-fill-primary-11 {
    fill: #fd3e65;
}

.sr-bb .srt-stroke-primary-11 {
    stroke: #fd3e65;
}

.sr-bb .srt-fill-primary-12 {
    fill: #FD2954;
}

.sr-bb .srt-stroke-primary-12 {
    stroke: #FD2954;
}

.sr-bb .srt-fill-home-1 {
    fill: #0495E3;
}

.sr-bb .srt-stroke-home-1 {
    stroke: #0495E3;
}

.sr-bb .srt-fill-home-2 {
    fill: #0495E3;
}

.sr-bb .srt-stroke-home-2 {
    stroke: #0495E3;
}

.sr-bb .srt-fill-home-3 {
    fill: #0495E3;
}

.sr-bb .srt-stroke-home-3 {
    stroke: #0495E3;
}

.sr-bb .srt-fill-home-4 {
    fill: #096eaf;
}

.sr-bb .srt-stroke-home-4 {
    stroke: #096eaf;
}

.sr-bb .srt-fill-home-5 {
    fill: #5cbaed;
}

.sr-bb .srt-stroke-home-5 {
    stroke: #5cbaed;
}

.sr-bb .srt-fill-away-1 {
    fill: #FD2954;
}

.sr-bb .srt-stroke-away-1 {
    stroke: #FD2954;
}

.sr-bb .srt-fill-away-2 {
    fill: #FD2954;
}

.sr-bb .srt-stroke-away-2 {
    stroke: #FD2954;
}

.sr-bb .srt-fill-away-3 {
    fill: #FD2954;
}

.sr-bb .srt-stroke-away-3 {
    stroke: #FD2954;
}

.sr-bb .srt-fill-away-4 {
    fill: #b7234b;
}

.sr-bb .srt-stroke-away-4 {
    stroke: #b7234b;
}

.sr-bb .srt-fill-away-5 {
    fill: #fe7490;
}

.sr-bb .srt-stroke-away-5 {
    stroke: #fe7490;
}

.sr-bb .srt-fill-neutral-1 {
    fill: #33334f;
}

.sr-bb .srt-stroke-neutral-1 {
    stroke: #33334f;
}

.sr-bb .srt-fill-neutral-2 {
    fill: #e8e8eb;
}

.sr-bb .srt-stroke-neutral-2 {
    stroke: #e8e8eb;
}

.sr-bb .srt-fill-neutral-3 {
    fill: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
    stroke: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
    fill: #adadb8;
}

.sr-bb .srt-stroke-neutral-4 {
    stroke: #adadb8;
}

.sr-bb .srt-fill-neutral-5 {
    fill: #c4c4cd;
}

.sr-bb .srt-stroke-neutral-5 {
    stroke: #c4c4cd;
}

.sr-bb .srt-fill-neutral-6 {
    fill: #4c4c65;
}

.sr-bb .srt-stroke-neutral-6 {
    stroke: #4c4c65;
}

.sr-bb .srt-fill-neutral-7 {
    fill: #adadb8;
}

.sr-bb .srt-stroke-neutral-7 {
    stroke: #adadb8;
}

.sr-bb .srt-fill-neutral-8 {
    fill: #33334f;
}

.sr-bb .srt-stroke-neutral-8 {
    stroke: #33334f;
}

.sr-bb .srt-fill-neutral-9 {
    fill: #222241;
}

.sr-bb .srt-stroke-neutral-9 {
    stroke: #222241;
}

.sr-bb .srt-fill-neutral-10 {
    fill: #30304d;
}

.sr-bb .srt-stroke-neutral-10 {
    stroke: #30304d;
}

.sr-bb .srt-fill-neutral-11 {
    fill: #727286;
}

.sr-bb .srt-stroke-neutral-11 {
    stroke: #727286;
}

.sr-bb .srt-fill-neutral-12 {
    fill: #b9b9c2;
}

.sr-bb .srt-stroke-neutral-12 {
    stroke: #b9b9c2;
}

.sr-bb .srt-fill-neutral-13 {
    fill: #2c2c49;
}

.sr-bb .srt-stroke-neutral-13 {
    stroke: #2c2c49;
}

.sr-bb .srt-fill-win {
    fill: #0495E3;
}

.sr-bb .srt-stroke-win {
    stroke: #0495E3;
}

.sr-bb .srt-fill-draw {
    fill: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-stroke-draw {
    stroke: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-fill-lose {
    fill: #FD2954;
}

.sr-bb .srt-stroke-lose {
    stroke: #FD2954;
}

.sr-bb .srt-stop-base-1 {
    stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
    stop-color: #FD2954;
}

.sr-bb .srt-stop-primary-2 {
    stop-color: #fe6987;
}

.sr-bb .srt-stop-primary-3 {
    stop-color: #fd3e65;
}

.sr-bb .srt-stop-primary-4 {
    stop-color: #a02148;
}

.sr-bb .srt-stop-primary-5 {
    stop-color: #a02148;
}

.sr-bb .srt-stop-primary-6 {
    stop-color: #fe839c;
}

.sr-bb .srt-stop-primary-7 {
    stop-color: #FD2954;
}

.sr-bb .srt-stop-primary-8 {
    stop-color: #FD2954;
}

.sr-bb .srt-stop-primary-9 {
    stop-color: #5a1a3e;
}

.sr-bb .srt-stop-primary-10 {
    stop-color: #ce254e;
}

.sr-bb .srt-stop-primary-11 {
    stop-color: #fd3e65;
}

.sr-bb .srt-stop-primary-12 {
    stop-color: #FD2954;
}

.sr-bb .srt-stop-home-1 {
    stop-color: #0495E3;
}

.sr-bb .srt-stop-away-1 {
    stop-color: #FD2954;
}

.sr-bb .srt-fill-neutral-14 {
    fill: #0E0E29;
}

.sr-bb .srt-stroke-neutral-14 {
    stroke: #0E0E29;
}

.sr-bb .srt-logo-powered-by-light {
    display: none;
}

.sr-bb .srt-logo-powered-by-dark {
    display: inline-block;
}

.sr-bb {
    text-align: left;
    background: #FFFFFF;
}

.sr-bb.sr-rtl {
    text-align: right;
}

.sr-bb .sr-bb {
    background: none;
}

.sr-bb .srt-base-1 {
    background-color: transparent;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}
/*.sr-bb .srt-base-1 {*/
/*  background-color: var(--CrashKali-body-bg);*/
/*  color: var(--light);*/
/*  border-color: rgba(0, 0, 0, 0.12);*/
/*}*/

.sr-bb .srt-base-1-win {
    background-color: transparent;
    color: #00003c;
    border-color: #00003c;
}

.sr-bb .srt-base-1-draw {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.4);
    border-color: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-base-1-lose {
    background-color: transparent;
    color: #ff0000;
    border-color: #ff0000;
}

.sr-bb .srt-base-1-is-active {
    background-color: rgba(255, 0, 0, 0.12);
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
    background-color: #e6e6e6;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
    background-color: rgba(255, 0, 0, 0.12);
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-primary {
    background-color: transparent;
    color: #FF0000;
    border-color: #FF0000;
}

.sr-bb .srt-base-1-home-1 {
    background-color: transparent;
    color: #00003c;
    border-color: #00003c;
}

.sr-bb .srt-base-1-away-1 {
    background-color: transparent;
    color: #ff0000;
    border-color: #ff0000;
}

.sr-bb .srt-base-1-home-2 {
    background-color: transparent;
    color: #00003c;
    border-color: #940000;
}

.sr-bb .srt-base-1-away-2 {
    background-color: transparent;
    color: #ff0000;
    border-color: #940000;
}

.sr-bb .srt-base-1-home-3 {
    background-color: transparent;
    color: #00003c;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-away-3 {
    background-color: transparent;
    color: #ff0000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-home-4 {
    background-color: transparent;
    color: #4d4d77;
    border-color: #4d4d77;
}

.sr-bb .srt-base-1-away-4 {
    background-color: transparent;
    color: #ff4d4d;
    border-color: #ff4d4d;
}

.sr-bb .srt-base-1-home-5 {
    background-color: transparent;
    color: #000027;
    border-color: #000027;
}

.sr-bb .srt-base-1-away-5 {
    background-color: transparent;
    color: #a60000;
    border-color: #a60000;
}

.sr-bb .srt-base-1-background {
    background-color: white;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2 {
    background-color: white;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2-is-active {
    background-color: #ffe0e0;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
    background-color: #ffe0e0;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3 {
    background-color: #ffe0e0;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-is-active {
    background-color: #ffd4d4;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
    background-color: #ffd4d4;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-background {
    background-color: white;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-4 {
    background-color: #fff2f2;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-5-is-active {
    background-color: #ffbdbd;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
    background-color: #ffbdbd;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-6 {
    background-color: transparent;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.3);
}

.sr-bb .srt-primary-1 {
    background-color: #FF0000;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-active {
    background-color: #ff1a1a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-hoverable:hover {
    background-color: #ff1a1a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
    background-color: #ff3333;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-2 {
    background-color: #b30000;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-3 {
    background-color: #ff1a1a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-4 {
    background-color: #ff6666;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-5 {
    background-color: #ff6666;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover {
    background-color: #FF0000;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-6 {
    background-color: #940000;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-7 {
    background-color: #FF0000;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8 {
    background-color: #FF0000;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8-is-active-1 {
    background-color: #ff1a1a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
    background-color: #ff1a1a;
    color: #ffffff;
    border-color: #FFFFFF;
}

.sr-bb .srt-primary-9 {
    background-color: #ffb3b3;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-primary-10 {
    background-color: #ff3333;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-11 {
    background-color: #ff1a1a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-12 {
    background-color: #FF0000;
    color: #ffffff;
    border-color: #ffffff;
}

.sr-bb .srt-primary-13 {
    background-color: #fff2f2;
    color: #ffffff;
    border-color: rgba(255, 0, 0, 0.3);
}

.sr-bb .srt-base-1-primary-1 {
    background-color: transparent;
    color: #FF0000;
    border-color: #FF0000;
}

.sr-bb .srt-base-1-primary-2 {
    background-color: transparent;
    color: #b30000;
    border-color: #b30000;
}

.sr-bb .srt-base-1-primary-3 {
    background-color: transparent;
    color: #ff1a1a;
    border-color: #ff1a1a;
}

.sr-bb .srt-base-1-primary-4 {
    background-color: transparent;
    color: #ff6666;
    border-color: #ff6666;
}

.sr-bb .srt-base-1-primary-5 {
    background-color: transparent;
    color: #ff6666;
    border-color: #ff6666;
}

.sr-bb .srt-base-1-primary-6 {
    background-color: transparent;
    color: #940000;
    border-color: #940000;
}

.sr-bb .srt-base-1-primary-7 {
    background-color: transparent;
    color: #FF0000;
    border-color: #FF0000;
}

.sr-bb .srt-base-1-primary-8 {
    background-color: transparent;
    color: #FF0000;
    border-color: #FF0000;
}

.sr-bb .srt-base-1-primary-9 {
    background-color: transparent;
    color: #ffb3b3;
    border-color: #ffb3b3;
}

.sr-bb .srt-base-1-primary-10 {
    background-color: transparent;
    color: #ff3333;
    border-color: #ff3333;
}

.sr-bb .srt-base-1-primary-11 {
    background-color: transparent;
    color: #ff1a1a;
    border-color: #ff1a1a;
}

.sr-bb .srt-base-1-primary-13 {
    background-color: #FFFFFF;
    color: #fff2f2;
    border-color: rgba(255, 0, 0, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
    background-color: transparent;
    color: #dedede;
    border-color: #dedede;
}

.sr-bb .srt-base-1-neutral-2 {
    background-color: transparent;
    color: #1a1a1a;
    border-color: #1a1a1a;
}

.sr-bb .srt-base-1-neutral-3 {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
    background-color: transparent;
    color: #595959;
    border-color: #595959;
}

.sr-bb .srt-base-1-neutral-5 {
    background-color: transparent;
    color: #404040;
    border-color: #404040;
}

.sr-bb .srt-base-1-neutral-6 {
    background-color: transparent;
    color: #c2c2c2;
    border-color: #c2c2c2;
}

.sr-bb .srt-base-1-neutral-7 {
    background-color: transparent;
    color: #595959;
    border-color: #595959;
}

.sr-bb .srt-base-1-neutral-8 {
    background-color: transparent;
    color: #dedede;
    border-color: #dedede;
}

.sr-bb .srt-base-1-neutral-9 {
    background-color: transparent;
    color: #f0f0f0;
    border-color: #f0f0f0;
}

.sr-bb .srt-base-1-neutral-10 {
    background-color: transparent;
    color: #e0e0e0;
    border-color: #e0e0e0;
}

.sr-bb .srt-base-1-neutral-11 {
    background-color: transparent;
    color: #999999;
    border-color: #999999;
}

.sr-bb .srt-base-1-neutral-12 {
    background-color: transparent;
    color: #4d4d4d;
    border-color: #4d4d4d;
}

.sr-bb .srt-base-1-neutral-13 {
    background-color: transparent;
    color: #e6e6e6;
    border-color: #e6e6e6;
}

.sr-bb .srt-base-1-is-active-primary {
    background-color: rgba(255, 0, 0, 0.12);
    color: #FF0000;
    border-color: #FF0000;
}

.sr-bb .srt-base-1-is-active-home-1 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #00003c;
    border-color: #00003c;
}

.sr-bb .srt-base-1-is-active-away-1 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ff0000;
    border-color: #ff0000;
}

.sr-bb .srt-base-1-is-active-home-2 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #00003c;
    border-color: #940000;
}

.sr-bb .srt-base-1-is-active-away-2 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ff0000;
    border-color: #940000;
}

.sr-bb .srt-base-1-is-active-home-3 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #00003c;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ff0000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #4d4d77;
    border-color: #4d4d77;
}

.sr-bb .srt-base-1-is-active-away-4 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ff4d4d;
    border-color: #ff4d4d;
}

.sr-bb .srt-base-1-is-active-home-5 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #000027;
    border-color: #000027;
}

.sr-bb .srt-base-1-is-active-away-5 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #a60000;
    border-color: #a60000;
}

.sr-bb .srt-base-1-is-active-primary-1 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #FF0000;
    border-color: #FF0000;
}

.sr-bb .srt-base-1-is-active-primary-2 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #b30000;
    border-color: #b30000;
}

.sr-bb .srt-base-1-is-active-primary-3 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ff1a1a;
    border-color: #ff1a1a;
}

.sr-bb .srt-base-1-is-active-primary-4 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ff6666;
    border-color: #ff6666;
}

.sr-bb .srt-base-1-is-active-primary-5 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ff6666;
    border-color: #ff6666;
}

.sr-bb .srt-base-1-is-active-primary-6 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #940000;
    border-color: #940000;
}

.sr-bb .srt-base-1-is-active-primary-7 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #FF0000;
    border-color: #FF0000;
}

.sr-bb .srt-base-1-is-active-primary-8 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #FF0000;
    border-color: #FF0000;
}

.sr-bb .srt-base-1-is-active-primary-9 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ffb3b3;
    border-color: #ffb3b3;
}

.sr-bb .srt-base-1-is-active-primary-10 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ff3333;
    border-color: #ff3333;
}

.sr-bb .srt-base-1-is-active-primary-11 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #ff1a1a;
    border-color: #ff1a1a;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #dedede;
    border-color: #dedede;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #1a1a1a;
    border-color: #1a1a1a;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
    background-color: rgba(255, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #595959;
    border-color: #595959;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #404040;
    border-color: #404040;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #c2c2c2;
    border-color: #c2c2c2;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #595959;
    border-color: #595959;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #dedede;
    border-color: #dedede;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #f0f0f0;
    border-color: #f0f0f0;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #e0e0e0;
    border-color: #e0e0e0;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #999999;
    border-color: #999999;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #4d4d4d;
    border-color: #4d4d4d;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
    background-color: rgba(255, 0, 0, 0.12);
    color: #e6e6e6;
    border-color: #e6e6e6;
}

.sr-bb .srt-home-1 {
    background-color: #00003c;
    color: #ffffff;
    border-color: #00003c;
}

.sr-bb .srt-away-1 {
    background-color: #ff0000;
    color: #ffffff;
    border-color: #ff0000;
}

.sr-bb .srt-home-2 {
    background-color: #00003c;
    color: #ffffff;
    border-color: #940000;
}

.sr-bb .srt-away-2 {
    background-color: #ff0000;
    color: #ffffff;
    border-color: #940000;
}

.sr-bb .srt-home-3 {
    background-color: #00003c;
    color: #ffffff;
    border-color: #FFFFFF;
}

.sr-bb .srt-away-3 {
    background-color: #ff0000;
    color: #ffffff;
    border-color: #FFFFFF;
}

.sr-bb .srt-home-4 {
    background-color: #4d4d77;
    color: #ffffff;
    border-color: #4d4d77;
}

.sr-bb .srt-away-4 {
    background-color: #ff4d4d;
    color: #ffffff;
    border-color: #ff4d4d;
}

.sr-bb .srt-home-5 {
    background-color: #000027;
    color: #ffffff;
    border-color: #000027;
}

.sr-bb .srt-away-5 {
    background-color: #a60000;
    color: #ffffff;
    border-color: #a60000;
}

.sr-bb .srt-home-6 {
    background-color: rgba(0, 0, 60, 0.2);
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-away-6 {
    background-color: rgba(255, 0, 0, 0.2);
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-home-6-is-hoverable:hover {
    background-color: rgba(0, 0, 60, 0.2);
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-away-6-is-hoverable:hover {
    background-color: rgba(255, 0, 0, 0.2);
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-1 {
    background-color: #dedede;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-2 {
    background-color: #1a1a1a;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-3 {
    background-color: rgba(0, 0, 0, 0.12);
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-4 {
    background-color: #595959;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-5 {
    background-color: #404040;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-6 {
    background-color: #c2c2c2;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-7 {
    background-color: #595959;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-8 {
    background-color: #dedede;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-9 {
    background-color: #f0f0f0;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-10 {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-11 {
    background-color: #999999;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-12 {
    background-color: #4d4d4d;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-13 {
    background-color: #e6e6e6;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-win {
    background-color: #00003c;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-draw {
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-lose {
    background-color: #ff0000;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-text-secondary {
    opacity: 0.7;
    -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-text-disabled {
    opacity: 0.35;
}

.sr-bb .srt-text-tertiary {
    opacity: 0.54;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
    opacity: 0.8;
    -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
    opacity: 0.6;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
    opacity: 0.7;
}

.sr-bb .srt-icon {
    opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
    opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23), 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.26), 0px 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
    box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
    box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
    box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
    box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
    box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
    fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
    stroke: #2579ad;
}

.sr-bb .srt-fill-green-card {
    fill: #2cbd00;
}

.sr-bb .srt-stroke-green-card {
    stroke: #2cbd00;
}

.sr-bb .srt-fill-soccer-yellow-card {
    fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
    stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
    stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
    stroke: #4fbe30;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
    fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
    fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
    fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
    stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
    fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
    stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
    fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
    stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
    fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
    stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
    fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
    stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
    fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
    stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
    fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
    stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
    fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
    stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
    fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
    stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
    fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
    stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
    background-color: #f5a623;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
    background-color: rgba(245, 166, 35, 0.3);
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-penalty {
    background-color: #f4a621;
    color: #ffffff;
    border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
    background-color: #ac182e;
    color: #ffffff;
    border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
    background-color: #ac182e;
    color: #ffffff;
    border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
    background-color: #4b90de;
    color: #ffffff;
    border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
    background-color: #f5a623;
    color: #ffffff;
    border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
    fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
    stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
    fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
    stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
    fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
    stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
    background-color: #009900;
    color: #ffffff;
    border-color: #009900;
}

.sr-bb .srt-mlb-run-2 {
    background-color: #ffffff;
    color: #009900;
    border-color: #009900;
}

.sr-bb .srt-mlb-hit-1 {
    background-color: #f7ab11;
    color: #000000;
    border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
    background-color: #ffffff;
    color: #f7ab11;
    border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
    background-color: #00a4ff;
    color: #ffffff;
    border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
    background-color: #d0021b;
    color: #ffffff;
    border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
    background-color: #ffffff;
    color: #d0021b;
    border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
    background-color: #212121;
    color: #ffffff;
    border-color: #ffffff;
}

.sr-bb .srt-fill-mlb-base {
    fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
    fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
    fill: #00003c;
}

.sr-bb .srt-stroke-change-increase {
    stroke: #00003c;
}

.sr-bb .srt-fill-change-decrease {
    fill: #ff0000;
}

.sr-bb .srt-stroke-change-decrease {
    stroke: #ff0000;
}

.sr-bb .srt-fill-text {
    fill: #000000;
}

.sr-bb .srt-fill-text-invert {
    fill: #ffffff;
}

.sr-bb .srt-fill-text-secondary {
    fill-opacity: 0.7;
}

.sr-bb .srt-fill-text-disabled {
    fill-opacity: 0.35;
}

.sr-bb .srt-fill-base-1 {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
    fill: rgba(255, 0, 0, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
    stroke: rgba(255, 0, 0, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
    fill: #e6e6e6;
}

.sr-bb .srt-stroke-base-1-active-2 {
    stroke: #e6e6e6;
}

.sr-bb .srt-fill-base-1-primary {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
    stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
    fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
    stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
    fill: #ffe0e0;
}

.sr-bb .srt-stroke-base-2-active {
    stroke: #ffe0e0;
}

.sr-bb .srt-fill-base-2-hover {
    fill: #ffe0e0;
}

.sr-bb .srt-stroke-base-2-hover {
    stroke: #ffe0e0;
}

.sr-bb .srt-fill-base-3 {
    fill: #ffe0e0;
}

.sr-bb .srt-stroke-base-3 {
    stroke: #ffe0e0;
}

.sr-bb .srt-fill-base-3-active {
    fill: #ffd4d4;
}

.sr-bb .srt-stroke-base-3-active {
    stroke: #ffd4d4;
}

.sr-bb .srt-fill-base-3-hover {
    fill: #ffd4d4;
}

.sr-bb .srt-stroke-base-3-hover {
    stroke: #ffd4d4;
}

.sr-bb .srt-fill-primary-1 {
    fill: #FF0000;
}

.sr-bb .srt-stroke-primary-1 {
    stroke: #FF0000;
}

.sr-bb .srt-fill-primary-2 {
    fill: #b30000;
}

.sr-bb .srt-stroke-primary-2 {
    stroke: #b30000;
}

.sr-bb .srt-fill-primary-3 {
    fill: #ff1a1a;
}

.sr-bb .srt-stroke-primary-3 {
    stroke: #ff1a1a;
}

.sr-bb .srt-fill-primary-4 {
    fill: #ff6666;
}

.sr-bb .srt-stroke-primary-4 {
    stroke: #ff6666;
}

.sr-bb .srt-fill-primary-5 {
    fill: #ff6666;
}

.sr-bb .srt-stroke-primary-5 {
    stroke: #ff6666;
}

.sr-bb .srt-fill-primary-6 {
    fill: #940000;
}

.sr-bb .srt-stroke-primary-6 {
    stroke: #940000;
}

.sr-bb .srt-fill-primary-7 {
    fill: #FF0000;
}

.sr-bb .srt-stroke-primary-7 {
    stroke: #FF0000;
}

.sr-bb .srt-fill-primary-8 {
    fill: #FF0000;
}

.sr-bb .srt-stroke-primary-8 {
    stroke: #FF0000;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
    fill: #ff1a1a;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
    stroke: #ff1a1a;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
    fill: #ff1a1a;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
    stroke: #ff1a1a;
}

.sr-bb .srt-fill-primary-9 {
    fill: #ffb3b3;
}

.sr-bb .srt-stroke-primary-9 {
    stroke: #ffb3b3;
}

.sr-bb .srt-fill-primary-10 {
    fill: #ff3333;
}

.sr-bb .srt-stroke-primary-10 {
    stroke: #ff3333;
}

.sr-bb .srt-fill-primary-11 {
    fill: #ff1a1a;
}

.sr-bb .srt-stroke-primary-11 {
    stroke: #ff1a1a;
}

.sr-bb .srt-fill-primary-12 {
    fill: #FF0000;
}

.sr-bb .srt-stroke-primary-12 {
    stroke: #FF0000;
}

.sr-bb .srt-fill-home-1 {
    fill: #00003c;
}

.sr-bb .srt-stroke-home-1 {
    stroke: #00003c;
}

.sr-bb .srt-fill-home-2 {
    fill: #00003c;
}

.sr-bb .srt-stroke-home-2 {
    stroke: #00003c;
}

.sr-bb .srt-fill-home-3 {
    fill: #00003c;
}

.sr-bb .srt-stroke-home-3 {
    stroke: #00003c;
}

.sr-bb .srt-fill-home-4 {
    fill: #4d4d77;
}

.sr-bb .srt-stroke-home-4 {
    stroke: #4d4d77;
}

.sr-bb .srt-fill-home-5 {
    fill: #000027;
}

.sr-bb .srt-stroke-home-5 {
    stroke: #000027;
}

.sr-bb .srt-fill-away-1 {
    fill: #ff0000;
}

.sr-bb .srt-stroke-away-1 {
    stroke: #ff0000;
}

.sr-bb .srt-fill-away-2 {
    fill: #ff0000;
}

.sr-bb .srt-stroke-away-2 {
    stroke: #ff0000;
}

.sr-bb .srt-fill-away-3 {
    fill: #ff0000;
}

.sr-bb .srt-stroke-away-3 {
    stroke: #ff0000;
}

.sr-bb .srt-fill-away-4 {
    fill: #ff4d4d;
}

.sr-bb .srt-stroke-away-4 {
    stroke: #ff4d4d;
}

.sr-bb .srt-fill-away-5 {
    fill: #a60000;
}

.sr-bb .srt-stroke-away-5 {
    stroke: #a60000;
}

.sr-bb .srt-fill-neutral-1 {
    fill: #dedede;
}

.sr-bb .srt-stroke-neutral-1 {
    stroke: #dedede;
}

.sr-bb .srt-fill-neutral-2 {
    fill: #1a1a1a;
}

.sr-bb .srt-stroke-neutral-2 {
    stroke: #1a1a1a;
}

.sr-bb .srt-fill-neutral-3 {
    fill: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
    stroke: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
    fill: #595959;
}

.sr-bb .srt-stroke-neutral-4 {
    stroke: #595959;
}

.sr-bb .srt-fill-neutral-5 {
    fill: #404040;
}

.sr-bb .srt-stroke-neutral-5 {
    stroke: #404040;
}

.sr-bb .srt-fill-neutral-6 {
    fill: #c2c2c2;
}

.sr-bb .srt-stroke-neutral-6 {
    stroke: #c2c2c2;
}

.sr-bb .srt-fill-neutral-7 {
    fill: #595959;
}

.sr-bb .srt-stroke-neutral-7 {
    stroke: #595959;
}

.sr-bb .srt-fill-neutral-8 {
    fill: #dedede;
}

.sr-bb .srt-stroke-neutral-8 {
    stroke: #dedede;
}

.sr-bb .srt-fill-neutral-9 {
    fill: #f0f0f0;
}

.sr-bb .srt-stroke-neutral-9 {
    stroke: #f0f0f0;
}

.sr-bb .srt-fill-neutral-10 {
    fill: #e0e0e0;
}

.sr-bb .srt-stroke-neutral-10 {
    stroke: #e0e0e0;
}

.sr-bb .srt-fill-neutral-11 {
    fill: #999999;
}

.sr-bb .srt-stroke-neutral-11 {
    stroke: #999999;
}

.sr-bb .srt-fill-neutral-12 {
    fill: #4d4d4d;
}

.sr-bb .srt-stroke-neutral-12 {
    stroke: #4d4d4d;
}

.sr-bb .srt-fill-neutral-13 {
    fill: #e6e6e6;
}

.sr-bb .srt-stroke-neutral-13 {
    stroke: #e6e6e6;
}

.sr-bb .srt-fill-win {
    fill: #00003c;
}

.sr-bb .srt-stroke-win {
    stroke: #00003c;
}

.sr-bb .srt-fill-draw {
    fill: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-stroke-draw {
    stroke: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-fill-lose {
    fill: #ff0000;
}

.sr-bb .srt-stroke-lose {
    stroke: #ff0000;
}

.sr-bb .srt-stop-base-1 {
    stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
    stop-color: #FF0000;
}

.sr-bb .srt-stop-primary-2 {
    stop-color: #b30000;
}

.sr-bb .srt-stop-primary-3 {
    stop-color: #ff1a1a;
}

.sr-bb .srt-stop-primary-4 {
    stop-color: #ff6666;
}

.sr-bb .srt-stop-primary-5 {
    stop-color: #ff6666;
}

.sr-bb .srt-stop-primary-6 {
    stop-color: #940000;
}

.sr-bb .srt-stop-primary-7 {
    stop-color: #FF0000;
}

.sr-bb .srt-stop-primary-8 {
    stop-color: #FF0000;
}

.sr-bb .srt-stop-primary-9 {
    stop-color: #ffb3b3;
}

.sr-bb .srt-stop-primary-10 {
    stop-color: #ff3333;
}

.sr-bb .srt-stop-primary-11 {
    stop-color: #ff1a1a;
}

.sr-bb .srt-stop-primary-12 {
    stop-color: #FF0000;
}

.sr-bb .srt-stop-home-1 {
    stop-color: #00003c;
}

.sr-bb .srt-stop-away-1 {
    stop-color: #ff0000;
}

.sr-bb .srt-fill-neutral-14 {
    fill: #FFFFFF;
}

.sr-bb .srt-stroke-neutral-14 {
    stroke: #FFFFFF;
}

.sr-bb .srt-logo-powered-by-light {
    display: inline-block;
}

.sr-bb .srt-logo-powered-by-dark {
    display: none;
}


.sr-bb .sr-lmt-plus__expand-wrapper.srm-is-collapsed{
    display:none;
}
.sr-bb .sr-lmt-plus__footer-wrapper{
    display:none!important;
}
.sr-bb .sr-lmt-plus-scb__result-period{
    font-size: 16px;
    color: var(--light);
    font-weight: bolder;
    margin-top: 7px;
}
/*end lmt styling*/


.notify-badge {
    position: absolute;
    right: -10px;
    top: -10px;
    margin-top: 5px !important;
    background: red;
    text-align: center;
    border-radius: 10px 0px 15px 0px;
    color: white;
    padding: 1px 5px;
    font-size: 10px;
}
